import hash from "object-hash";
import { useTheme } from "styled-components/native";
import { memo } from "react";

import type { ICategoryItem } from "@hooks/@types/useProductCategory.types";

import { CategoryItem } from "./CategoryItem";

import { Container } from "./styles";

export type CategoryInputType = "check" | "counter" | "radio";

interface CategoryItemsProps {
  items: ICategoryItem[];
  maxAmount: number;
  inputType: CategoryInputType;
  onChangeItem: (item: ICategoryItem) => void;
}

const checkMaxAmountItemReached = (amount: number, maxAmount: number) => {
  return maxAmount !== 0 && amount === maxAmount;
};

const CategoryItemsBase = ({
  items,
  maxAmount,
  inputType,
  onChangeItem,
}: CategoryItemsProps) => {
  const { colors, commonShadowConfig } = useTheme();

  const maxAmountCategoryReached =
    items.reduce((acc, item) => acc + item.amount, 0) === maxAmount &&
    maxAmount !== 0;

  const handleChangeItem = (itemID: string, newValue: boolean | number) => {
    let newItem = items.find((item) => item.id === itemID)!;

    if (inputType !== "counter") {
      newItem = {
        ...newItem,
        isSelected: newValue as boolean,
        amount: newValue ? 1 : 0,
      };
    } else {
      newItem = {
        ...newItem,
        amount: newValue as number,
      };
    }

    onChangeItem(newItem);
  };

  return (
    <Container
      style={{
        shadowColor: colors.shadows,
        ...commonShadowConfig,
      }}
    >
      {items.map((item) => (
        <CategoryItem
          key={item.id}
          item={item}
          inputType={inputType}
          onChangeItem={handleChangeItem}
          disabled={
            maxAmountCategoryReached ||
            checkMaxAmountItemReached(item.amount, item.maxAmount ?? 0)
          }
        />
      ))}
    </Container>
  );
};

const validateProps = (
  prev: CategoryItemsProps,
  next: CategoryItemsProps
): boolean => {
  const prevHash = hash(prev.items);
  const nextHash = hash(next.items);
  return prevHash === nextHash;
};

export const CategoryItems = memo(CategoryItemsBase, validateProps);
