import { RFValue } from "react-native-responsive-fontsize";
import styled, { css } from "styled-components/native";

interface PriceProps {
  isOldPrice?: boolean;
  highlightSize: number;
  normalSize?: number;
  bold?: boolean;
  color?: string;
}

export const Container = styled.Text<PriceProps>`
  font-family: ${({ theme, bold }) =>
    bold ? theme.fonts.bold : theme.fonts.semiBold};
  font-size: ${({ highlightSize, normalSize }) =>
    RFValue(normalSize ?? highlightSize - 4)}px;
  color: ${({ theme, color }) => color ?? theme.colors.text[200]};

  ${({ isOldPrice, theme }) =>
    isOldPrice &&
    css`
      color: ${theme.colors.text[500]};
      font-family: ${theme.fonts.regular};
    `}
`;

export const HighlightText = styled.Text<PriceProps>`
  font-size: ${({ highlightSize }) => RFValue(highlightSize)}px;
`;

export const UnitText = styled.Text<{ unitColor?: string }>`
  color: ${({ theme, unitColor }) => unitColor ?? theme.colors.text[100]};
`;
