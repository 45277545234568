import { BottomMessageModal } from "@components/BottomMessageModal";
import { TextContent } from "@components/BottomMessageModal/styles";

import { useOrder } from "@contexts/useOrder";

import { CustomTextContent } from "./styles";

export const RemoveProductMessage = () => {
  const {
    showRemoveProductMessage,
    itemIndex,
    orderProducts,
    handleRemoveItem,
    closeMessage,
  } = useOrder();

  return (
    <BottomMessageModal
      show={showRemoveProductMessage && itemIndex !== null}
      title='Confirmação'
      customMessageContent={
        <TextContent>
          Confirmar a remoção do item
          <CustomTextContent>
            {` "${
              orderProducts[itemIndex!] && orderProducts[itemIndex!].name
            }" `}
          </CustomTextContent>
          do pedido?
        </TextContent>
      }
      onConfirm={handleRemoveItem}
      onCancel={closeMessage}
    />
  );
};
