import {
  GreetingsContainer,
  AppName,
  HighlightText,
  GreetingText,
} from "../styles";

export const HeaderGreetings = () => {
  return (
    <GreetingsContainer>
      <AppName>
        Ei<HighlightText> Garçom</HighlightText>,
      </AppName>
      <GreetingText>Bem-vindo!</GreetingText>
    </GreetingsContainer>
  );
};
