import { useState, ReactNode, forwardRef } from "react";
import {
  TextInputProps as TextInputPropsReact,
  NativeSyntheticEvent,
  TextInputFocusEventData,
  TextInput as TextInputReact,
  Platform,
} from "react-native";

import {
  InputContainer,
  StyledInput,
  IconBox,
  InputBox,
  Error,
} from "./styles";

type InputInsideElement = {
  type?: "icon";
  element?: ReactNode;
};

interface TextInputProps
  extends Omit<TextInputPropsReact, "accessibilityRole"> {
  elementRight?: InputInsideElement;
  elementLeft?: InputInsideElement;
  error?: string | undefined;
}

export const TextInput = forwardRef<TextInputReact, TextInputProps>(
  (
    { elementLeft, elementRight, error, onFocus, onBlur, value, ...rest },
    ref
  ) => {
    const [isFocused, setIsFocused] = useState(false);

    const handleFocus = (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
      setIsFocused(true);
      onFocus && onFocus(e);
    };

    const handleBlur = (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
      setIsFocused(false);
      onBlur && onBlur(e);
    };

    return (
      <InputContainer focused={isFocused} error={!!error}>
        <>
          {elementLeft &&
            (elementLeft.type === "icon" ? (
              <IconBox iconPosition='left'>{elementLeft.element}</IconBox>
            ) : (
              elementLeft.element
            ))}
          <InputBox
            hasElementLeft={!!elementLeft}
            hasElementRight={!!elementRight}
          >
            <StyledInput
              //@ts-ignore
              ref={ref}
              onFocus={handleFocus}
              onBlur={handleBlur}
              error={!!error}
              value={value}
              keyboardType={
                Platform.OS === "ios" || Platform.OS === "web"
                  ? "ascii-capable"
                  : "visible-password"
              }
              {...rest}
            />
          </InputBox>
          {elementRight &&
            (elementRight.type === "icon" ? (
              <IconBox iconPosition='right'>{elementRight.element}</IconBox>
            ) : (
              elementRight.element
            ))}
        </>
        {!!error && <Error>{error}</Error>}
      </InputContainer>
    );
  }
);
