import styled, { css } from "styled-components/native";
import { RFValue } from "react-native-responsive-fontsize";

interface InputProps {
  focused?: boolean;
  hasElementRight?: boolean;
  hasElementLeft?: boolean;
  error?: boolean;
}

interface IconInputProps {
  iconPosition: "left" | "right";
  concealable?: boolean;
}

export const InputContainer = styled.View<InputProps>`
  flex-direction: row;
  background-color: ${({ theme }) => theme.colors.background[200]};
  border-radius: 8px;

  align-items: center;

  border: 1px solid
    ${({ theme, focused, error }) =>
      error
        ? theme.colors.red
        : focused
        ? theme.colors.primary
        : theme.colors.background[300]};
`;

export const InputBox = styled.View<InputProps>`
  width: 100%;
  flex: 1;

  padding: 14px 20px;

  flex-direction: row;
  align-items: center;

  position: relative;

  ${({ hasElementLeft }) =>
    hasElementLeft &&
    css`
      padding-left: 16px;
    `}

  ${({ hasElementRight }) =>
    hasElementRight &&
    css`
      padding-right: 16px;
    `}
`;

export const StyledInput = styled.TextInput.attrs<InputProps>(
  ({ theme, selectionColor, placeholderTextColor, error }) => ({
    selectionColor:
      selectionColor ?? (error ? theme.colors.red : theme.colors.primary),
    placeholderTextColor: placeholderTextColor ?? theme.colors.text[500],
  })
)<InputProps>`
  z-index: 2;
  width: 100%;

  font-size: ${RFValue(16)}px;

  font-family: ${({ theme }) => theme.fonts.regular};
  color: ${({ theme }) => theme.colors.text[200]};

  outline-style: none;
`;

export const IconBox = styled.View<IconInputProps>`
  ${({ concealable, iconPosition }) =>
    !concealable &&
    (iconPosition === "left"
      ? css`
          margin-left: 20px;
        `
      : css`
          margin-right: 20px;
        `)};
`;

export const Error = styled.Text`
  color: ${({ theme }) => theme.colors.red};
  font-size: 14px;
  position: absolute;
  top: 110%;
`;
