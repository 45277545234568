import { AnimatePresence, MotiView } from "moti";
import { memo, useEffect } from "react";
import hash from "object-hash";

import type { IProductOrder } from "@contexts/@types/useOrder.types";

import {
  transitionSpring,
  useDropItemAnimation,
  useDropdownAnimation,
  useFooterAnimation,
  useTagHasExtraAnimation,
  useArrowAnimation,
} from "./animations";
import {
  ActionButton,
  ActionsDivider,
  Amount,
  AmountUnit,
  ArrowIconStyled,
  ComboProduct,
  ComboProductArea,
  ComboProductTitle,
  Container,
  EditIconStyled,
  FooterActions,
  ProductContent,
  ProductDetails,
  ProductDropdownContainer,
  ProductImage,
  ProductPrice,
  ProductTitle,
  ProductTitleText,
  TagHasExtra,
  TagHasExtraPlus,
  TagHasExtraText,
  TileContainer,
  TrashIconStyled,
  DashedLineStyled,
} from "./styles";
import { AdditionalList } from "./components/AdditionalList";
import { ObservationsList } from "./components/ObservationsList";
import { fadePresence } from "@utils/commonAnimations";

interface ProductTileProps {
  product: IProductOrder;
  showExtraDetails?: boolean;
  showDetailsLine?: boolean;
  onPressTile?: () => void;
  onRemove?: () => void;
  onEdit?: () => void;
}

const ProductTileBase = ({
  product,
  showExtraDetails = false,
  showDetailsLine = true,
  onPressTile,
  onRemove,
  onEdit,
}: ProductTileProps) => {
  const {
    image,
    additional,
    amount,
    unit,
    name,
    totalPrice,
    observation,
    prefixObservations,
    comboProducts,
    price,
    promotionalPrice,
  } = product;
  const hasPrefixObservations =
    prefixObservations && prefixObservations.length > 0;
  const hasAdditional = additional && additional.length > 0;
  const hasComboProducts = comboProducts && comboProducts.length > 0;
  // const hasRemovedIngredients = removedIngredients.length > 0;
  const hasExtraData =
    hasAdditional || !!observation || hasPrefixObservations || hasComboProducts;

  const hasActions = !!onRemove || !!onEdit;

  const dropdownAnimation = useDropdownAnimation();
  const dropItemAnimation = useDropItemAnimation();
  const tagHasExtraAnimation = useTagHasExtraAnimation();
  const footerAnimation = useFooterAnimation(hasActions);
  const arrowAnimation = useArrowAnimation();

  const delayOnHide = {
    delay: !showExtraDetails && dropdownAnimation.current === "show" ? 150 : 0,
  };

  const delayOpacityOnShow = {
    delay: showExtraDetails && dropdownAnimation.current === "hidden" ? 300 : 0,
  };

  const transitionWithDelay = {
    ...transitionSpring,
    maxHeight: delayOnHide,
    marginTop: delayOnHide,
  };

  useEffect(() => {
    if (showExtraDetails) {
      arrowAnimation.transitionTo("up");
      tagHasExtraAnimation.transitionTo("hidden");

      dropdownAnimation.transitionTo("show");
      dropItemAnimation.transitionTo("show");
      footerAnimation.transitionTo("show");
    } else {
      arrowAnimation.transitionTo("down");
      tagHasExtraAnimation.transitionTo("show");

      footerAnimation.transitionTo("hidden");
      dropItemAnimation.transitionTo("hidden");
      dropdownAnimation.transitionTo("hidden");
    }
  }, [showExtraDetails]);

  return (
    <Container>
      <TileContainer onPress={onPressTile}>
        <ProductImage
          source={{
            uri: image,
          }}
        />
        <ProductContent>
          <ProductTitle>
            <ProductTitleText>{name}</ProductTitleText>
            {(hasExtraData || hasActions) && showDetailsLine && (
              <MotiView
                state={arrowAnimation}
                transition={{ ...transitionSpring, rotateZ: delayOnHide }}
              >
                <ArrowIconStyled />
              </MotiView>
            )}
          </ProductTitle>
          {showDetailsLine && (
            <ProductDetails>
              <Amount>
                {amount}
                <AmountUnit> {unit}</AmountUnit>
              </Amount>
              {hasExtraData && (
                <TagHasExtra
                  state={tagHasExtraAnimation}
                  transition={{
                    delay:
                      !showExtraDetails && dropdownAnimation.current === "show"
                        ? 300
                        : 0,
                  }}
                >
                  <TagHasExtraText>
                    <TagHasExtraPlus>+ </TagHasExtraPlus>
                    Extra
                  </TagHasExtraText>
                </TagHasExtra>
              )}
              <AnimatePresence exitBeforeEnter>
                <ProductPrice price={totalPrice} />
              </AnimatePresence>
            </ProductDetails>
          )}
        </ProductContent>
      </TileContainer>

      {(hasExtraData || hasActions) && (
        <ProductDropdownContainer
          state={dropdownAnimation}
          transition={{
            ...transitionSpring,
            paddingTop: delayOnHide,
            opacity: delayOpacityOnShow,
          }}
        >
          <AdditionalList
            additional={additional}
            dropItemAnimation={dropItemAnimation}
            transitionWithDelay={transitionWithDelay}
          />

          {/* {hasRemovedIngredients &&
            removedIngredients.map((ingredient) => (
              <RemovedIngredient
                key={ingredient}
                state={dropItemAnimation}
                transition={transitionItem}
              >
                - {ingredient}
              </RemovedIngredient>
            ))} */}

          <ObservationsList
            prefixObservations={prefixObservations}
            observation={observation}
            dropItemAnimation={dropItemAnimation}
            transitionWithDelay={transitionWithDelay}
          />

          {comboProducts && (
            <ComboProductArea>
              <DashedLineStyled />
              {comboProducts.map(
                ({
                  product: {
                    name,
                    id,
                    additional: CProductAdditional,
                    observation: CProductObservation,
                    prefixObservations: CProductPrefixObservations,
                  },
                }) => (
                  <ComboProduct key={id}>
                    <ComboProductTitle
                      state={dropItemAnimation}
                      transition={transitionWithDelay}
                    >
                      {name}
                    </ComboProductTitle>
                    <AdditionalList
                      additional={CProductAdditional}
                      dropItemAnimation={dropItemAnimation}
                      transitionWithDelay={transitionWithDelay}
                    />
                    <ObservationsList
                      prefixObservations={CProductPrefixObservations}
                      observation={CProductObservation}
                      dropItemAnimation={dropItemAnimation}
                      transitionWithDelay={transitionWithDelay}
                    />
                  </ComboProduct>
                )
              )}
            </ComboProductArea>
          )}

          <FooterActions
            state={footerAnimation}
            transition={transitionWithDelay}
          >
            {!!onRemove && (
              <ActionButton
                iconLeft={<TrashIconStyled />}
                text='Remover'
                onPress={showExtraDetails ? onRemove : undefined}
              />
            )}
            {hasActions && <ActionsDivider />}

            {!!onEdit && (
              <ActionButton
                iconLeft={<EditIconStyled />}
                text='Editar'
                onPress={showExtraDetails ? onEdit : undefined}
              />
            )}
          </FooterActions>
        </ProductDropdownContainer>
      )}
    </Container>
  );
};

export const ProductTile = memo(ProductTileBase, (prevProps, nextProps) => {
  return (
    hash(prevProps.product) === hash(nextProps.product) &&
    prevProps.showExtraDetails === nextProps.showExtraDetails
  );
});
