import { Container, HighlightText, UnitText } from "./styles";

interface PriceHighlightProps {
  price: number;
  isOldPrice?: boolean;
  highlightSize?: number;
  normalSize?: number;
  unitColor?: string;
  color?: string;
  bold?: boolean;
}

export const PriceHighlight = ({
  price,
  isOldPrice,
  highlightSize = 16,
  unitColor,
  ...rest
}: PriceHighlightProps) => {
  const priceSplit = price.toFixed(2).split(".");

  return (
    <Container isOldPrice={isOldPrice} highlightSize={highlightSize} {...rest}>
      {unitColor ? <UnitText unitColor={unitColor}>R$ </UnitText> : "R$ "}
      <HighlightText highlightSize={highlightSize}>
        {priceSplit[0]}
      </HighlightText>
      ,{priceSplit[1]}
    </Container>
  );
};
