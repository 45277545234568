import { useEffect, useState, useRef } from "react";
import EventEmitter from "@services/EventEmitter";
import { makeEventNotifier } from "./useEventListener";

export type TypeNotification = "info" | "warning" | "error" | "success";

export type Notification = {
  message: string;
  type: TypeNotification;
  timeout?: number;
};

interface UseToastData {
  currentNotification: Notification;
  removeNotification: () => void;
}

export const toastNotification = (newNotification: Notification) => {
  EventEmitter.notify("sendNotification", {
    newNotification,
  });
};

const notifier = makeEventNotifier<{
  newNotification: Notification;
}>("sendNotification");

export const useToastNotificationListener = (
  listener: typeof notifier.notify,
  deps: ReadonlyArray<any>
) => {
  notifier.useEventListener(listener, deps);
};

export const useToast = (): UseToastData => {
  const notificationQueue = useRef<Notification[]>([]);
  const [currentNotification, setCurrentNotification] =
    useState<Notification>(null);

  useToastNotificationListener(({ newNotification }) => {
    notificationQueue.current.length === 0 &&
      setCurrentNotification(newNotification);

    notificationQueue.current.push(newNotification);
  }, []);

  useEffect(() => {
    if (!!currentNotification) {
      setTimeout(() => {
        setCurrentNotification(null);
      }, currentNotification.timeout ?? 3000);
    }
  }, [currentNotification]);

  const removeNotification = () => {
    notificationQueue.current.shift();

    if (notificationQueue.current.length > 0) {
      setCurrentNotification(notificationQueue.current[0]);
    }
  };

  return {
    currentNotification: currentNotification,
    removeNotification: removeNotification,
  };
};
