import { useAnimationState } from "moti";
import { RFValue } from "react-native-responsive-fontsize";

export const useTagAnimation = () =>
  useAnimationState(
    {
      showTrashButton: {
        paddingHorizontal: RFValue(8),
      },
      hideTrashButton: {
        paddingHorizontal: RFValue(20.5),
      },
    },
    {
      from: "hideTrashButton",
      to: "showTrashButton",
    }
  );

export const transitionSpring = {
  stiffness: 800,
  damping: 300,
  mass: 1.5,
};
