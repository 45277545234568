import { useAnimationState } from "moti";
import { useEffect } from "react";
import { RFValue } from "react-native-responsive-fontsize";

import { useModuleBottomSheet, useModulesPage } from "@contexts/index";

import {
  Container,
  LongArrowStyled,
  NumberModule,
  HeaderButton,
  TitleModule,
  LockOpenIconStyled,
  ModulePasswordText,
  CircularLoader,
} from "./styles";

export const usePasswordModuleAnimation = () =>
  useAnimationState(
    {
      show: {
        width: RFValue(36),
        opacity: 1,
      },
      hidden: {
        width: 0,
        opacity: 0,
      },
    },
    {
      from: "show",
      to: "hidden",
    }
  );

export const Header = () => {
  const { selectedModule, handleCloseModulesBottomSheet, moduleTypeExtended } =
    useModulesPage();
  const {
    passwordModule,
    onLayoutComponent,
    loadingPassModule,
    requestModulePassword,
  } = useModuleBottomSheet();

  const passwordModuleAnimation = usePasswordModuleAnimation();

  const delayOpacityOnShow = {
    delay:
      passwordModule && passwordModuleAnimation.current === "hidden" ? 150 : 0,
  };

  useEffect(() => {
    if (passwordModule) {
      passwordModuleAnimation.transitionTo("show");
    } else {
      passwordModuleAnimation.transitionTo("hidden");
    }
  }, [passwordModule]);

  return (
    <Container onLayout={(e) => onLayoutComponent(e, "header")}>
      <HeaderButton onPress={handleCloseModulesBottomSheet}>
        <LongArrowStyled />
      </HeaderButton>
      <TitleModule>
        {moduleTypeExtended}
        <NumberModule> Nº</NumberModule> {selectedModule?.number}
      </TitleModule>
      <HeaderButton onPress={requestModulePassword} isRightButton>
        {loadingPassModule ? <CircularLoader /> : <LockOpenIconStyled />}

        <ModulePasswordText
          state={passwordModuleAnimation}
          transition={{
            stiffness: 1000,
            damping: 100,
            mass: 2,
            opacity: delayOpacityOnShow,
          }}
        >
          {passwordModule}
        </ModulePasswordText>
      </HeaderButton>
    </Container>
  );
};
