import { BottomMessageModal } from "@components/BottomMessageModal";
import { useOrder } from "@contexts/useOrder";

export const ClearProductListMessage = () => {
  const { handleClearOrder, closeMessage, itemIndex, showClearListMessage } =
    useOrder();

  return (
    <BottomMessageModal
      show={showClearListMessage && itemIndex === null}
      title='Confirmação'
      message='Deseja realmente remover todos os itens desse pedido?'
      onConfirm={handleClearOrder}
      onCancel={closeMessage}
    />
  );
};
