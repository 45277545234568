import { Extrapolate } from "react-native-reanimated";

import { useModulesPage } from "@contexts/useModulesPage";
import { MenuDropdown } from "../MenuDropdown";

import { SearchIcon, StyledTextInput } from "@components/SearchBar/styles";
import { Container, FakeSearchBarContainer } from "./styles";

export const FloatingHeader = () => {
  const { searchValue, filteredModuleList, scrollY, scrollPageToTop } =
    useModulesPage();

  const top = scrollY.interpolate({
    inputRange: [50, 200],
    outputRange: [-200, filteredModuleList.length >= 3 ? 0 : -200],
    extrapolateRight: Extrapolate.CLAMP,
  });

  const opacity = scrollY.interpolate({
    inputRange: [50, 200],
    outputRange: [0, 1],
    extrapolate: Extrapolate.CLAMP,
  });

  return (
    <Container
      style={{
        top,
        opacity,
      }}
    >
      <FakeSearchBarContainer onPress={scrollPageToTop}>
        <SearchIcon />
        <StyledTextInput
          placeholder='Nome ou número...'
          value={searchValue}
          focusable={false}
          editable={false}
        />
      </FakeSearchBarContainer>

      <MenuDropdown inFloatingHeader />
    </Container>
  );
};
