import { useState } from "react";
import { useTheme } from "styled-components/native";

import type { IProductOrder } from "@contexts/@types/useOrder.types";

import { GradientDivider } from "../GradientDivider";
import { ProductTile } from "../ProductTile";
import { Container } from "./styles";

interface OrderProductList {
  data: IProductOrder[];
  onRemove?: (index: number) => void;
  onEdit?: (index: number) => void;
  showControls?: boolean;
}

export const OrderProductList = ({
  data,
  onEdit,
  onRemove,
}: OrderProductList) => {
  const { colors, commonShadowConfig } = useTheme();

  const [hashItemOpen, setIndexTileOpen] = useState<string>("");

  const handleOpenTile = (hasItem: string) => {
    setIndexTileOpen((oldHash) => {
      if (oldHash === hasItem) {
        return "";
      } else {
        return hasItem;
      }
    });
  };

  return (
    <Container
      style={{
        shadowColor: colors.shadows,
        ...commonShadowConfig,
      }}
      data={data}
      ItemSeparatorComponent={() => <GradientDivider verticalMargin={12} />}
      keyExtractor={(item, index) => item.id + index}
      renderItem={({ item, index }) => {
        const hashItem = `${item.id} - ${index}`;
        const isCurrentTileOpen = hashItem === hashItemOpen;
        return (
          <ProductTile
            product={item}
            showExtraDetails={isCurrentTileOpen}
            onPressTile={() => handleOpenTile(hashItem)}
            onRemove={onRemove ? () => onRemove(index) : undefined}
            onEdit={onEdit ? () => onEdit(index) : undefined}
          />
        );
      }}
    />
  );
};
