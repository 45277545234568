import {
  Container,
  EmptyTileListIconStyled,
  Label,
  HighlightText,
  EmptyCardListIconStyled,
} from "./styles";

interface EmptyListComponentProps {
  normalLabel: string;
  highlightLabel: string;
  type: "card" | "tile";
}

export const EmptyListComponent = ({
  normalLabel,
  highlightLabel,
  type,
}: EmptyListComponentProps) => {
  return (
    <Container>
      {type === "card" ? (
        <EmptyCardListIconStyled />
      ) : (
        <EmptyTileListIconStyled />
      )}
      <Label>
        <HighlightText>{highlightLabel}</HighlightText> {normalLabel}
      </Label>
    </Container>
  );
};
