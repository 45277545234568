import { TextInput as NativeTextInput, LayoutChangeEvent } from "react-native";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import axios from "axios";

import type {
  ModuleBottomSheetContext,
  ModuleBottomSheetProps,
  ModuleOrder,
  ModuleSaleFromBD,
  PlaceholderHeights,
} from "./@types/useModuleBottomSheet.types";

import type { TypeTagItem } from "@components/Forms/TagInput";
import type { GenericResponse } from "../@types/generic-response";
import type { IProductOrder } from "./@types/useOrder.types";

import { useUser } from "./useUser";
import { useModulesPage } from "./useModulesPage";
import { toastNotification } from "../hooks/useToast";

import AsyncStorage from "@react-native-async-storage/async-storage";
import { api } from "@services/api";
import { moduleQueries } from "@services/queries";
import { defaultCatch } from "@utils/defaultCatch";
import { ICategoryItem } from "@hooks/@types/useProductCategory.types";

const ModuleBottomSheetContext = createContext({} as ModuleBottomSheetContext);

export const ModuleBottomSheetContextProvider = ({
  children,
}: ModuleBottomSheetProps) => {
  const { user } = useUser();
  const {
    selectedModule,
    handleCloseModulesBottomSheet,
    moduleTypeExtended,
    isModulesBottomSheetOpen,
  } = useModulesPage();

  const inputRef = useRef<NativeTextInput>(null);
  const [surname, setSurname] = useState("");
  const [passwordModule, setPasswordModule] = useState<string | null>(null);

  const [moduleBond, setModuleBond] = useState<TypeTagItem[]>([]);
  const [moduleOrders, setModuleOrders] = useState<ModuleOrder[]>([]);

  const [totalPriceOrder, setTotalPriceOrder] = useState(0);

  const [surnameIsFocused, setSurnameIsFocused] = useState(false);
  const [loadingModule, setLoadingModule] = useState(true);
  const [loadingPassModule, setLoadingPassModule] = useState(false);
  const [updatingSurname, setUpdatingSurname] = useState(false);

  const [placeholderHeights, setPlaceholderHeights] =
    useState<PlaceholderHeights>({
      header: 0,
      footer: 0,
    });

  useEffect(() => {
    if (isModulesBottomSheetOpen && selectedModule) {
      handleSurnameChange(selectedModule?.surname ?? "");

      getOrdersFromSelectedModule();
    } else {
      setUpdatingSurname(false);
      setPasswordModule(null);
      setTotalPriceOrder(0);
      setModuleOrders([]);
    }
  }, [isModulesBottomSheetOpen, selectedModule]);

  const getOrdersFromSelectedModule = async () => {
    if (!selectedModule) return;

    try {
      setLoadingModule(moduleOrders.length === 0);
      const storageIP = JSON.parse((await AsyncStorage.getItem("@ip")) ?? "");

      const { data: moduleSale } = (
        await api.post<GenericResponse<ModuleSaleFromBD | string>>(
          "venda/consultarVenda",
          {
            ID_COMANDA: Number(selectedModule.id),
          }
        )
      ).data;

      if (typeof moduleSale === "string") {
        moduleSale.includes("Nenhuma venda encontrada") && setModuleOrders([]);
        return;
      }

      setTotalPriceOrder(Number(moduleSale.VALOR_FINAL));

      const newModuleOrders: ModuleOrder[] = moduleSale.LANCAMENTO.map(
        ({ NUMERO_LANCAMENTO, ITENS }) => {
          const order: IProductOrder[] = ITENS.map((item) => {
            const additional: ICategoryItem[] = item.ADICIONAL.map(
              (additional) => ({
                id: additional.ID.toString(),
                amount: Number(additional.QUANTIDADE),
                description: additional.DESCRICAO,
                price:
                  additional.TOTAL !== "0"
                    ? Number(additional.TOTAL)
                    : undefined,
                type: "ADICIONAL",
              })
            );
            const totalAdditional = additional.reduce(
              (acc, add) => (add.price ?? 0) + acc,
              0
            );

            const prefixObservations: ICategoryItem[] = item.OBSERVACAO.map(
              (obs) => ({
                id: obs.ID.toString(),
                amount: Number(obs.QUANTIDADE),
                description: obs.OBSERVACAO,
                type: "OBSERVACAO",
              })
            );

            return {
              id: item.ID.toString(),
              name: item.DESCRICAO,
              unit: item.UN_VENDA,
              image:
                storageIP === "0.0.0.0"
                  ? item.IMAGE_URL!
                  : `http://${storageIP}:53649/produto_galeria/${item.ID_IMAGEM_PADRAO}?v=${item.VERSAO_IMAGEM}`,
              price: Number(item.VALOR_UNITARIO),
              totalPrice: Number(item.TOTAL_ITEM) + totalAdditional,
              amount: Number(item.QUANTIDADE),
              prefixObservations,
              productType: "N",
              additional,
            };
          });

          return {
            orderNumber: NUMERO_LANCAMENTO,
            order,
          };
        }
      );

      setModuleOrders(newModuleOrders);
    } catch (error: any) {
      defaultCatch(
        error,
        "getOrdersFromSelectedModule",
        "Erro ao carregar os pedidos!"
      );
    } finally {
      setTimeout(() => {
        setLoadingModule(false);
      }, 200);
    }
  };

  const handleRequestUpdateSurname = async (clear?: boolean) => {
    const query = moduleQueries.updateSurname(
      selectedModule!.number,
      selectedModule!.type,
      clear ? "" : surname
    );

    try {
      setUpdatingSurname(true);

      const { status, data } = (
        await api.post<GenericResponse<any>>("venda/execsql", {
          SQL: query,
        })
      ).data;

      if (status === "error") {
        throw Error(data);
      }
      if (clear) {
        setSurname("");
      }
    } catch (error: any) {
      defaultCatch(
        error,
        "handleRequestUpdateSurname",
        "Erro ao alterar o apelido!"
      );
    } finally {
      setUpdatingSurname(false);
    }
  };

  const handleCloseModule = async () => {
    if (moduleOrders.length === 0 || selectedModule?.status === "F") return;

    try {
      const { data } = (
        await api.post<GenericResponse<any>>("comanda/fecharComanda", {
          ID_COMANDA: Number(selectedModule!.id),
          ID_CAIXA: user!.idCaixa,
        })
      ).data;

      if (typeof data === "string") {
        throw new Error(data);
      }

      if (user?.name === "Teste Grátis" || user?.name === "Apple") {
        setModuleOrders([]);
      }

      handleCloseModulesBottomSheet();
    } catch (error: any) {
      defaultCatch(
        error,
        "handleCloseModule",
        `Erro ao fechar a ${moduleTypeExtended}!`
      );
    }
  };

  const onLayoutComponent = (
    {
      nativeEvent: {
        layout: { height },
      },
    }: LayoutChangeEvent,
    component: "header" | "footer"
  ) => setPlaceholderHeights((old) => ({ ...old, [component]: height }));

  const handleSurnameChange = (text: string) => {
    setSurname(text);
  };

  const handleSurnameFocusChange = (newState: boolean) =>
    setSurnameIsFocused(newState);

  const handleModuleBondChange = (newData: TypeTagItem[]) => {
    setModuleBond(newData);
  };

  const requestModulePassword = async () => {
    if (passwordModule) return;

    const urlByPrefixUsername = {
      DEV: "https://loja.gplusgourmet.com.br/api/v2",
      TOGO: "https://loja.gplustogo.com.br/api/v2",
      GOBOX: "https://loja.gobox.app.br/api/v2",
    };
    const dataRequest = {
      ...user!.userTOGO,
      [moduleTypeExtended]: selectedModule?.number,
    };

    const headers = {
      auth: "Basic f159a34cb3c40d3bc5b888edba9e61d6a0fccc36e6830ccef16aabbbb7c7",
    };

    if (user!.userTOGO) {
      try {
        setLoadingPassModule(true);

        const prefix = user!.userTOGO.Username.split("-")[0] as
          | "DEV"
          | "TOGO"
          | "GOBOX";

        const {
          data: { Chave: currentPass },
        } = (
          await axios.post<GenericResponse<{ Chave: string | null }>>(
            `${urlByPrefixUsername[prefix]}/ConsultarChaveAtivacao${moduleTypeExtended}`,
            dataRequest,
            {
              headers,
            }
          )
        ).data;

        if (currentPass) {
          setPasswordModule(currentPass);
        } else {
          const {
            data: { Chave: newPass },
          } = (
            await axios.post<GenericResponse<{ Chave: string | null }>>(
              `${urlByPrefixUsername[prefix]}/GerarChaveAtivacao${moduleTypeExtended}`,
              dataRequest,
              {
                headers,
              }
            )
          ).data;

          setPasswordModule(newPass!);
        }
      } catch (error: any) {
        defaultCatch(
          error,
          "requestModulePassword",
          `Erro ao buscar senha da ${moduleTypeExtended}!`
        );
      } finally {
        setLoadingPassModule(false);
      }
    } else {
      toastNotification({
        message: `Sem integração com Gplus TOGO`,
        type: "error",
      });
    }
  };

  return (
    <ModuleBottomSheetContext.Provider
      value={{
        passwordModule,
        totalPriceOrder,
        surname,
        inputRef,
        surnameIsFocused,
        moduleBond,
        placeholderHeights,
        moduleOrders,
        loadingModule,
        loadingPassModule,
        updatingSurname,
        onLayoutComponent,
        handleCloseModule,
        handleRequestUpdateSurname,
        handleSurnameChange,
        handleSurnameFocusChange,
        handleModuleBondChange,
        requestModulePassword,
      }}
    >
      {children}
    </ModuleBottomSheetContext.Provider>
  );
};

export const useModuleBottomSheet = () => useContext(ModuleBottomSheetContext);
