import { Platform } from "react-native";
import styled from "styled-components/native";
import * as Device from "expo-device";

export const Container = styled.View`
  background-color: ${({ theme }) => theme.colors.background[100]};

  flex: 1;

  padding-top: ${Platform.OS === "web" && Device.manufacturer === "Apple"
    ? 40
    : 0}px;
`;
