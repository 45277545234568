import { Platform } from "react-native";
import { RFValue } from "react-native-responsive-fontsize";
import styled, { css } from "styled-components/native";

import { STATUS_BAR_HEIGHT } from "../../../../utils/nativeLayoutHelper";

export const HeaderUserContainer = styled.View`
  width: 100%;

  padding: ${STATUS_BAR_HEIGHT + 20}px 20px 0;

  flex-direction: row;

  ${Platform.OS === "web" &&
  css`
    @media screen and (min-color-index: 0) and(-webkit-min-device-pixel-ratio:0) {
      margin-top: 50px;
    }
  `}
`;

export const Title = styled.Text`
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: ${RFValue(20)}px;
  color: ${({ theme }) => theme.colors.text[100]};

  padding: 4px 0;
`;
