import { removeEmojis } from "@utils/string.utils";
import { Fragment, useState } from "react";
import {
  KeyboardTypeOptions,
  NativeSyntheticEvent,
  TextInputKeyPressEventData,
} from "react-native";

import { SpacerBox } from "../../SpacerBox";
import { Tag } from "../../Tag";

import { InputBox, InputContainer, StyledInput, TagStyled } from "./styles";

export type TypeTagItem<T extends Object | null = null> = {
  text: string;
  value?: T;
};

export interface TagInputProps {
  repeatableTags?: boolean;
  commaTriggerNewTag?: boolean;
  spaceTriggerNewTag?: boolean;
  maxTags?: number;
  placeholder?: string;
  data: TypeTagItem[];
  tagReadOnly?: boolean;
  inputEditable?: boolean;
  prefixTagText?: string;
  KeyboardType?: KeyboardTypeOptions;
  onAddTag?: (tag: TypeTagItem) => void;
  onRemoveTag?: (tag: TypeTagItem, index: number) => void;
  onDataChanged?: (newData: TypeTagItem[]) => void;
}

export const TagInput = ({
  commaTriggerNewTag,
  maxTags = 0,
  repeatableTags,
  spaceTriggerNewTag,
  data,
  placeholder,
  tagReadOnly,
  inputEditable,
  prefixTagText,
  KeyboardType,
  onAddTag,
  onRemoveTag,
  onDataChanged,
}: TagInputProps) => {
  const [inputValue, setInputValue] = useState("");

  const newTag = () => ({ text: prefixTagText + inputValue.trim() });

  const addNewTag = () => {
    const tagAlreadyExists = data.some((tag) => tag.text === newTag().text);
    if (!repeatableTags && tagAlreadyExists) {
      // retornar erro ou dar algum feedback
      return;
    }

    onAddTag && onAddTag(newTag());
    onDataChanged && onDataChanged([...data, newTag()]);
    setInputValue("");
  };

  const handleKeyPress = (
    event: NativeSyntheticEvent<TextInputKeyPressEventData>
  ) => {
    const switchClick: { [key: string]: () => void } = {
      ",": () => {
        if (!commaTriggerNewTag || inputValue.trim() === "") return;
        event.preventDefault();
        addNewTag();
      },
      " ": () => {
        if (!spaceTriggerNewTag || inputValue.trim() === "") return;
        event.preventDefault();
        addNewTag();
      },
      Backspace: () => {
        if (inputValue !== "" || data.length === 0) return;
        event.preventDefault();

        const newData = [...data];
        const removed = newData.pop();
        onRemoveTag && onRemoveTag(removed!, data.length - 1);
        onDataChanged && onDataChanged(newData);
      },
    };

    switchClick[event.nativeEvent.key] && switchClick[event.nativeEvent.key]();
  };

  const handleRemoveTag = (tag: TypeTagItem, index: number) => {
    const newData = data.filter((_, tagIndex) => index !== tagIndex);

    onRemoveTag && onRemoveTag(tag, index);
    onDataChanged && onDataChanged(newData);
  };

  return (
    <InputContainer>
      {data.map((tag, index) => (
        <Fragment key={index}>
          <TagStyled
            item={tag}
            index={index}
            onRemove={() => handleRemoveTag(tag, index)}
            readOnly={tagReadOnly}
          >
            {(item, _) => <Tag.Text>{item.text}</Tag.Text>}
          </TagStyled>
          <SpacerBox width={8} />
        </Fragment>
      ))}
      <InputBox>
        <StyledInput
          editable={inputEditable}
          value={inputValue}
          onKeyPress={handleKeyPress}
          onChangeText={(value) => setInputValue(removeEmojis(value))}
          onSubmitEditing={(event) => {
            if (
              inputValue.trim() === "" ||
              (data.length > maxTags && maxTags > 0)
            )
              return;
            event.preventDefault();
            addNewTag();
          }}
          placeholder={placeholder}
          keyboardType={KeyboardType}
        />
      </InputBox>
    </InputContainer>
  );
};
