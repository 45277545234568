import Svg, { SvgProps, Path } from "react-native-svg";

export default (props: SvgProps) => (
  <Svg
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <Path
      d='M11.462 15.77a1.73 1.73 0 1 1-3.462 0 1.73 1.73 0 0 1 3.462 0Zm0-5.77A1.73 1.73 0 1 1 8 10a1.73 1.73 0 0 1 3.462 0Zm0-5.77A1.73 1.73 0 1 1 8 4.23a1.73 1.73 0 0 1 3.462 0Z'
      fill='currentColor'
    />
  </Svg>
);
