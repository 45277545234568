import { RFValue } from "react-native-responsive-fontsize";
import styled from "styled-components/native";

import { CloseIcon, SearchIcon as SearchIconBase } from "@assets/icons";
import { Button } from "@components/Button";

interface SearchBarState {
  isFocused: boolean;
}

export const Container = styled.View<SearchBarState>`
  flex: 1;
  width: 100%;

  background-color: ${({ theme }) => theme.colors.background[200]};

  padding: 10px 20px;

  flex-direction: row;
  align-items: center;

  border-radius: 12px;
  border: 1px solid
    ${({ theme, isFocused }) =>
      isFocused ? theme.colors.primary : theme.colors.background[300]};
`;

export const ClearButton = styled(Button).attrs({
  variant: "transparent",
})``;

export const StyledTextInput = styled.TextInput.attrs(({ theme }) => ({
  placeholderTextColor: theme.colors.text[500],
  selectionColor: theme.colors.primary,
}))`
  flex: 1;
  width: 100%;
  height: 100%;

  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: ${RFValue(16)}px;
  color: ${({ theme }) => theme.colors.text[200]};

  outline-style: none;
`;

export const SearchIcon = styled(SearchIconBase).attrs(({ theme }) => ({
  width: RFValue(20),
  height: RFValue(20),
  color: theme.colors.text[400],
  strokeWidth: 1.6,
}))`
  margin-right: 12px;
`;

export const CloseIconStyled = styled(CloseIcon).attrs(({ theme }) => ({
  width: RFValue(16),
  height: RFValue(16),
  color: theme.colors.text[500],
  strokeWidth: 1.4,
}))``;
