import AsyncStorage from "@react-native-async-storage/async-storage";
import {
  useNavigation,
  StackActions,
  useFocusEffect,
} from "@react-navigation/native";
import { useCallback, useEffect, useState } from "react";

import { api } from "@services/api";

import type { GenericResponse } from "../@types/generic-response";
import type {
  ComboFromBD,
  ComboLevel,
  SelectedComboProduct,
  useProductComboData,
  useProductComboProps,
} from "./@types/useProductCombo.types";

import { defaultCatch } from "@utils/defaultCatch";
import { makeEventNotifier } from "./useEventListener";
import { IProductOrder } from "@contexts/@types/useOrder.types";
import { BackHandler } from "react-native";

const notifier = makeEventNotifier<{
  selectedProduct: IProductOrder;
  selectedLevelID: string;
}>("addOrEditProductComboLevel");

export const useProductAddComboLevelListener = (
  listener: typeof notifier.notify,
  deps: ReadonlyArray<any>
) => {
  notifier.useEventListener(listener, deps);
};

export const useProductCombo = ({
  product,
  isEditingMode,
}: useProductComboProps): useProductComboData => {
  const { dispatch } = useNavigation();

  const productIsCombo = product.productType === "G";
  const [loadingComboLevels, setLoadingComboLevels] = useState(true);
  const [comboLevels, setComboLevels] = useState<ComboLevel[]>([]);
  const [selectedComboLevel, setSelectedComboLevel] =
    useState<ComboLevel | null>(null);
  const [selectedProducts, setSelectedProducts] = useState<
    SelectedComboProduct[]
  >([]);

  const getInvalidLevel = () => {
    // Como o card do produto tbm é uma seção é necessário adicionar +1
    return comboLevels.findIndex((level) => !level.valid) + 1;
  };

  const handlePickProductForLevel = (levelID: string) => {
    setSelectedComboLevel(
      comboLevels.find((level) => level.id === levelID) ?? null
    );
  };

  const handleCancelPickProduct = () => {
    setSelectedComboLevel(null);
  };

  const handleRemoveProductForLevel = (removedLevelID: string) => {
    setSelectedProducts((oldState) =>
      oldState.filter(({ levelID }) => levelID !== removedLevelID)
    );
  };

  const handleRequestEditProduct = (
    selectedLevelID: string,
    product: IProductOrder
  ) => {
    const { additional, observations } = comboLevels.find(
      ({ id }) => id === selectedLevelID
    )!;

    dispatch(
      StackActions.push("Product", {
        selectedLevelID,
        product,
        fixedAdditional: additional,
        fixedObservations: observations,
      })
    );
  };

  useEffect(() => {
    if (productIsCombo) {
      const getComboLevels = async () => {
        try {
          const storageIP = JSON.parse(
            (await AsyncStorage.getItem("@ip")) ?? ""
          );

          const { data } = (
            await api.post<GenericResponse<ComboFromBD[]>>(
              "venda/carregarCombos",
              {
                ID_PRODUTO: Number(product.id),
              }
            )
          ).data;

          const newComboLevels: ComboLevel[] = data.map(
            ({
              ID,
              NOME,
              ORDEM,
              QUANTIDADE,
              VALOR,
              ADICIONAIS,
              OBSERVACOES,
              PRODUTOS,
            }) => ({
              id: ID.toString(),
              title: NOME,
              position: ORDEM,
              amountInLevel: Number(QUANTIDADE),
              valid: false,
              additional: ADICIONAIS.map((add) => ({
                id: add.ID.toString(),
                description: add.DESCRICAO_TOUCH,
                amount: 0,
                type: "ADICIONAL",
                categoryID: "Adicionais",
              })),
              observations: OBSERVACOES.map((obs) => ({
                id: obs.ID.toString(),
                description: obs.OBSERVACAO,
                amount: 0,
                selected: false,
                type: "OBSERVACAO",
                categoryID: "Observações",
              })),
              products: PRODUTOS.map((p) => ({
                id: p.ID.toString(),
                name: p.DESCRICAO_TOUCH ?? "Sem nome",
                unit: "un",
                image: `http://${storageIP}:53649/produto_galeria/${p.ID_IMAGEM_PADRAO}?v=${p.VERSAO_IMAGEM}`,
                price: Number(VALOR),
                productType: "N",
              })),
            })
          );

          setComboLevels(
            newComboLevels.sort(
              (levelA, levelB) => levelA.position - levelB.position
            )
          );
        } catch (error: any) {
          defaultCatch(
            error,
            "getComboLevels",
            "Erro ao carregar os níveis do combo!"
          );
        } finally {
          setLoadingComboLevels(false);
        }
      };

      getComboLevels();
    }
  }, [product]);

  useProductAddComboLevelListener(
    ({ selectedLevelID, selectedProduct }) => {
      const selectedComboLevelID = selectedComboLevel?.id ?? selectedLevelID!;

      setSelectedProducts((oldState) =>
        [
          ...oldState.filter(({ levelID }) => levelID !== selectedComboLevelID),
          {
            levelID: selectedComboLevelID,
            levelPosition:
              selectedComboLevel?.position ??
              oldState.find(({ levelID }) => levelID === selectedComboLevelID)!
                .levelPosition,
            product: {
              ...selectedProduct,
              amount:
                selectedComboLevel?.amountInLevel ?? selectedProduct.amount,
            },
          },
        ].sort((pA, pB) => {
          return pA.levelPosition - pB.levelPosition;
        })
      );

      setSelectedComboLevel(null);
    },
    [selectedComboLevel]
  );

  useEffect(() => {
    setComboLevels((oldState) =>
      oldState.map((level) => ({
        ...level,
        valid: selectedProducts.some((product) => product.levelID === level.id),
      }))
    );
  }, [selectedProducts]);

  useEffect(() => {
    if (isEditingMode && !loadingComboLevels) {
      const productOrder = product as IProductOrder;
      const receivedSelectedProducts = productOrder.comboProducts ?? [];

      setSelectedProducts(receivedSelectedProducts);
    }
  }, [isEditingMode, loadingComboLevels]);

  useFocusEffect(
    useCallback(() => {
      const onBackPress = () => {
        if (!!selectedComboLevel) {
          setSelectedComboLevel(null);
          return true;
        } else {
          return false;
        }
      };

      const subscription = BackHandler.addEventListener(
        "hardwareBackPress",
        onBackPress
      );

      return () => subscription.remove();
    }, [selectedComboLevel])
  );

  return {
    comboLevels,
    loadingComboLevels,
    selectedComboLevel,
    selectedProducts,
    getInvalidLevel,
    handlePickProductForLevel,
    handleCancelPickProduct,
    handleRemoveProductForLevel,
    handleRequestEditProduct,
  };
};
