import * as React from "react";
import Svg, { SvgProps, Path } from "react-native-svg";

export default (props: SvgProps) => (
  <Svg
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <Path
      d='M9.969 10.955a4.353 4.353 0 1 0 0-8.705 4.353 4.353 0 0 0 0 8.705Z'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <Path
      d='M2.938 17.652c0-3.349 2.678-6.697 7.03-6.697 4.353 0 7.032 3.348 7.032 6.697'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </Svg>
);
