import { FlashList, ListRenderItem } from "@shopify/flash-list";
import { useCallback } from "react";
import { Keyboard } from "react-native";

import { useOrder, useProductsPage } from "@contexts/index";
import type { IProductDetailed } from "@contexts/@types/useProductsPage.types";

import { EmptyListComponent, SpacerBox } from "@components/index";
import { CardProduct } from "@components/CardProduct";

import { Container } from "./styles";

export const ProductsList = () => {
  const { filteredProductList, productListRef } = useProductsPage();
  const { footerHeight } = useOrder();

  const columns = 2;

  const renderItem: ListRenderItem<IProductDetailed> = useCallback(
    ({ item }) => <CardProduct product={item} />,
    []
  );

  return (
    <Container>
      <FlashList
        ref={productListRef}
        numColumns={columns}
        onScrollBeginDrag={() => Keyboard.dismiss()}
        showsVerticalScrollIndicator={false}
        ItemSeparatorComponent={() => <SpacerBox height={16} />}
        data={filteredProductList}
        keyExtractor={(item) => item.id}
        renderItem={renderItem}
        ListFooterComponent={<SpacerBox height={footerHeight + 20} />}
        estimatedItemSize={172}
        ListEmptyComponent={
          <EmptyListComponent
            highlightLabel='Nenhum'
            normalLabel='produto foi encontrado.'
            type='card'
          />
        }
      />
    </Container>
  );
};
