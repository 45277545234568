import Svg, { SvgProps, Path } from "react-native-svg";

export default (props: SvgProps) => (
  <Svg
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <Path
      d='M11.5 8v6m-3-6v6M3 5h2m12 0h-2m-2 0V3.5c0-.333-.2-1-1-1H8c-.333 0-1 .2-1 1V5m6 0H7m6 0h2M7 5H5m10 0v10c0 .5-.4 1.7-2 1.7H7c-.667.1-2-.5-2-1.7V5'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </Svg>
);
