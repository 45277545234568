import * as React from "react";
import Svg, { SvgProps, Path } from "react-native-svg";

export default (props: SvgProps) => (
  <Svg
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <Path
      d='M13.768 13.642 18 18m-2.286-9.143A6.857 6.857 0 1 1 2 8.857a6.857 6.857 0 0 1 13.714 0Z'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </Svg>
);
