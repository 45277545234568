import Svg, { Rect, Path } from "react-native-svg";
import { SvgCustomProps } from "./EmptyTileListIcon";

export const EmptyCardListIcon = ({
  alertColor,
  listColor,
  shadowColor,
  strokeWidth,
  ...rest
}: SvgCustomProps) => (
  <Svg
    viewBox='0 0 44 44'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...rest}
  >
    <Rect
      x={5}
      y={8}
      width={10}
      height={10}
      rx={2}
      fill={shadowColor}
      fillOpacity={0.2}
    />
    <Rect
      x={24}
      y={8}
      width={10}
      height={10}
      rx={2}
      fill={shadowColor}
      fillOpacity={0.2}
    />
    <Rect
      x={5}
      y={26}
      width={10}
      height={10}
      rx={2}
      fill={shadowColor}
      fillOpacity={0.2}
    />
    <Path
      d='M14.43 5.592H8.85a2 2 0 0 0-2 2v5.9a2 2 0 0 0 2 2h5.58a2 2 0 0 0 2-2v-5.9a2 2 0 0 0-2-2ZM14.43 24.092H8.85a2 2 0 0 0-2 2v5.9a2 2 0 0 0 2 2h5.58a2 2 0 0 0 2-2v-5.9a2 2 0 0 0-2-2ZM33.58 5.592H28a2 2 0 0 0-2 2v5.9a2 2 0 0 0 2 2h5.58a2 2 0 0 0 2-2v-5.9a2 2 0 0 0-2-2Z'
      stroke={listColor}
      strokeWidth={strokeWidth}
    />
    <Path
      d='M30.252 24.329v7.368m-.66-10.801a2.526 2.526 0 0 1 1.898.239c.381.216.693.528.909.895l7.431 12.797c.332.573.397 1.216.236 1.8-.086.308-.23.595-.427.846-.197.25-.44.458-.717.612a2.54 2.54 0 0 1-1.24.324H22.816a2.49 2.49 0 0 1-1.758-.716 2.369 2.369 0 0 1-.394-2.866l7.434-12.797a2.45 2.45 0 0 1 1.493-1.132v-.002Z'
      stroke={alertColor}
      strokeWidth={strokeWidth}
      strokeLinecap='round'
    />
    <Path
      d='M30.654 34.753a.5.5 0 1 1-.708-.708.5.5 0 0 1 .707.708Z'
      stroke={alertColor}
      strokeWidth={strokeWidth}
      strokeLinecap='round'
    />
  </Svg>
);
