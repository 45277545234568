import { useEffect } from "react";
import { View } from "react-native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";

import { useUser, ProductPageContextProvider } from "@contexts/index";

import {
  Authentication,
  ModulesPage,
  Product,
  Products,
  SplashScreen,
} from "@pages/index";

import { setupApiClient } from "@services/api";

import type { IProduct } from "@contexts/@types/useProductsPage.types";
import type { IProductOrder } from "@contexts/@types/useOrder.types";
import type { IModule } from "@contexts/@types/useModulesPage.types";
import type { ICategoryItem } from "@hooks/@types/useProductCategory.types";

export type ProductPageParams = {
  product: IProduct | IProductOrder;
  index?: number;
  selectedLevelID?: string;
  fixedAdditional?: ICategoryItem[];
  fixedObservations?: ICategoryItem[];
};

declare global {
  namespace ReactNavigation {
    interface RootParamList {
      Authentication: undefined;
      SplashScreen: undefined;
      ModulesPage: undefined;
      Product: ProductPageParams;
      Products: { module: IModule };
    }
  }
}

const { Navigator, Screen } = createNativeStackNavigator();

export const AppRoutes = () => {
  const { handleSignOut, user } = useUser();

  useEffect(() => {
    setupApiClient(handleSignOut);
  }, []);

  return (
    <View style={{ flex: 1 }}>
      <Navigator
        initialRouteName='SplashScreen'
        screenOptions={{
          headerShown: false,
        }}
      >
        {!user ? (
          <>
            <Screen name='SplashScreen' component={SplashScreen} />
            <Screen name='Authentication' component={Authentication} />
          </>
        ) : (
          <>
            <Screen name='ModulesPage' component={ModulesPage} />
            <Screen name='Product'>
              {({ route: { params } }) => (
                <ProductPageContextProvider {...(params as ProductPageParams)}>
                  <Product />
                </ProductPageContextProvider>
              )}
            </Screen>
            <Screen name='Products' component={Products} />
          </>
        )}
      </Navigator>
    </View>
  );
};
