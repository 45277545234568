import { Container } from "./styles";

interface SizedBoxProps {
  children?: React.ReactNode;
  width?: number;
  height?: number;
}

export const SpacerBox = ({ children, width, height }: SizedBoxProps) => {
  return (
    <Container widthCustom={width} heightCustom={height}>
      {children && children}
    </Container>
  );
};
