import { RFValue } from "react-native-responsive-fontsize";
import styled from "styled-components/native";

import { EmptyCardListIcon } from "@assets/customIcons/EmptyCardListIcon";
import { EmptyTileListIcon } from "@assets/customIcons/EmptyTileListIcon";

export const Container = styled.View`
  align-items: center;
  justify-content: center;

  margin-top: 52px;
`;

export const Label = styled.Text`
  width: 200px;

  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: ${RFValue(14)}px;
  color: ${({ theme }) => theme.colors.text[500]};
  text-align: center;

  margin-top: 12px;
`;

export const HighlightText = styled.Text`
  color: ${({ theme }) => theme.colors.primary};
`;

export const BodyListEmpty = styled.View`
  align-items: center;
  justify-content: center;
`;

export const EmptyTileListIconStyled = styled(EmptyTileListIcon).attrs(
  ({ theme }) => ({
    alertColor: theme.colors.primary,
    listColor: theme.colors.text[500],
    shadowColor: theme.colors.background[400],
    width: RFValue(44),
    height: RFValue(44),
    strokeWidth: 1.4,
  })
)``;

export const EmptyCardListIconStyled = styled(EmptyCardListIcon).attrs(
  ({ theme }) => ({
    alertColor: theme.colors.primary,
    listColor: theme.colors.text[500],
    shadowColor: theme.colors.background[400],
    width: RFValue(44),
    height: RFValue(44),
    strokeWidth: 1.4,
  })
)``;
