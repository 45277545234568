import Svg, { SvgProps, Rect, Path } from "react-native-svg";

export interface SvgCustomProps extends Omit<SvgProps, "color"> {
  alertColor: string;
  listColor: string;
  shadowColor: string;
}

export const EmptyTileListIcon = ({
  alertColor,
  listColor,
  shadowColor,
  strokeWidth,
  ...rest
}: SvgCustomProps) => (
  <Svg
    viewBox='0 0 44 44'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...rest}
  >
    <Rect
      x={1.6}
      y={9.9}
      width={36}
      height={10}
      rx={2}
      fill={shadowColor}
      fillOpacity={0.2}
    />
    <Rect
      x={1.6}
      y={26.4}
      width={36}
      height={10}
      rx={2}
      fill={shadowColor}
      fillOpacity={0.2}
    />
    <Path
      d='M18.189 9.79H39m-20.811 16.5h2.741m-2.741-11h13.838M18.19 31.79h.083M6.35 33.99h5.58a2 2 0 0 0 2-2v-5.9a2 2 0 0 0-2-2H6.35a2 2 0 0 0-2 2v5.9a2 2 0 0 0 2 2Zm0-16.5h5.58a2 2 0 0 0 2-2v-5.9a2 2 0 0 0-2-2H6.35a2 2 0 0 0-2 2v5.9a2 2 0 0 0 2 2Z'
      stroke={listColor}
      strokeWidth={strokeWidth}
      strokeLinecap='round'
    />
    <Path
      d='M30.752 22.33v7.367m-.66-10.8a2.526 2.526 0 0 1 1.898.238c.381.216.693.528.909.895l7.431 12.797c.332.573.397 1.217.236 1.8-.086.308-.23.596-.427.846-.197.25-.44.459-.717.612a2.539 2.539 0 0 1-1.24.324H23.316a2.49 2.49 0 0 1-1.758-.716 2.369 2.369 0 0 1-.394-2.866l7.434-12.797a2.45 2.45 0 0 1 1.493-1.131v-.003Z'
      stroke={alertColor}
      strokeWidth={strokeWidth}
      strokeLinecap='round'
    />
    <Path
      d='M31.154 32.753a.5.5 0 1 1-.708-.707.5.5 0 0 1 .708.707Z'
      stroke={alertColor}
      strokeWidth={strokeWidth}
      strokeLinecap='round'
    />
  </Svg>
);
