import { AxiosError } from "axios";
import { toastNotification } from "../hooks/useToast";

export const defaultCatch = (
  err: AxiosError<any>,
  funcName: string,
  defaultMessage: string
) => {
  if (err.response?.status === 401) {
    toastNotification({
      message: "Sessão expirada ou finalizada",
      type: "error",
    });
  } else if (err.message === "Network Error") {
    toastNotification({
      message: "Erro ao conectar com servidor, verifique sua conexão!",
      type: "error",
      timeout: 5000,
    });
  } else {
    toastNotification({
      message: defaultMessage,
      type: "error",
    });
    console.log(`${funcName}: `, err);
  }
};
