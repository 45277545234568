import { Animated, Platform } from "react-native";
import { useMemo } from "react";
import { Extrapolate } from "react-native-reanimated";

import { useProductPage } from "@contexts/useProductPage";
import { SpacerBox } from "@components/SpacerBox";

import { CategoryItems } from "./components/CategoryItems";
import { StickyFooter } from "./components/StickyFooter";
import { StickHeader } from "./components/StickHeader";
import { FieldObservation } from "./components/FieldObservation";
import { ProductHeaderCard } from "./components/ProductHeaderCard";
import { CategoryHeader } from "./components/CategoryHeader";

import { HEADER_IMAGE_HEIGHT } from "./components/ProductHeaderCard/styles";
import { CircularLoader, Container, Content } from "./styles";
import { UnavailableMessage } from "./components/UnavailableBottomMessage";
import { ComboLevelHeader } from "./components/ComboLevelHeader";
import { ComboLevelItem } from "./components/ComboLevelItem";
import { ComboProductsBottomSheet } from "./components/ComboProductsBottomSheet";

export const Product = () => {
  const {
    scrollYPage,
    isLoading,
    categoryServices: { categories, handleChangeCategory },
    comboServices: { comboLevels },
    contentRef,
    footerHeight,
    headerHeight,
  } = useProductPage();

  const headerProduct = {
    title: "FixedComponent",
    data: [
      {
        id: "productInfo",
        body: (
          <>
            <ProductHeaderCard />
            {/* <IngredientsList /> */}
          </>
        ),
      },
    ],
  };

  const productObservations = {
    title: "FixedComponent",
    data: [
      {
        id: "observations",
        body: <FieldObservation />,
      },
    ],
  };

  const placeholderFooter = {
    title: "FixedComponent",
    data: [
      {
        id: "footerPlaceholder",
        body: <SpacerBox height={footerHeight} />,
      },
    ],
  };

  const loadingComponent = {
    title: "FixedComponent",
    data: [
      {
        id: "circularLoading",
        body: <CircularLoader />,
      },
    ],
  };

  const categorySections = useMemo(
    () =>
      categories.map((category) => ({
        title: category.title,
        category,
        data: [
          {
            id: category.id,
            body: (
              <CategoryItems
                key={category.id}
                inputType={category.inputType}
                items={category.items}
                maxAmount={category.maxAmount ?? 0}
                onChangeItem={(item) => handleChangeCategory(category.id, item)}
              />
            ),
          },
        ],
      })),
    [categories]
  );

  const comboSections = useMemo(
    () =>
      comboLevels.map((comboLevel) => ({
        title: comboLevel.title,
        comboLevel,
        data: [
          {
            id: comboLevel.id,
            body: <ComboLevelItem levelID={comboLevel.id} />,
          },
        ],
      })),
    [comboLevels]
  );

  const placeholderHeaderHeight = scrollYPage.interpolate({
    inputRange: [0, HEADER_IMAGE_HEIGHT + headerHeight],
    outputRange: [0, headerHeight],
    extrapolateRight: Extrapolate.CLAMP,
  });

  return (
    <Container>
      <StickHeader />

      <Animated.View
        style={{
          height:
            Platform.OS === "web" || Platform.OS === "ios"
              ? placeholderHeaderHeight
              : 0,
        }}
      />

      <Content
        ref={contentRef}
        scrollEventThrottle={16}
        showsVerticalScrollIndicator={false}
        windowSize={4}
        onScroll={Animated.event(
          [{ nativeEvent: { contentOffset: { y: scrollYPage } } }],
          { useNativeDriver: false }
        )}
        sections={
          isLoading
            ? [headerProduct, loadingComponent]
            : [
                headerProduct,
                ...categorySections,
                ...comboSections,
                productObservations,
                placeholderFooter,
              ]
        }
        keyExtractor={(item) => item.id}
        renderItem={({ item }) => item.body}
        renderSectionHeader={({ section }) => {
          if (section.category) return <CategoryHeader {...section.category} />;

          if (section.comboLevel)
            return <ComboLevelHeader {...section.comboLevel} />;

          return null;
        }}
        stickySectionHeadersEnabled
        contentInset={{ top: Platform.OS === "ios" ? 0 : headerHeight }}
        automaticallyAdjustContentInsets
        automaticallyAdjustKeyboardInsets
      />
      <StickyFooter />
      <UnavailableMessage />
      <ComboProductsBottomSheet />
    </Container>
  );
};
