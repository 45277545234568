import { RFValue } from "react-native-responsive-fontsize";
import styled, { css, DefaultTheme } from "styled-components/native";
import {
  CardsGroupIcon,
  NotesIcon,
  TableIcon,
  ClockIcon,
  StarIcon,
} from "../../../../../assets/icons";
import type { TypeStatus } from "../../../../contexts/@types/useModulesPage.types";
import { convertHexToRgba } from "../../../../utils/string.utils";

interface CardModuleState {
  isFavorite?: boolean;
  status?: TypeStatus;
  isExpanded?: boolean;
  grouped?: boolean;
  isFirstOfRow?: boolean;
  isLastOfRow?: boolean;
}

export const CardModuleContainer = styled.Pressable<CardModuleState>`
  min-height: 124px;
  flex-grow: 1;
  flex-basis: 0;

  margin: 0 8px;

  background-color: ${({ theme }) => theme.colors.background[200]};
  border-radius: 16px;

  ${({ isFirstOfRow }) =>
    isFirstOfRow &&
    css`
      margin-left: 20px;
    `}

  ${({ isLastOfRow }) =>
    isLastOfRow &&
    css`
      margin-right: 20px;
    `}

  ${({ isExpanded }) =>
    isExpanded &&
    css`
      min-width: ${RFValue(200)}px;
    `}
`;

export const HeaderCard = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: 16px 16px 0 16px;
  margin-bottom: 12px;
`;

export const IconBox = styled.View<{ status: TypeStatus; grouped: boolean }>`
  padding: 10px;

  background-color: ${({ theme, status, grouped }) =>
    convertHexToRgba(
      grouped ? theme.colors.purple : iconColorByStatus(theme, status),
      0.12
    )};
  border-radius: ${RFValue(20)}px;
`;

export const NumberBox = styled.View`
  border: 1px solid ${({ theme }) => theme.colors.background[300]};
  border-radius: 8px;

  padding: 4px 8px;
`;

export const NumberText = styled.Text`
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: ${RFValue(14)}px;
  color: ${({ theme }) => theme.colors.text[200]};
`;

export const DarkenNumberText = styled.Text`
  color: ${({ theme }) => theme.colors.text[500]};
`;

export const CardBody = styled.View`
  padding: 0px 16px 12px 16px;
`;

export const CardTitle = styled.Text`
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: ${RFValue(14)}px;
  color: ${({ theme }) => theme.colors.text[200]};
`;

export const CardSubText = styled.Text`
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: ${RFValue(14)}px;
  color: ${({ theme }) => theme.colors.text[500]};
`;

export const CardFooter = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  background-color: ${({ theme }) =>
    convertHexToRgba(
      theme.colors.background[300],
      theme.title === "light" ? 0.2 : 0.6
    )};

  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;

  padding: 12px 16px;
`;

export const UsageTime = styled.View`
  flex-direction: row;
  align-items: center;
`;

export const UsageTimeText = styled.Text<CardModuleState>`
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: ${RFValue(12)}px;
  color: ${({ theme, status }) =>
    status !== "L" ? theme.colors.text[200] : theme.colors.text[500]};

  margin-left: 8px;
`;

export const FavoritePressableArea = styled.Pressable`
  position: absolute;
  right: 0;

  padding: 12px 16px;
`;

const iconStyle = {
  width: RFValue(20),
  height: RFValue(20),
  strokeWidth: 1.4,
};

const iconFooterStyle = {
  width: RFValue(16),
  height: RFValue(16),
  strokeWidth: 1.2,
};

const iconColorByStatus = (
  theme: DefaultTheme,
  status: TypeStatus,
  grouped?: boolean,
  isClockIcon?: boolean
) => {
  const colors = {
    L: isClockIcon ? theme.colors.text[500] : theme.colors.text[400],
    O: theme.colors.blue,
    F: theme.colors.red,
  };

  if (grouped) return theme.colors.purple;

  return colors[status];
};

export const NotesIconStyled = styled(NotesIcon).attrs<CardModuleState>(
  ({ theme, status }) => ({
    ...iconStyle,
    color: iconColorByStatus(theme, status!),
  })
)<CardModuleState>``;

export const TableIconStyled = styled(TableIcon).attrs<CardModuleState>(
  ({ theme, status }) => ({
    ...iconStyle,
    color: iconColorByStatus(theme, status!),
  })
)<CardModuleState>``;

export const ClockIconStyled = styled(ClockIcon).attrs<CardModuleState>(
  ({ theme, status, grouped }) => ({
    ...iconFooterStyle,
    color: iconColorByStatus(theme, status!, grouped && status !== "L", true),
  })
)<CardModuleState>``;

export const CardsGroupIconStyled = styled(CardsGroupIcon).attrs({
  ...iconStyle,
})`
  color: ${({ theme }) => theme.colors.purple};
`;
export const StarIconStyled = styled(StarIcon).attrs<CardModuleState>(
  ({ theme, isFavorite, status, grouped }) => ({
    ...iconFooterStyle,
    color: !isFavorite
      ? theme.colors.text[500]
      : iconColorByStatus(theme, status!, grouped),
    fill: !isFavorite
      ? "transparent"
      : convertHexToRgba(
          grouped ? theme.colors.purple : iconColorByStatus(theme, status!),
          0.32
        ),
  })
)<CardModuleState>``;

export const CircularLoader = styled.ActivityIndicator.attrs<CardModuleState>(
  ({ theme, status, grouped }) => ({
    color: iconColorByStatus(theme, status!, grouped),
    size: RFValue(16),
  })
)<CardModuleState>``;
