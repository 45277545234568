import DashedLine from "react-native-dashed-line";
import { RFValue } from "react-native-responsive-fontsize";
import styled, { css } from "styled-components/native";

import { Button, PriceHighlight } from "@components/index";
import { convertHexToRgba } from "@utils/string.utils";

import dark from "../../../../../../styles/themes/dark";
import { NAV_BAR_HEIGHT } from "@utils/nativeLayoutHelper";

export const Container = styled.View`
  flex: 1;
  width: 100%;

  background-color: ${({ theme }) =>
    convertHexToRgba(theme.colors.background[100], 0.96)};

  border-top-width: 1px;
  border-top-color: ${({ theme }) => theme.colors.background[300]};

  position: absolute;
  bottom: 0;
  left: 0;

  padding: 16px 20px ${20 + NAV_BAR_HEIGHT}px;

  z-index: 1;
`;

export const TotalOrder = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding-bottom: 16px;
`;

export const TotalOrderTitle = styled.Text`
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: ${RFValue(16)}px;
  color: ${({ theme }) => theme.colors.text[100]};
`;

export const TotalOrderPrice = styled(PriceHighlight).attrs(({ theme }) => ({
  unitColor: theme.colors.primary,
  color: theme.colors.text[100],
  bold: true,
}))``;

export const DashedLineStyled = styled(DashedLine).attrs(({ theme }) => ({
  dashColor: theme.colors.background[300],
  dashThickness: 1,
  dashLength: 8,
  dashGap: 8,
}))`
  margin-bottom: 16px;
`;

export const Actions = styled.View`
  flex-direction: row;
  align-items: center;
`;

export const CloseModuleButton = styled(Button).attrs(
  ({ theme, disabled }) => ({
    variant: "transparent",
    textButtonStyle: {
      fontSize: RFValue(16),
      fontFamily: theme.fonts.semiBold,
      color: disabled ? theme.colors.text[500] : theme.colors.primary,
    },
  })
)`
  flex: 1;
  padding: 10px 20px;

  margin-right: 16px;
`;

export const AddOrderButton = styled(Button).attrs(({ theme, disabled }) => ({
  variant: "solid",
  textButtonStyle: {
    fontSize: RFValue(16),
    color: disabled ? theme.colors.text[500] : dark.colors.text[100],
  },
}))`
  flex: 1;
  padding: 10px 20px;

  ${({ disabled, theme }) =>
    disabled &&
    css`
      background-color: ${theme.colors.background[300]};
    `}
`;
