import { MotiView } from "moti";
import { RFValue } from "react-native-responsive-fontsize";
import styled, { css } from "styled-components/native";

import { Button, PriceHighlight } from "@components/index";

import { fadePresence } from "@utils/commonAnimations";
import {
  NAV_BAR_HEIGHT,
  SCREEN_HEIGHT,
  SCREEN_WIDTH,
} from "@utils/nativeLayoutHelper";
import { convertHexToRgba } from "@utils/string.utils";
import dark from "../../styles/themes/dark";
import { Platform } from "react-native";

interface FooterOrderProps {
  absolutePosition?: boolean;
}

export const Container = styled(MotiView).attrs({
  from: {
    bottom: -SCREEN_HEIGHT,
  },
  animate: {
    bottom: 0,
  },
  exit: {
    bottom: -SCREEN_HEIGHT,
  },
})`
  width: ${SCREEN_WIDTH}px;

  position: absolute;
  left: 0;

  border-top-color: ${({ theme }) => theme.colors.background[300]};
`;

export const BackgroundAnimated = styled(MotiView)`
  flex: 1;
  width: 100%;
  height: 100%;

  position: absolute;

  background-color: ${({ theme }) => theme.colors.background[100]};
`;

export const SummaryAnimation = styled(MotiView).attrs({
  ...fadePresence,
})`
  flex: 1;
`;

export const FullContentAnimation = styled(MotiView).attrs({
  from: {
    opacity: 0,
    height: 0,
  },
  animate: {
    opacity: 1,
    height: SCREEN_HEIGHT,
  },
  exit: {
    opacity: 0,
    height: 0,
  },
})`
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
`;

export const FooterOrder = styled.View<FooterOrderProps>`
  width: 100%;

  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: 16px 20px ${24 + (Platform.OS === "ios" ? NAV_BAR_HEIGHT : 0)}px;

  ${({ theme, absolutePosition }) =>
    absolutePosition &&
    css`
      background-color: ${convertHexToRgba(theme.colors.background[100], 0.96)};

      border-top-width: 1px;
      border-top-color: ${theme.colors.background[300]};

      position: absolute;
      bottom: 0;
      left: 0;
    `}
`;

export const OrderTotalContainer = styled.View``;

export const Total = styled(PriceHighlight).attrs(({ theme }) => ({
  highlightSize: 20,
  normalSize: 14,
  bold: true,
  unitColor: theme.colors.primary,
  color: theme.colors.text[100],
}))``;

export const Amount = styled.Text`
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: ${RFValue(12)}px;
  color: ${({ theme }) => theme.colors.text[500]};
`;

export const TotalText = styled(Amount)``;

export const OpenOrderButton = styled(Button).attrs(({ theme }) => ({
  variant: "solid",
  textButtonStyle: {
    fontSize: RFValue(16),
  },
}))`
  padding: 10px 20px;
`;

export const ConfirmOrderButton = styled(OpenOrderButton).attrs(
  ({ theme, disabled }) =>
    disabled && {
      textButtonStyle: {
        color: theme.colors.text[500],
      },
    }
)`
  ${({ disabled, theme }) =>
    disabled &&
    css`
      background-color: ${theme.colors.background[300]};
    `}
`;

export const CircularLoader = styled.ActivityIndicator.attrs(({ theme }) => ({
  color: theme.colors.text[500],
}))``;
