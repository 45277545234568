import { RFValue } from "react-native-responsive-fontsize";
import styled from "styled-components/native";
import { Tag } from "../../Tag";

interface InputProps {
  focused?: boolean;
  error?: boolean;
}

export const InputContainer = styled.View<InputProps>`
  flex-direction: row;
  flex-wrap: wrap;
  background-color: ${({ theme }) => theme.colors.background[200]};
  border-radius: 8px;

  align-items: center;

  border: 1px solid
    ${({ theme, focused, error }) =>
      error
        ? theme.colors.red
        : focused
        ? theme.colors.primary
        : theme.colors.background[300]};

  padding: ${RFValue(4)}px 20px;
`;

export const InputBox = styled.View`
  flex: 1;
  min-width: ${RFValue(100)}px;
`;

export const StyledInput = styled.TextInput.attrs<InputProps>(
  ({ theme, selectionColor, placeholderTextColor, error }) => ({
    selectionColor:
      selectionColor ?? (error ? theme.colors.red : theme.colors.primary),
    placeholderTextColor: placeholderTextColor ?? theme.colors.text[500],
  })
)<InputProps>`
  width: 100%;

  font-size: ${RFValue(16)}px;
  font-family: ${({ theme }) => theme.fonts.regular};
  color: ${({ theme }) => theme.colors.text[200]};
  line-height: ${RFValue(20)}px;

  padding: ${RFValue(8)}px 0;
`;

export const TagStyled = styled(Tag.Root)`
  margin: ${RFValue(2)}px 0;
` as typeof Tag.Root;
