import { RFValue } from "react-native-responsive-fontsize";
import styled from "styled-components/native";
import { PriceHighlight } from "@components/PriceHighlight";

interface CardProductContainerProps {
  isEmpty?: boolean;
}

export const Container = styled.Pressable<CardProductContainerProps>`
  flex: 1;
  flex-basis: 0;
  align-items: center;

  width: 100%;
  min-height: 160px;

  border-radius: 16px;

  margin: 0 8px;
`;

export const Background = styled.View`
  position: absolute;

  width: 100%;
  height: 75%;

  margin-top: 25%;

  border-radius: 16px;

  background-color: ${({ theme }) => theme.colors.background[200]};
`;

export const ProductImage = styled.ImageBackground.attrs((_) => ({
  imageStyle: {
    borderRadius: 24,
  },
}))`
  width: 88px;
  height: 88px;
`;

export const Content = styled.View`
  flex-grow: 1;

  padding: 16px 12px;

  justify-content: center;
  align-items: center;
`;

export const ProductTitle = styled.Text<{ hasPromotion: boolean }>`
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: ${RFValue(12)}px;
  color: ${({ theme }) => theme.colors.text[200]};
  text-align: center;

  text-transform: capitalize;

  margin-bottom: ${({ hasPromotion }) => (hasPromotion ? 2 : 6)}px;
`;

export const PromotionContainer = styled.View`
  flex-direction: row;
  align-items: center;

  margin-top: 2px;
`;

export const OldPrice = styled.View`
  margin-left: 12px;
`;

export const Line = styled.View`
  width: 100%;
  height: 1px;

  background-color: ${({ theme }) => theme.colors.text[500]};

  position: absolute;
  right: 0;
  bottom: ${RFValue(8)}px;
`;

export const PromotionText = styled.Text`
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: ${RFValue(10)}px;
  color: ${({ theme }) => theme.colors.text[500]};
  margin-top: 2px;
`;

export const ProductPrice = styled(PriceHighlight).attrs(({ theme }) => ({
  unitColor: theme.colors.primary,
  highlightSize: 14,
}))``;
