import { useNavigation } from "@react-navigation/native";
import { Extrapolate } from "react-native-reanimated";

import { useProductPage } from "@contexts/useProductPage";

import { LongArrowStyled } from "../../../Products/styles";
import {
  ButtonReturn,
  Container,
  HeaderProduct,
  ProductTitle,
  TitleHeader,
} from "./styles";
import { HEADER_IMAGE_HEIGHT } from "../ProductHeaderCard/styles";

export const StickHeader = () => {
  const { product, scrollYPage, headerHeight, handleChangeDimensions } =
    useProductPage();
  const { goBack } = useNavigation();

  const headerOpacity = scrollYPage.interpolate({
    inputRange: [HEADER_IMAGE_HEIGHT - headerHeight, HEADER_IMAGE_HEIGHT],
    outputRange: [0, 1],
    extrapolate: Extrapolate.CLAMP,
  });

  return (
    <Container
      onLayout={({
        nativeEvent: {
          layout: { height },
        },
      }) => handleChangeDimensions("header", height)}
    >
      <HeaderProduct style={{ opacity: headerOpacity }}>
        <TitleHeader>
          <ProductTitle>{product.name}</ProductTitle>
        </TitleHeader>
      </HeaderProduct>
      <ButtonReturn variant='solid' onPress={() => goBack()} isTheFirst>
        <LongArrowStyled />
      </ButtonReturn>
    </Container>
  );
};
