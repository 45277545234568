import styled from "styled-components/native";

interface RadioState {
  isSelected: boolean;
}

export const Container = styled.Pressable<RadioState>`
  border: 1.4px solid
    ${({ theme, isSelected }) =>
      isSelected ? theme.colors.primary : theme.colors.background[500]};

  padding: 4px;
`;

export const CenterPoint = styled.View`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.primary};
`;
