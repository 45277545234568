import { useEffect, useState } from "react";
import {
  ActivityIndicator,
  Pressable,
  KeyboardAvoidingView,
} from "react-native";
import { AnimatePresence } from "moti";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import AsyncStorage from "@react-native-async-storage/async-storage";

import { FormConnectionNetwork } from "./components/FormConnectionNetwork";
import { FormSignIn } from "./components/FormSignIn";
import { HeaderGreetings } from "./components/HeaderGreeting";
import {
  ButtonPrev,
  Container,
  FooterButtons,
  LongArrowLeftIcon,
  ButtonSubmit,
  AnimatedTextView,
  LongArrowRightIcon,
  ButtonText,
  FormContainerAnimated,
} from "./styles";
import { formConnectionNetworkSchema, formSignInSchema } from "./schema";
import { useUser } from "@contexts/useUser";
import dark from "../../styles/themes/dark";
import { DisconnectAnotherDevicesMessage } from "./components/DisconnectAnotherDevicesMessage";

export type FormConnectionNetworkData = {
  ip: string;
};

export type FormSignInData = {
  user: string;
  password: string;
};

export const Authentication = () => {
  const [formPage, setFormPage] = useState("connectionNetwork");
  const { handleSetLocalIp, handleSignIn, isLoading } = useUser();
  const { control, handleSubmit, setValue } = useForm<
    FormConnectionNetworkData | FormSignInData
  >({
    resolver: yupResolver(
      formPage === "connectionNetwork"
        ? formConnectionNetworkSchema
        : formSignInSchema
    ),
  });

  useEffect(() => {
    const setIPFromStorage = async () => {
      const ip = await AsyncStorage.getItem("@ip");

      if (ip !== null) {
        setValue("ip", JSON.parse(ip));
      }
    };
    setIPFromStorage();
  }, []);

  const onSubmit: SubmitHandler<
    FormConnectionNetworkData | FormSignInData
  > = async (data) => {
    if (formPage === "signIn") {
      await handleSignIn(data as FormSignInData);
    } else {
      handleSetLocalIp((data as FormConnectionNetworkData).ip);
      setFormPage("signIn");
    }
  };

  const handleReturn = () => {
    setFormPage("connectionNetwork");
  };

  return (
    <KeyboardAvoidingView style={{ flex: 1 }} behavior='position'>
      <Container>
        <HeaderGreetings />
        <AnimatePresence exitBeforeEnter>
          {formPage === "connectionNetwork" ? (
            <FormContainerAnimated key='connectionNetwork' isInitialForm>
              <FormConnectionNetwork
                control={control}
                onSubmit={handleSubmit(onSubmit)}
              />
            </FormContainerAnimated>
          ) : (
            <FormContainerAnimated key='signIn'>
              <FormSignIn control={control} onSubmit={handleSubmit(onSubmit)} />
            </FormContainerAnimated>
          )}
        </AnimatePresence>
        <FooterButtons>
          <AnimatePresence>
            {formPage !== "connectionNetwork" && !isLoading && (
              <Pressable onPress={handleReturn}>
                <ButtonPrev>
                  <LongArrowLeftIcon />
                </ButtonPrev>
              </Pressable>
            )}
          </AnimatePresence>
          <ButtonSubmit disabled={isLoading} onPress={handleSubmit(onSubmit)}>
            <AnimatePresence exitBeforeEnter>
              {formPage === "connectionNetwork" ? (
                <AnimatedTextView key='Next' isInitialForm>
                  <ButtonText>Próximo</ButtonText>
                  <LongArrowRightIcon />
                </AnimatedTextView>
              ) : (
                <AnimatedTextView key='Enter'>
                  <ButtonText>Entrar </ButtonText>
                  {isLoading && (
                    <ActivityIndicator color={dark.colors.text[100]} />
                  )}
                </AnimatedTextView>
              )}
            </AnimatePresence>
          </ButtonSubmit>
        </FooterButtons>
      </Container>
      <DisconnectAnotherDevicesMessage />
    </KeyboardAvoidingView>
  );
};
