import { TypeModule } from "../contexts/@types/useModulesPage.types";

export const moduleQueries = {
  getAll: (userId: number, modulesType: string) => `
  SELECT IIF(MS.ID IS NOT NULL, 'S', 'N') AS MESA_FAVORITADA,
       VDC.ID_COMANDA AS ID,
       VDC.ID_COMANDA_PRINCIPAL,
       VDC.ID_VENDA_CABECALHO,
       VDC.NUMERO_COMANDA,
       COALESCE(VDC.EM_USO, 'N') AS EM_USO,
       VDC.TIPO,
       TRIM(VDC.APELIDO) AS APELIDO,
       IIF(VDC.ID_COMANDA_PRINCIPAL IS NOT NULL, 'S', 'N') AS AGRUPADA,

       CASE VDC.FECHADA WHEN 'S' THEN
         'F'
       ELSE
       CASE WHEN VDC.ID_VENDA_CABECALHO IS NOT NULL THEN
         'O'
       ELSE
         'L'
       END
       END AS STATUS,
       IIF(VDC.ID_VENDA_CABECALHO IS NOT NULL, VDC.TEMPO_ULT_PED, '') 
  AS TEMPO_ULT_PED

     FROM VIEWGESTAODELIVERYCOMANDA AS VDC
  LEFT JOIN MESAS_SALVAS AS MS  
  ON (VDC.NUMERO_COMANDA = MS.NUMERO_MESA AND 
    VDC.TIPO = MS.TIPO AND MS.ID_OPERADOR = ${userId})

  WHERE VDC.SITUACAO = 'A'
    AND VDC.TIPO IN (${modulesType})

  ORDER BY VDC.NUMERO_COMANDA
  `,
  updateSurname: (
    numberModule: number,
    type: TypeModule,
    surname: string | null
  ) => `
  UPDATE ecf_venda_comanda SET
   apelido = ${
     surname ? `'${surname}'` : null
   } WHERE numero_comanda = ${numberModule} AND tipo = '${type}'
  `,
  addToFavorite: (userId: number, numberModule: number, type: TypeModule) => `
  UPDATE OR INSERT INTO 
    MESAS_SALVAS (ID_OPERADOR, NUMERO_MESA, TIPO) 
    VALUES (${userId}, ${numberModule}, '${type}')
  MATCHING (ID_OPERADOR, NUMERO_MESA, TIPO);`,
  deleteFavorite: (userId: number, numberModule: number, type: TypeModule) => `
  DELETE FROM MESAS_SALVAS WHERE 
    ID_OPERADOR = ${userId} 
    AND NUMERO_MESA = ${numberModule} 
    AND TIPO = '${type}'
  `,
};

export const productQueries = {
  getAll: () => `
  SELECT P.ID,
       P.DESCRICAO_TOUCH,
       P.VALOR_PROMOCIONAL,
       P.VALOR_VENDA,
       COALESCE(P.ORDEM_TOUCH, '0') AS ORDEM_TOUCH,
       P.TIPO,
       P.ID_IMAGEM_PADRAO,
       P.VERSAO_IMAGEM,
       P.ID_GRUPO,
       P.UN_VENDA AS UNIDADE,
       P.PRODUTO_PIZZA,
       P.POSSUI_CATEGORIA AS TIPO_CATEGORIA,
       IIF(TIPO = 'G', 'N', P.VENDE_FRACIONADO) AS PODE_FRACIONAR

  FROM PRODUTO AS P
  WHERE TIPO != 'P'
  AND TIPO != 'D'
  AND SITUACAO           = 1
  AND P.MOSTRAR_CARDAPIO = 1
  `,
  getAdditional: (productID: number) => `
  SELECT P.ID,
       P.DESCRICAO_TOUCH,
       P.VALOR_VENDA

  FROM PRODUTO AS P
  INNER JOIN PRODUTO_VINC_ADC AS PVA ON (P.ID = PVA.ID_ADICIONAL)

  WHERE PVA.ID_PRODUTO = ${productID}
  AND PVA.SITUACAO = 1
  AND P.ADICIONAL    = 'S'
  `,
  getPrefixObservations: (productID: number) => `
  SELECT OBS.ID,
       OBS.OBSERVACAO,
       OBS.OBS_GLOBAL

  FROM OBSERVACOES AS OBS
  INNER JOIN PRODUTO_VINC_OBS AS PVO ON (OBS.ID = PVO.ID_OBSERVACAO)

  WHERE PVO.ID_PRODUTO = ${productID}
  AND OBS.SITUACAO   = 1
  `,
};

export const productGroupQueries = {
  getAll: () => `
    SELECT ID, DESCRICAO FROM CLASSIFICACAO_GRUPO WHERE SITUACAO = 'A'
  `,
};
