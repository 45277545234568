import { AnimatePresence } from "moti";
import {
  CancelButton,
  ConfirmButton,
  Container,
  FooterButtons,
  TextContent,
  Title,
  Overlay,
} from "./styles";

interface BottomMessageModal {
  title: string;
  message?: string;
  show: boolean;
  onConfirm?: () => void;
  confirmButtonText?: string;
  onCancel?: () => void;
  cancelButtonText?: string;
  customMessageContent?: React.ReactNode;
}

export const BottomMessageModal = ({
  title,
  message = "",
  show,
  cancelButtonText,
  confirmButtonText,
  customMessageContent,
  onCancel,
  onConfirm,
}: BottomMessageModal) => {
  return (
    <AnimatePresence>
      {show && (
        <>
          <Overlay />
          <Container>
            <Title>{title}</Title>
            {customMessageContent ?? <TextContent>{message}</TextContent>}
            <FooterButtons>
              {onCancel && (
                <CancelButton
                  variant='transparent'
                  text={cancelButtonText ?? "Não"}
                  onPress={onCancel}
                />
              )}
              <ConfirmButton
                variant='solid'
                text={confirmButtonText ?? "Sim"}
                onPress={onConfirm}
              />
            </FooterButtons>
          </Container>
        </>
      )}
    </AnimatePresence>
  );
};
