import { MotiView } from "moti";
import { useState, forwardRef } from "react";
import { TextInput, Platform } from "react-native";
import { useDebouncedCallback } from "use-debounce";

import { fadePresence } from "@utils/commonAnimations";

import {
  ClearButton,
  CloseIconStyled,
  Container,
  SearchIcon,
  StyledTextInput,
} from "./styles";

interface SearchBarProps {
  placeholder?: string;
  onChange: (value: string) => void;
  debounce?: number;
}

export const SearchBar = forwardRef<TextInput, SearchBarProps>(
  ({ placeholder, onChange, debounce }, ref) => {
    const [search, setSearch] = useState("");
    const [isFocused, setIsFocused] = useState(false);

    const debounced = useDebouncedCallback((value) => {
      onChange(value.trim());
    }, debounce);

    const handleChange = (newSearch: string) => {
      if (debounce) {
        setSearch(newSearch);
        debounced(newSearch);
      } else {
        setSearch(newSearch);
        onChange(newSearch.trim());
      }
    };

    return (
      <Container isFocused={isFocused}>
        <SearchIcon />
        <StyledTextInput
          ref={ref}
          placeholder={placeholder ?? "Pesquisar..."}
          onChangeText={handleChange}
          value={search}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          keyboardType={
            Platform.OS === "ios" || Platform.OS === "web"
              ? "ascii-capable"
              : "visible-password"
          }
        />
        {search !== "" && (
          <MotiView {...fadePresence}>
            <ClearButton onPress={() => handleChange("")}>
              <CloseIconStyled />
            </ClearButton>
          </MotiView>
        )}
      </Container>
    );
  }
);
