import { createContext, useContext, useEffect, useRef, useState } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useNavigation } from "@react-navigation/native";

import type {
  UserContextData,
  UserContextProviderProps,
  IUser,
  SignInProps,
  SignInResponse,
} from "./@types/useUser.types";

import { api } from "@services/api";
import { toastNotification } from "../hooks/useToast";
import { capitalize } from "@utils/string.utils";
import { GenericResponse } from "src/@types/generic-response";
import { initServer, shutdownServer } from "@services/mirageAPI";

export const UserContext = createContext({} as UserContextData);

export let localIP = "";

export const UserContextProvider = ({ children }: UserContextProviderProps) => {
  const [user, setUser] = useState<IUser>();
  const [isLoading, setIsLoading] = useState(false);
  const { navigate, reset } = useNavigation();
  const [showDisconnectDevicesMessage, setShowDisconnectDevicesMessage] =
    useState(false);
  const userCredentialsSaved = useRef<SignInProps | undefined>();

  const storeData = async (user: IUser) => {
    try {
      const jsonUser = JSON.stringify(user);
      const jsonIP = JSON.stringify(localIP);

      await AsyncStorage.multiSet([
        ["@user", jsonUser],
        ["@ip", jsonIP],
      ]);
    } catch (e) {
      toastNotification({
        message: "Erro ao armazenar dados localmente!",
        type: "error",
      });

      console.log("storeData: ", e);
    }
  };

  const getUserFromStorage = async () => {
    try {
      const storageUser = await AsyncStorage.getItem("@user");
      const ip = await AsyncStorage.getItem("@ip");

      if (storageUser !== null && ip !== null) {
        if (ip.includes("0.0.0.0")) {
          initServer();
        }

        localIP = ip;
        api.defaults.baseURL = `http://${JSON.parse(ip)}:300/v1/`;
        setUser(JSON.parse(storageUser));

        return;
      }

      reset({
        index: 0,
        routes: [{ name: "Authentication" }],
      });
    } catch (e) {
      toastNotification({
        message: "Erro ao consultar dados do usuário localmente!",
        type: "error",
      });

      console.log("getUserFromStorage: ", e);
    }
  };

  useEffect(() => {
    getUserFromStorage();
  }, []);

  const handleSetLocalIp = (newIp: string) => {
    localIP = newIp;
    api.defaults.baseURL = `http://${newIp.trim()}:300/v1/`;
  };

  const handleSignIn = async (
    credentials?: SignInProps,
    override?: boolean
  ) => {
    if (
      credentials?.user === "Apple" &&
      credentials.password === "teste" &&
      localIP === "0.0.0.0"
    ) {
      initServer();
    }

    showDisconnectDevicesMessage && setShowDisconnectDevicesMessage(false);

    const currentUserCredentials = override
      ? userCredentialsSaved.current
      : credentials;

    try {
      setIsLoading(true);

      const { data } = (
        await api.post<SignInResponse>("usuario/login", {
          user: currentUserCredentials?.user,
          password: currentUserCredentials?.password,
          override: override ? 1 : 0,
        })
      ).data;

      if (typeof data === "string") {
        throw new Error("Usuário inválido");
      }

      const environments = data.AMBIENTES.map((env) => ({
        id: env.ID,
        name: capitalize(env.NOME),
      }));

      const newUser = {
        id: data.ID,
        name: data.NOME,
        idCaixa: data.ID_CAIXA,
        userTOGO: data.TOGO,
        environments,
        usedModules: data.MODULOS_UTILIZADOS,
        askTableInCommands: data.PERGUNTAR_MESA,
        apiVersion: data.API_VERSION,
      };

      await AsyncStorage.multiSet([
        ["@TOKEN", data.ACCESS_TOKEN],
        ["@REFRESH_TOKEN", data.REFRESH_TOKEN],
      ]);

      api.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${data.ACCESS_TOKEN}`;

      const storageUser = JSON.parse(
        (await AsyncStorage.getItem("@user")) ?? "{}"
      );

      setUser({ ...newUser, idCaixa: storageUser?.iCaixa ?? newUser.idCaixa });

      await storeData(newUser);

      navigate("ModulesPage");
    } catch (error: any) {
      switch (error.message) {
        case "Network Error":
          toastNotification({
            message: "IP local incorreto ou sem conexão com a rede local!",
            type: "error",
            timeout: 4000,
          });
          break;

        case "Usuário inválido":
          toastNotification({
            message: "Usuário ou senha estão incorretos!",
            type: "error",
          });
          break;

        case "Request failed with status code 401":
          userCredentialsSaved.current = credentials;
          setShowDisconnectDevicesMessage(true);

          break;

        default:
          toastNotification({
            message: "Erro inesperado ao tentar se conectar com a rede local!",
            type: "error",
          });
          console.log("handleSignIn: ", error);

          break;
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleSignOut = async () => {
    shutdownServer();

    try {
      setUser(undefined);
      await AsyncStorage.multiRemove([
        "@user",
        "@userToGO",
        "@TOKEN",
        "@REFRESH_TOKEN",
      ]);

      api.defaults.headers.common["Authorization"] = undefined;

      setTimeout(() => {
        reset({
          index: 0,
          routes: [{ name: "Authentication" }],
        });
      }, 2000);

      await api.post<GenericResponse<any>>("usuario/logout", {
        userID: user?.id,
      });
    } catch (err: any) {
      console.log("handleSignOut: ", err);
    }
  };

  const handleChangeUserEnvironment = async (newEnvironmentID: number) => {
    const newUser = { ...user!, idCaixa: newEnvironmentID };
    setUser(newUser);
    await AsyncStorage.setItem("@user", JSON.stringify(newUser));
  };

  const handleCloseDisconnectDevicesMessage = () => {
    setShowDisconnectDevicesMessage(false);
  };

  const handleActiveFreeTest = () => {
    initServer();

    handleSetLocalIp("0.0.0.0");
    handleSignIn({ user: "Teste Grátis", password: "teste" });
  };

  return (
    <UserContext.Provider
      value={{
        user,
        isLoading,
        showDisconnectDevicesMessage,
        handleSetLocalIp,
        handleSignIn,
        handleSignOut,
        handleChangeUserEnvironment,
        handleCloseDisconnectDevicesMessage,
        handleActiveFreeTest,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
