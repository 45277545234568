import { RFValue } from "react-native-responsive-fontsize";
import styled from "styled-components/native";

interface RequiredStatus {
  isValid?: boolean;
}

export const Container = styled.View`
  background-color: ${({ theme }) => theme.colors.background[100]};
  width: 100%;

  padding: 8px 20px 16px;
`;

export const CategoryTitle = styled.Text`
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: ${RFValue(14)}px;
  color: ${({ theme }) => theme.colors.text[200]};

  margin-bottom: 4px;
`;

export const CategorySubTitle = styled.View`
  flex-direction: row;
  align-items: center;
`;

export const SubTitleText = styled.Text`
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: ${RFValue(12)}px;
  color: ${({ theme }) => theme.colors.text[400]};

  margin-right: auto;
`;

export const CategoryStatus = styled.View``;

export const CategoryRequiredStatus = styled.Text<RequiredStatus>`
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: ${RFValue(10)}px;
  color: ${({ theme, isValid }) =>
    isValid ? theme.colors.green : theme.colors.red};

  margin-right: 12px;
`;

export const GroupAmountStatus = styled.Text`
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: ${RFValue(12)}px;
  color: ${({ theme }) => theme.colors.text[400]};
`;
