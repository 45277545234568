import { Platform } from "react-native";
import { RFValue } from "react-native-responsive-fontsize";
import styled from "styled-components/native";

import { PriceHighlight } from "@components/PriceHighlight";
import { STATUS_BAR_HEIGHT, SCREEN_WIDTH } from "@utils/nativeLayoutHelper";

import {
  ProductTitle,
  PromotionContainer as PromotionContainerBase,
} from "../../../../components/CardProduct/styles";

export const HEADER_IMAGE_HEIGHT = Platform.OS === "web" ? 164 : 192;

export const Container = styled.View`
  /*88 = horizontal space (44 + 44) */
  width: ${SCREEN_WIDTH - 88}px;

  align-items: center;

  margin: ${STATUS_BAR_HEIGHT + 44}px auto 32px;
`;

export const ImageBlur = styled.ImageBackground.attrs((_) => ({
  imageStyle: {
    borderBottomLeftRadius: 64,
    borderBottomRightRadius: 64,
    opacity: 0.8,
  },
}))`
  width: ${SCREEN_WIDTH}px;
  height: ${RFValue(HEADER_IMAGE_HEIGHT)}px;

  border-bottom-left-radius: 64px;
  border-bottom-right-radius: 64px;

  position: absolute;
  top: -${STATUS_BAR_HEIGHT + 44}px;

  background-color: rgba(
    35,
    37,
    40,
    ${({ theme }) => (theme.title === "light" ? 0.2 : 0.4)}
  );
`;

export const Background = styled.View`
  flex: 1;

  border-radius: 24px;

  background-color: ${({ theme }) => theme.colors.background[200]};

  position: absolute;

  width: 100%;
  height: 75%;

  margin-top: 20%;
`;

export const ProductImage = styled.ImageBackground.attrs((_) => ({
  imageStyle: { borderRadius: 32 },
}))`
  width: 132px;
  height: 132px;
`;

export const Content = styled.View`
  flex-grow: 1;

  padding: 16px;

  justify-content: center;
  align-items: center;
`;

export const Title = styled(ProductTitle)`
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: ${RFValue(18)}px;
`;

export const PromotionText = styled.Text`
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: ${RFValue(12)}px;
  color: ${({ theme }) => theme.colors.text[500]};

  margin-top: 2px;
`;

export const PromotionContainer = styled(PromotionContainerBase)``;

export const OldPriceBox = styled.View`
  margin-left: 12px;
`;

export const Line = styled.View`
  width: 100%;
  height: 1px;

  background-color: ${({ theme }) => theme.colors.text[500]};

  position: absolute;
  right: 0;
  bottom: ${RFValue(8)}px;
`;

export const Price = styled(PriceHighlight).attrs(({ theme }) => ({
  unitColor: theme.colors.primary,
  highlightSize: 20,
  normalSize: 14,
}))``;

export const OldPrice = styled(PriceHighlight).attrs(({ theme }) => ({
  highlightSize: 16,
  normalSize: 12,
  isOldPrice: true,
}))``;
