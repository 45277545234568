import { FlatList, FlatListProps } from "react-native";
import styled from "styled-components/native";

import type { IProductOrder } from "@contexts/@types/useOrder.types";

export const Container = styled(
  FlatList as new (
    props: FlatListProps<IProductOrder>
  ) => FlatList<IProductOrder>
).attrs((_) => ({
  showsVerticalScrollIndicator: false,
  contentContainerStyle: {
    flexGrow: 1,
  },
}))`
  background-color: ${({ theme }) => theme.colors.background[200]};
  border-radius: 16px;

  padding: 12px 12px;

  margin: 0 20px;
`;
