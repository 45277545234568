import { useEffect, useState } from "react";

import type {
  useProductCategoryProps,
  useProductCategoryData,
  Category,
  CategoryFromBD,
  ICategoryItem,
} from "./@types/useProductCategory.types";
import type { GenericResponse } from "../@types/generic-response";
import type { IProductOrder } from "@contexts/@types/useOrder.types";

import { api } from "@services/api";
import { defaultCatch } from "@utils/defaultCatch";

export const useProductCategory = ({
  product,
  isEditingMode,
}: useProductCategoryProps): useProductCategoryData => {
  const [loadingCategories, setLoadingCategories] = useState(true);
  const [categories, setCategories] = useState<Category[]>([]);

  const selectedItems = categories
    .flatMap((category) => category.items)
    .filter((item) => item.amount >= 1);

  const selectedProducts = selectedItems.filter(
    (item) => item.type === "ADICIONAL" || item.type === "PRODUTO"
  );

  const selectedObservations = selectedItems.filter(
    (item) => item.type === "OBSERVACAO"
  );

  const allSelectedItemsTotal = selectedProducts.reduce(
    (acc, { amount, price }) => acc + amount * (price ?? 0),
    0
  );
  const categoryFormatter = (category: CategoryFromBD): Category => {
    const allItemsHaveQTDMaxOne = category.ITEMS.every(({ QTD_MAX }) => {
      return QTD_MAX === 1;
    });

    const isRadioInput =
      category.QUANTIDADE_MAXIMA === 1 && category.OBRIGATORIO === 1;
    const isCheckbox =
      allItemsHaveQTDMaxOne || category.QUANTIDADE_MAXIMA === 1;

    return {
      id: category.ID.toString(),
      title: category.TITULO,
      position: category.POSICAO,
      required: !!category.OBRIGATORIO,
      maxAmount: category.QUANTIDADE_MAXIMA,
      minAmount: category.QUANTIDADE_MINIMA,
      itemsAmount: 0,
      valid: !!category.OBRIGATORIO ? false : undefined,
      inputType: isRadioInput ? "radio" : isCheckbox ? "check" : "counter",
      items: category.ITEMS.map((item) => ({
        id: item.ID.toString(),
        categoryID: category.ID.toString(),
        description: item.DESCRICAO,
        amount: 0,
        maxAmount: item.QTD_MAX,
        position: item.ORDEM,
        price: Number(item.PRECO) === 0 ? undefined : Number(item.PRECO),
        type: category.TIPO_ITENS,
      })),
    };
  };

  const getCategories = async () => {
    try {
      const { data } = (
        await api.post<GenericResponse<CategoryFromBD[]>>(
          "venda/carregarCategorias",
          {
            ID_PRODUTO: Number(product.id),
          }
        )
      ).data;

      let newCategories: Category[] = data.map((c) => categoryFormatter(c));

      setCategories(
        newCategories.sort((catA, catB) => catA.position - catB.position)
      );
    } catch (error: any) {
      defaultCatch(
        error,
        "getCategories",
        "Erro ao carregar e formatar as categorias!"
      );
    } finally {
      setLoadingCategories(false);
    }
  };

  const updateCategory = (category: Category, items: ICategoryItem[]) => {
    const itemsAmount = items.reduce((acc, item) => acc + item.amount, 0);

    return {
      ...category,
      items,
      itemsAmount,
      valid: category.required ? itemsAmount >= category.minAmount : undefined,
    };
  };

  const handleChangeCategory = (categoryID: string, newItem: ICategoryItem) => {
    setCategories((oldState) =>
      oldState.map((category) => {
        const isRadioCategory = category.inputType === "radio";
        if (category.id === categoryID) {
          const items = category.items.map((item) => {
            if (newItem.id === item.id) {
              return newItem;
            }

            return {
              ...item,
              amount: isRadioCategory ? 0 : item.amount,
              isSelected: isRadioCategory ? false : item.isSelected,
            };
          });

          return updateCategory(category, items);
        }

        return category;
      })
    );
  };

  const getInvalidCategory = () => {
    // Como o card do produto tbm é uma seção é necessário adicionar +1
    return categories.findIndex((c) => c.valid !== undefined && !c.valid) + 1;
  };

  const handleSetCategories = (categories: Category[]): void => {
    setCategories(categories);
    setLoadingCategories(false);
  };

  useEffect(() => {
    product.typeCategory && getCategories();
  }, [product]);

  useEffect(() => {
    if (isEditingMode && !loadingCategories) {
      const productOrder = product as IProductOrder;
      const receivedItems = [
        ...(productOrder.additional ?? []),
        ...(productOrder.prefixObservations ?? []),
      ];

      setCategories((oldState) =>
        oldState.map((category) => {
          const receivedItemsFromThisCategory = receivedItems.filter(
            (item) => item.categoryID === category.id
          );

          if (receivedItemsFromThisCategory.length !== 0) {
            return updateCategory(
              category,
              category.items.map((item) => {
                const receivedItem = receivedItemsFromThisCategory.find(
                  (receivedItem) => receivedItem.id === item.id
                );

                return receivedItem ?? item;
              })
            );
          }

          return category;
        })
      );
    }
  }, [loadingCategories, isEditingMode]);

  return {
    categories,
    allSelectedItemsTotal,
    selectedProducts,
    selectedObservations,
    loadingCategories,
    getInvalidCategory,
    handleChangeCategory,
    handleSetCategories,
  };
};
