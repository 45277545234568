import { useEffect, useRef } from "react";
import {
  Keyboard,
  LayoutChangeEvent,
  ScrollView,
  Dimensions,
} from "react-native";
import {
  useSharedValue,
  useAnimatedStyle,
  withSpring,
} from "react-native-reanimated";
import { useAnimationState, MotiView } from "moti";
import { RFValue } from "react-native-responsive-fontsize";

import { useProductsPage } from "@contexts/useProductsPage";

import {
  GroupList,
  GroupItemContainer,
  GroupItemText,
  AnimatedBar,
} from "../styles";

interface LayoutItem {
  id: string;
  x: number;
  y: number;
  width: number;
}

const transitionSpring = {
  stiffness: 800,
  damping: 100,
  mass: 2,
};

const useGroupSectionAnimation = (firstShow: boolean) =>
  useAnimationState(
    {
      show: {
        maxHeight: RFValue(70),
        marginTop: 0,
        opacity: firstShow ? 1 : [0, { value: 1, delay: 50 }],
      },
      hidden: {
        maxHeight: 0,
        marginTop: 16,
        opacity: 0,
      },
    },
    {
      from: "show",
      to: "show",
    }
  );

const { width: SCREEN_WIDTH } = Dimensions.get("window");

export const Groups = () => {
  const { groups, selectedGroupsID, showGroupsSection, handleSelectGroup } =
    useProductsPage();
  const scrollRef = useRef<ScrollView>(null);
  const translationX = useSharedValue(0);
  const width = useSharedValue(0);
  const layoutItems = useRef<LayoutItem[]>([]);
  const firstShowState = useRef(true);

  const groupSectionAnimationState = useGroupSectionAnimation(
    firstShowState.current
  );

  const handleGroupItemLayout = (
    id: string,
    {
      nativeEvent: {
        layout: { width, x, y },
      },
    }: LayoutChangeEvent
  ) => {
    layoutItems.current.push({ id, width, x, y });

    if (id === selectedGroupsID) {
      animateBar(x, width);
    }
  };

  const animatedSheetStyle = useAnimatedStyle(() => {
    return {
      width: width.value,
      transform: [
        {
          translateX: translationX.value,
        },
      ],
    };
  });

  const animateBar = (itemPositionX: number, itemWidth: number) => {
    const halfElement = itemWidth / 2;
    const middleItem = itemPositionX + halfElement / 2;

    translationX.value = withSpring(middleItem, transitionSpring);
    width.value = withSpring(halfElement, transitionSpring);

    scrollRef.current?.scrollTo({
      x: itemPositionX + halfElement - SCREEN_WIDTH / 2,
      animated: true,
    });
  };

  useEffect(() => {
    const selectedGroupItem = layoutItems.current.find(
      ({ id }) => id === selectedGroupsID
    );

    if (!!selectedGroupItem) {
      animateBar(selectedGroupItem.x, selectedGroupItem.width);
    }
  }, [selectedGroupsID]);

  useEffect(() => {
    groupSectionAnimationState.transitionTo(
      showGroupsSection ? "show" : "hidden"
    );

    if (firstShowState.current) {
      firstShowState.current = false;
    }
  }, [showGroupsSection]);

  return (
    <MotiView state={groupSectionAnimationState} transition={transitionSpring}>
      <GroupList
        ref={scrollRef}
        horizontal
        onScrollBeginDrag={() => Keyboard.dismiss()}
      >
        <AnimatedBar style={animatedSheetStyle} />
        {groups.map(({ id, name }) => (
          <GroupItemContainer
            key={id}
            selected={id === selectedGroupsID}
            onPress={() => handleSelectGroup(id)}
            onLayout={(e) => handleGroupItemLayout(id, e)}
          >
            <GroupItemText selected={id === selectedGroupsID}>
              {name}
            </GroupItemText>
          </GroupItemContainer>
        ))}
      </GroupList>
    </MotiView>
  );
};
