import { BackHandler, Keyboard } from "react-native";
import { useFocusEffect, useNavigation } from "@react-navigation/native";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import { makeEventNotifier } from "../hooks/useEventListener";
import { api } from "../services/api";
import EventEmitter from "../services/EventEmitter";

import type { GenericResponse } from "../@types/generic-response";
import type {
  OrderContextData,
  OrderContextProviderProps,
  ProductOrderBDFormat,
  OrderBDFormat,
  IProductOrder,
} from "./@types/useOrder.types";

import { useUser } from "./useUser";
import { defaultCatch } from "@utils/defaultCatch";

const notifier = makeEventNotifier<{
  newProduct: IProductOrder;
  index?: number;
}>("addOrEditProduct");

export const useProductAddListener = (
  listener: typeof notifier.notify,
  deps: ReadonlyArray<any>
) => {
  notifier.useEventListener(listener, deps);
};

export const OrderContext = createContext({} as OrderContextData);

export const OrderContextProvider = ({
  children,
  module,
}: OrderContextProviderProps) => {
  const { user } = useUser();
  const { navigate, goBack } = useNavigation();

  const [footerHeight, setFooterHeight] = useState(0);
  const [isOrderPageOpen, setIsOrderPageOpen] = useState(false);
  const [showOrderBottomBar, setShowOrderBottomBar] = useState(false);
  const [showAskTable, setShowAskTable] = useState(false);
  const [showClearListMessage, setShowClearListMessage] = useState(false);
  const [showRemoveProductMessage, setShowRemoveProductMessage] =
    useState(false);
  const [sendingOrder, setSendingOrder] = useState(false);
  const [orderProducts, setOrderProducts] = useState<IProductOrder[]>([]);
  const total = orderProducts.reduce(
    (acc, { totalPrice }) => acc + totalPrice,
    0
  );
  const itemIndex = useRef<null | number>(null);

  useProductAddListener(({ newProduct, index }) => {
    const isEditing = index !== undefined;
    if (isEditing) {
      setOrderProducts((oldProducts) =>
        oldProducts.map((p, itemIndex) =>
          itemIndex === index ? newProduct : p
        )
      );
    } else {
      setOrderProducts((oldProducts) => [...oldProducts, newProduct]);
    }
  }, []);

  useEffect(() => {
    const orderProductsEmpty = orderProducts.length === 0;

    if (orderProductsEmpty) {
      setIsOrderPageOpen(false);
    }

    !isOrderPageOpen && setShowOrderBottomBar(!orderProductsEmpty);
  }, [orderProducts, isOrderPageOpen]);

  useFocusEffect(
    useCallback(() => {
      const onBackPress = () => {
        if (isOrderPageOpen) {
          setIsOrderPageOpen(false);
          return true;
        } else {
          return false;
        }
      };

      const subscription = BackHandler.addEventListener(
        "hardwareBackPress",
        onBackPress
      );

      return () => subscription.remove();
    }, [isOrderPageOpen])
  );

  const toggleShowOrderPage = () => {
    Keyboard.dismiss();
    setIsOrderPageOpen(!isOrderPageOpen);
  };

  const formatProduct = (
    {
      id,
      price,
      promotionalPrice,
      amount,
      additional,
      prefixObservations,
      observation,
    }: IProductOrder,
    primaryItemIndex: number,
    comboID?: number
  ): ProductOrderBDFormat => {
    const observationsFormatted = prefixObservations
      ? prefixObservations.map(({ description, amount }) => ({
          OBSERVACAO: description,
          QUANTIDADE: amount,
        }))
      : [];

    const additionalFormatted =
      additional
        ?.filter(({ type }) => type === "ADICIONAL")
        .map(({ id, amount, price }) => {
          return {
            ID_PRODUTO: Number(id),
            VALOR_UNITARIO: price ?? 0,
            QUANTIDADE: amount,
          };
        }) ?? [];

    if (observation) {
      observationsFormatted.push({
        OBSERVACAO: observation,
        QUANTIDADE: 1,
      });
    }

    return {
      ID_ECF_PRODUTO: Number(id),
      CONTROLE_FRACIONAMENTO: 0,
      ID_COMBO: comboID,
      CONTROLE_COMBO: primaryItemIndex,
      VALOR_UNITARIO: promotionalPrice ?? price,
      QUANTIDADE: amount,
      ADICIONAIS: additionalFormatted,
      OBSERVACOES: observationsFormatted,
    };
  };

  const handleConfirmOrder = async (tableNumber?: string) => {
    const { id: moduleID, type, number } = module;
    const moduleIsACommand = type === "C";
    if (!showAskTable && user?.askTableInCommands && moduleIsACommand) {
      setShowAskTable(true);
      return;
    }

    closeMessage();

    try {
      setSendingOrder(true);

      const orderComboProducts = orderProducts.filter(
        (product) => product.comboProducts && product.comboProducts?.length > 0
      );
      const orderNormalProducts = orderProducts.filter(
        (product) =>
          !product.comboProducts || product.comboProducts?.length === 0
      );

      let itemsOfCategoriesTypeProduct: ProductOrderBDFormat[] = [];
      const itemsOfCombos: ProductOrderBDFormat[] = orderComboProducts
        .map((primaryItem, primaryItemIndex) =>
          primaryItem.comboProducts!.map(({ product }) => {
            const formattedProduct = formatProduct(
              product,
              primaryItemIndex,
              Number(primaryItem.id)
            );
            return {
              ...formattedProduct,
              OBSERVACOES: [
                ...formattedProduct.OBSERVACOES!,
                {
                  OBSERVACAO: `Item do produto ${primaryItem.name}`,
                  QUANTIDADE: 1,
                },
              ],
            };
          })
        )
        .flat();

      const itemsFormatted: ProductOrderBDFormat[] = orderNormalProducts.map(
        (primaryItem, primaryItemIndex) => {
          primaryItem.additional &&
            itemsOfCategoriesTypeProduct.push(
              ...primaryItem.additional
                .filter(({ type }) => type === "PRODUTO")
                .map(({ amount, price, id }) => ({
                  ID_ECF_PRODUTO: Number(id),
                  ID_COMBO: Number(primaryItem.id),
                  CONTROLE_COMBO: primaryItemIndex,
                  CONTROLE_FRACIONAMENTO: 0,
                  VALOR_UNITARIO: price ?? 0,
                  QUANTIDADE: amount,
                  OBSERVACOES: [
                    {
                      OBSERVACAO: `Item do produto ${primaryItem.name}`,
                      QUANTIDADE: 1,
                    },
                  ],
                }))
            );

          return formatProduct(primaryItem, primaryItemIndex);
        }
      );

      const orderFormatted: OrderBDFormat = {
        ID_FUNCIONARIO: user!.id,
        ID_CAIXA: user!.idCaixa.toString(),
        ID_COMANDA: Number(moduleID),
        NUMERO_COMANDA: number,
        MODO_LANCAMENTO: moduleIsACommand ? "COMANDA" : "MESA",
        MESA_ENTREGAR: !!tableNumber ? tableNumber : "",
        ITENS: [
          ...itemsFormatted,
          ...itemsOfCategoriesTypeProduct,
          ...itemsOfCombos,
        ],
        TOTAL: total.toString(),
      };

      const data = (
        await api.post<GenericResponse<any>>("venda/novaVenda", orderFormatted)
      ).data;

      if (data.status === "success") {
        EventEmitter.notify("onUpdateModulesOrder", {
          closeBottomSheet: true,
        });
        goBack();
      } else {
        throw new Error(data.data);
      }
    } catch (error: any) {
      defaultCatch(
        error,
        "handleConfirmOrder",
        "Erro ao confirmar um novo pedido!"
      );
    } finally {
      setSendingOrder(false);
    }
  };

  const handleRequestClearOrder = () => {
    setShowClearListMessage(true);
  };

  const handleClearOrder = () => {
    orderProducts.length > 0 && setOrderProducts([]);

    setShowClearListMessage(false);
  };

  const handleRemoveItem = () => {
    setOrderProducts((oldProducts) =>
      oldProducts.filter((_, index) => index !== itemIndex.current)
    );

    itemIndex.current = null;
    setShowRemoveProductMessage(false);
  };

  const handleRequestRemoveItem = (newItemIndex: number) => {
    itemIndex.current = newItemIndex;
    setShowRemoveProductMessage(true);
  };

  const handleEditProduct = (itemIndex: number) => {
    const product = orderProducts[itemIndex];
    navigate("Product", { product, index: itemIndex });
  };

  const handleSetFooterHeight = (newHeight: number) => {
    setFooterHeight(newHeight);
  };

  const closeMessage = () => {
    itemIndex.current = null;
    showClearListMessage && setShowClearListMessage(false);
    showRemoveProductMessage && setShowRemoveProductMessage(false);
    showAskTable && setShowAskTable(false);
  };

  return (
    <OrderContext.Provider
      value={{
        isOrderPageOpen,
        total,
        orderProducts,
        module,
        footerHeight,
        itemIndex: itemIndex.current,
        showOrderBottomBar,
        sendingOrder,
        showAskTable,
        showClearListMessage,
        showRemoveProductMessage,
        toggleShowOrderPage,
        handleConfirmOrder,
        handleClearOrder,
        handleRemoveItem,
        handleEditProduct,
        handleSetFooterHeight,
        handleRequestRemoveItem,
        closeMessage,
        handleRequestClearOrder,
      }}
    >
      {children}
    </OrderContext.Provider>
  );
};

export const useOrder = () => useContext(OrderContext);
