import { useState } from "react";

import { useProductsPage } from "@contexts/useProductsPage";

import { BottomSheetModal } from "@components/BottomSheetModal";

import {
  ModalContainer,
  OptionText,
  Option,
  TitlePizza,
  TypeContainer,
  TypeOptions,
  TypeTitle,
  FooterButtons,
  ReturnButton,
  ContinueButton,
} from "./styles";

export const PizzaBottomSheet = () => {
  const { handleClosePizzaBottomSheet, showPizzaBottomSheet } =
    useProductsPage();

  const [sizeOption, setSizeOption] = useState("Broto");
  const [amountFlavors, setAmountFlavors] = useState(1);

  const sizeOptions = ["Broto", "Média", "Família"];
  const amountFlavorsOptions = [1, 2, 3, 4];

  return (
    <BottomSheetModal
      isOpen={showPizzaBottomSheet}
      onDismiss={handleClosePizzaBottomSheet}
      percentagePosition={50}
    >
      <ModalContainer>
        <TitlePizza>Pizza de Peperoni</TitlePizza>
        <TypeContainer>
          <TypeTitle>Escolha o tamanho</TypeTitle>
          <TypeOptions>
            {sizeOptions.map((option) => (
              <Option key={option} isSelected={sizeOption === option}>
                <OptionText isSelected={sizeOption === option}>
                  {option}
                </OptionText>
              </Option>
            ))}
          </TypeOptions>
        </TypeContainer>
        <TypeContainer>
          <TypeTitle>Quantos sabores?</TypeTitle>
          <TypeOptions>
            {amountFlavorsOptions.map((option) => (
              <Option key={option} isSelected={amountFlavors === option}>
                <OptionText isSelected={amountFlavors === option}>
                  {option}
                </OptionText>
              </Option>
            ))}
          </TypeOptions>
        </TypeContainer>
        <FooterButtons>
          <ReturnButton text='Voltar' />
          <ContinueButton text='Continuar' />
        </FooterButtons>
      </ModalContainer>
    </BottomSheetModal>
  );
};
