export const fadePresence = {
  from: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
};

export const transitionSpring = {
  stiffness: 1000,
  damping: 100,
  mass: 4,
};
