import { RFValue } from "react-native-responsive-fontsize";
import styled from "styled-components/native";

import { RefreshIcon } from "@assets/icons";

export const Container = styled.View`
  flex: 1;

  background-color: ${({ theme }) => theme.colors.background[100]};

  overflow: hidden;
`;

export const SearchAreaContainer = styled.View`
  width: 100%;
  padding: 0 20px;

  margin: 32px 0;

  flex-direction: row;
  align-items: center;
`;

export const RefreshButton = styled.TouchableOpacity`
  border-radius: 12px;
  border: solid 1px ${({ theme }) => theme.colors.background[300]};

  padding: 12px;
  margin-left: 16px;

  background-color: ${({ theme }) => theme.colors.background[200]};
`;

export const CategoryPageTitle = styled.Text`
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: ${RFValue(16)}px;
  color: ${({ theme }) => theme.colors.text[100]};

  margin-bottom: 16px;
  padding: 0 20px;
`;

export const CategoryPageTitleHighlight = styled.Text`
  color: ${({ theme }) => theme.colors.primary};
`;

export const CircularLoader = styled.ActivityIndicator.attrs(({ theme }) => ({
  color: theme.colors.primary,
  size: RFValue(32),
}))`
  margin-top: 52px;
`;

export const RefreshButtonIcon = styled(RefreshIcon).attrs(({ theme }) => ({
  width: RFValue(20),
  height: RFValue(20),
  color: theme.colors.text[400],
  strokeWidth: 1.4,
}))``;
