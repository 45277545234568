import "react-native-gesture-handler";
import { StatusBar } from "expo-status-bar";
import { NavigationContainer } from "@react-navigation/native";

import { useFonts } from "./src/hooks/useFont";
import {
  CustomThemeContextProvider,
  UserContextProvider,
} from "@contexts/index";

import { AppRoutes } from "./src/routes/app.routes";
import { Toast, StatusBarPadding } from "@components/index";

// FIXME need reanimated update, see https://github.com/software-mansion/react-native-reanimated/issues/3355
// @ts-ignore
if (process.browser) {
  // @ts-ignore
  window._frameTimestamp = null;
}

export default function App() {
  const { fontsLoaded } = useFonts();

  if (!fontsLoaded) {
    return null;
  }

  return (
    <CustomThemeContextProvider>
      <StatusBarPadding>
        <StatusBar backgroundColor='transparent' translucent />
        <NavigationContainer>
          <UserContextProvider>
            <AppRoutes />
          </UserContextProvider>
        </NavigationContainer>
        <Toast />
      </StatusBarPadding>
    </CustomThemeContextProvider>
  );
}
