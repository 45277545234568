import { Dimensions, Platform } from "react-native";
import { RFValue } from "react-native-responsive-fontsize";
import styled, { css } from "styled-components/native";

import { Button, PriceHighlight } from "@components/index";

import dark from "../../../../styles/themes/dark";
import { convertHexToRgba } from "@utils/string.utils";
import { NAV_BAR_HEIGHT } from "@utils/nativeLayoutHelper";

const { width: SCREEN_WIDTH } = Dimensions.get("window");

interface ButtonCustomProps {
  disabled: boolean;
  validProduct: boolean;
  alignCenter?: boolean;
}

export const Container = styled.View`
  position: absolute;
  bottom: 0;
  left: 0;

  flex-direction: row;
  align-items: center;

  border-top-width: 1px;
  border-top-color: ${({ theme }) => theme.colors.background[300]};

  background-color: ${({ theme }) =>
    convertHexToRgba(theme.colors.background[100], 0.96)};

  padding: 16px 20px ${24 + (Platform.OS === "ios" ? NAV_BAR_HEIGHT : 0)}px;

  width: ${SCREEN_WIDTH}px;
`;

export const LaunchProductButton = styled(Button).attrs({
  variant: "solid",
})<ButtonCustomProps>`
  flex: 1;
  width: 100%;

  padding: 10px 20px;

  align-items: center;
  justify-content: ${({ alignCenter }) =>
    alignCenter ? "center" : "space-between"};

  ${({ disabled, theme, validProduct }) =>
    (disabled || !validProduct) &&
    css`
      background-color: ${theme.colors.background[300]};
    `}
`;

export const LaunchText = styled.Text<ButtonCustomProps>`
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: ${RFValue(16)}px;
  color: ${({ theme, disabled, validProduct }) =>
    disabled || !validProduct ? theme.colors.text[500] : dark.colors.text[100]};

  margin-right: 8px;
`;

export const TotalPriceText = styled(PriceHighlight).attrs<ButtonCustomProps>(
  ({ theme, disabled, validProduct }) => ({
    highlightSize: RFValue(16),
    normalSize: RFValue(12),
    color:
      disabled || !validProduct
        ? theme.colors.text[500]
        : dark.colors.text[100],
    // bold: true,
  })
)<ButtonCustomProps>``;
