import { Platform } from "react-native";
import { useTheme } from "styled-components/native";

import { useProductPage } from "@contexts/useProductPage";
import { removeEmojis } from "@utils/string.utils";

import { Container, TextAreaBox, Title, TextAreaInput } from "./styles";

export const FieldObservation = () => {
  const { colors, commonShadowConfig } = useTheme();
  const { handleObservationChange, observation } = useProductPage();

  return (
    <Container>
      <Title>Observações</Title>

      <TextAreaBox
        style={{
          shadowColor: colors.shadows,
          ...commonShadowConfig,
        }}
      >
        <TextAreaInput
          placeholder='Digite sua observação...'
          value={observation}
          style={{ textAlignVertical: "top" }}
          onChangeText={(value) => handleObservationChange(removeEmojis(value))}
          blurOnSubmit
          keyboardType={
            Platform.OS === "ios" || "web"
              ? "ascii-capable"
              : "visible-password"
          }
        />
      </TextAreaBox>
    </Container>
  );
};
