import { Container } from "./styles";

export const SplashScreen = () => {
  return (
    <Container
      resizeMode='contain'
      source={require("@assets/app/splash.png")}
    />
  );
};
