import type { UseAnimationState } from "moti";
import type { TransitionProps } from "../../animations";
import type { ICategoryItem } from "@hooks/@types/useProductCategory.types";

import { AdditionalContainer, AdditionalName, AdditionalPrice } from "./styles";

interface AdditionalList {
  additional?: ICategoryItem[];
  dropItemAnimation: UseAnimationState<any>;
  transitionWithDelay: TransitionProps;
}

export const AdditionalList = ({
  additional,
  dropItemAnimation,
  transitionWithDelay,
}: AdditionalList) => {
  if (!additional) {
    return null;
  }

  return (
    <>
      {additional!.map(({ description, price, amount }) => (
        <AdditionalContainer
          key={description}
          state={dropItemAnimation}
          transition={transitionWithDelay}
        >
          <AdditionalName
            key={description}
          >{`+ ${amount} ${description}`}</AdditionalName>
          {amount !== 0 && price !== undefined && price !== 0 && (
            <AdditionalPrice price={price * amount} />
          )}
        </AdditionalContainer>
      ))}
    </>
  );
};
