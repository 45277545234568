import { useEffect } from "react";
import * as SplashScreen from "expo-splash-screen";
import {
  useFonts as expoUseFonts,
  OpenSans_400Regular,
  OpenSans_500Medium,
  OpenSans_600SemiBold,
  OpenSans_700Bold,
  OpenSans_300Light,
} from "@expo-google-fonts/open-sans";

interface UseFontsData {
  fontsLoaded: boolean;
}

export const useFonts = (): UseFontsData => {
  const [fontsLoaded] = expoUseFonts({
    OpenSans_300Light,
    OpenSans_400Regular,
    OpenSans_500Medium,
    OpenSans_600SemiBold,
    OpenSans_700Bold,
  });

  useEffect(() => {
    async function prepare() {
      try {
        if (fontsLoaded) {
          await SplashScreen.hideAsync();
        } else {
          await SplashScreen.preventAutoHideAsync();
        }
      } catch (e) {
        console.warn(e);
      }
    }

    prepare();
  }, [fontsLoaded]);

  return {
    fontsLoaded,
  };
};
