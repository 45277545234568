import { FlatList, FlatListProps } from "react-native";
import { RFValue } from "react-native-responsive-fontsize";
import styled from "styled-components/native";

import { ModuleOrder } from "@contexts/@types/useModuleBottomSheet.types";

export const BottomSheetContent = styled.View`
  position: relative;

  flex: 1;
  height: 100%;
`;

export const OrderContainer = styled.View`
  flex-direction: column;

  margin-bottom: 28px;
`;

export const OrderTitle = styled.Text`
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: ${RFValue(16)}px;
  color: ${({ theme }) => theme.colors.text[100]};

  margin-bottom: 12px;

  padding: 0 20px;
`;

export const OrderNumber = styled.Text`
  font-family: ${({ theme }) => theme.fonts.medium};
  color: ${({ theme }) => theme.colors.primary};
`;

export const OrderList = styled(
  FlatList as new (props: FlatListProps<ModuleOrder>) => FlatList<ModuleOrder>
).attrs({
  showsHorizontalScrollIndicator: false,
})``;

export const CircularLoader = styled.ActivityIndicator.attrs(({ theme }) => ({
  color: theme.colors.primary,
  size: RFValue(32),
}))`
  margin-top: ${RFValue(64)}px;
`;
