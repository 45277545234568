import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Animated, BackHandler, TextInput } from "react-native";
import { io } from "socket.io-client";
import { useFocusEffect } from "@react-navigation/native";
import { FlashList } from "@shopify/flash-list";

import { api } from "@services/api";
import { moduleQueries } from "@services/queries";

import { useUser } from "./useUser";
import { makeEventNotifier } from "@hooks/useEventListener";
import { toastNotification } from "@hooks/useToast";

import { formatQueryModuleType } from "@utils/string.utils";

import type { GenericResponse } from "../@types/generic-response";
import type {
  IModule,
  ModulesFromBD,
  ModulesPageContextData,
  ModulesPageProviderProps,
  UpdatedModuleFromBD,
  TypeModule,
} from "./@types/useModulesPage.types";

import { defaultCatch } from "@utils/defaultCatch";

const notifier = makeEventNotifier<{
  closeBottomSheet?: number;
}>("onUpdateModulesOrder");

export const useUpdateModulesOrderListener = (
  listener: typeof notifier.notify,
  deps: ReadonlyArray<any>
) => {
  notifier.useEventListener(listener, deps);
};

export const ModulesPageContext = createContext({} as ModulesPageContextData);

export const ModulesPageContextProvider = ({
  children,
}: ModulesPageProviderProps) => {
  const { user } = useUser();

  const [allModules, setAllModules] = useState<IModule[]>([]);
  const [isModulesBottomSheetOpen, setIsModulesBottomSheetOpen] =
    useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [currentModuleType, setCurrentModuleType] = useState<TypeModule>("C");
  const [searchValue, setSearchValue] = useState("");
  const [modulesLoading, setModulesLoading] = useState(true);
  const [moduleIDUpdatingFavoriteState, setModuleIDUpdatingFavoriteState] =
    useState("");

  const flashListRef = useRef<FlashList<IModule>>(null);
  const searchInputRef = useRef<TextInput>(null);
  const scrollY = useRef(new Animated.Value(0)).current;

  const favoriteModules = allModules.filter(({ type, isFavorite }) => {
    const isEqualCurrentModule = type === currentModuleType;

    return isEqualCurrentModule && isFavorite;
  });

  const filteredModuleList = allModules.filter(({ type, surname, number }) => {
    const isEqualCurrentModule = type === currentModuleType;

    if (searchValue.length > 0) {
      return (
        isEqualCurrentModule &&
        (surname?.toLowerCase().includes(searchValue.toLowerCase()) ||
          number.toString() === searchValue)
      );
    }

    return isEqualCurrentModule;
  });

  const selectedModule =
    filteredModuleList.find(({ id }) => id === selectedId) ?? null;
  const moduleTypeExtended = currentModuleType === "C" ? "Comanda" : "Mesa";

  const getAllModules = async () => {
    const modulesNotLoaded = allModules.length === 0;

    try {
      modulesNotLoaded && setModulesLoading(true);

      const { data: modules } = (
        await api.post<GenericResponse<ModulesFromBD[]>>("venda/query", {
          SQL: moduleQueries.getAll(
            user!.id,
            formatQueryModuleType(user!.usedModules)
          ),
        })
      ).data;

      const allModulesFormatted: IModule[] = modules.map((module) => {
        return {
          id: module.ID.toString(),
          type: module.TIPO,
          number: module.NUMERO_COMANDA,
          inUse: module.EM_USO === "S",
          surname:
            module.APELIDO && module.APELIDO.trim().length > 0
              ? module.APELIDO
              : null,
          grouped: module.AGRUPADA === "S",
          status: module.STATUS,
          idSaleHeader: module.ID_VENDA_CABECALHO,
          usageTime:
            module.TEMPO_ULT_PED.length > 0 ? module.TEMPO_ULT_PED : null,
          isFavorite: module.MESA_FAVORITADA === "S",
          idMainModule: module.ID_COMANDA_PRINCIPAL,
        };
      });

      user!.usedModules.length === 1 &&
        setCurrentModuleType(user!.usedModules as TypeModule);

      setAllModules(allModulesFormatted);
    } catch (error: any) {
      defaultCatch(
        error,
        "getAllModules",
        "Erro ao carregar a lista de módulos!"
      );
    } finally {
      setModulesLoading(false);
    }
  };

  useEffect(() => {
    getAllModules();

    const updateModulesInterval = setInterval(() => {
      getAllModules();
    }, 300000); // 5 min

    return () => {
      clearInterval(updateModulesInterval);
    };
  }, []);

  useEffect(() => {
    const localIP = api.defaults.baseURL!.replace("/v1/", "");

    const socket = io(localIP);
    socket.on("refreshModules", (updatedModules: string) => {
      const updateModulesFormatted: UpdatedModuleFromBD[] =
        JSON.parse(updatedModules);

      setAllModules((oldState) =>
        oldState.map((module) => {
          const moduleUpdated = updateModulesFormatted.find(
            ({ ID, NUMERO_COMANDA }) =>
              Number(module.id) === ID && module.number === NUMERO_COMANDA
          );

          if (moduleUpdated) {
            return {
              ...module,
              status:
                moduleUpdated.FECHADA === "S"
                  ? "F"
                  : moduleUpdated.STATUSCOMANDA,
              inUse: moduleUpdated.EM_USO === "S",
              surname: !!moduleUpdated.APELIDO
                ? moduleUpdated.APELIDO.trim()
                : null,
            };
          }

          return module;
        })
      );
    });
  }, []);

  useFocusEffect(
    useCallback(() => {
      const onBackPress = () => {
        if (isModulesBottomSheetOpen) {
          setIsModulesBottomSheetOpen(false);
          return true;
        } else {
          return false;
        }
      };

      const subscription = BackHandler.addEventListener(
        "hardwareBackPress",
        onBackPress
      );

      return () => subscription.remove();
    }, [isModulesBottomSheetOpen])
  );

  const handleOpenModuleBottomSheet = (id: string) => {
    setSelectedId(id);
    setIsModulesBottomSheetOpen(true);
  };

  const handleCloseModulesBottomSheet = () => {
    setIsModulesBottomSheetOpen(false);
    (user?.name === "Teste Grátis" || user?.name === "Apple") &&
      handleRefresh();
  };

  useUpdateModulesOrderListener(({ closeBottomSheet }) => {
    if (closeBottomSheet) {
      handleCloseModulesBottomSheet();
    }
  }, []);

  const handleRefresh = () => {
    getAllModules();
  };

  const handleSearchModule = (searchValue: string) => {
    setSearchValue(searchValue);
  };

  const handleToggleModuleType = (type: TypeModule) => {
    setCurrentModuleType(type);
  };

  const scrollPageToTop = () => {
    flashListRef.current?.scrollToOffset({ offset: 0, animated: true });
    searchInputRef.current?.focus();
  };

  const toggleFavoriteState = async (
    numberModule: number,
    isFavorite: boolean
  ) => {
    setModuleIDUpdatingFavoriteState(
      allModules.find(
        ({ number, type }) =>
          number === numberModule && type === currentModuleType
      )!.id
    );

    const query = isFavorite
      ? moduleQueries.deleteFavorite(user!.id, numberModule, currentModuleType)
      : moduleQueries.addToFavorite(user!.id, numberModule, currentModuleType);

    try {
      const { status, data } = (
        await api.post<GenericResponse<any>>("venda/execsql", {
          SQL: query,
        })
      ).data;

      if (status === "success") {
        setAllModules((oldState) =>
          oldState
            .map((module) => {
              const isTargetModule =
                module.number === numberModule &&
                module.type === currentModuleType;
              if (isTargetModule)
                return {
                  ...module,
                  isFavorite: !isFavorite,
                };

              return module;
            })
            .sort((modA, modB) => modA.number - modB.number)
        );
      } else {
        throw new Error(data);
      }
    } catch (error) {
      toastNotification({
        message: "Erro ao adicionar aos favoritos!",
        type: "error",
      });
      console.log("toggleFavoriteState: ", error);
    } finally {
      setModuleIDUpdatingFavoriteState("");
    }
  };

  return (
    <ModulesPageContext.Provider
      value={{
        isModulesBottomSheetOpen,
        selectedModule,
        filteredModuleList,
        currentModuleType,
        modulesLoading,
        searchValue,
        scrollY,
        flashListRef,
        searchInputRef,
        favoriteModules,
        moduleTypeExtended,
        moduleIDUpdatingFavoriteState,
        handleOpenModuleBottomSheet,
        handleCloseModulesBottomSheet,
        handleSearchModule,
        handleRefresh,
        handleToggleModuleType,
        scrollPageToTop,
        toggleFavoriteState,
      }}
    >
      {children}
    </ModulesPageContext.Provider>
  );
};

export const useModulesPage = () => useContext(ModulesPageContext);
