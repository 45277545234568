import { useMemo } from "react";
import { useTheme } from "styled-components/native";

import { useModulesPage } from "@contexts/useModulesPage";
import { simplifyMinutes } from "@utils/string.utils";

import type { IModule } from "@contexts/@types/useModulesPage.types";

import {
  CardModuleContainer,
  IconBox,
  NotesIconStyled,
  CardsGroupIconStyled,
  HeaderCard,
  TableIconStyled,
  NumberBox,
  NumberText,
  DarkenNumberText,
  CardBody,
  CardTitle,
  CardSubText,
  CardFooter,
  UsageTime,
  ClockIconStyled,
  UsageTimeText,
  StarIconStyled,
  FavoritePressableArea,
  CircularLoader,
} from "./styles";

interface CardModuleProps extends IModule {
  index: number;
  numOfColumns?: number;
  isExpanded?: boolean;
}

export const CardModule = ({
  index,
  numOfColumns = 1,
  isFavorite,
  number,
  surname,
  usageTime,
  status,
  grouped,
  type,
  id,
  isExpanded,
  idMainModule,
}: CardModuleProps) => {
  const {
    handleOpenModuleBottomSheet,
    toggleFavoriteState,
    moduleIDUpdatingFavoriteState,
    filteredModuleList,
  } = useModulesPage();
  const { colors, commonShadowConfig } = useTheme();

  const iconByModuleType = {
    C: <NotesIconStyled status={status} />,
    M: <TableIconStyled status={status} />,
  };

  const statusText = {
    L: "Livre",
    O: "Ocupada",
    F: "Fechada",
  };

  const group = useMemo(
    () =>
      grouped &&
      filteredModuleList
        .filter((mod) => mod.idMainModule === idMainModule)
        .map((mod) => mod.number)
        .toString(),
    [grouped]
  );

  const moreThanOneColumn = numOfColumns > 1;

  return (
    <CardModuleContainer
      isFirstOfRow={index % numOfColumns === 0 && moreThanOneColumn}
      isLastOfRow={
        (index - (numOfColumns - 1)) % numOfColumns === 0 && moreThanOneColumn
      }
      isExpanded={isExpanded}
      onPress={() => handleOpenModuleBottomSheet(id)}
      style={{
        shadowColor: colors.shadows,
        ...commonShadowConfig,
      }}
    >
      <HeaderCard>
        <IconBox status={status} grouped={grouped}>
          {grouped ? <CardsGroupIconStyled /> : iconByModuleType[type]}
        </IconBox>
        <NumberBox>
          <NumberText>
            <DarkenNumberText>Nº </DarkenNumberText>
            {number}
          </NumberText>
        </NumberBox>
      </HeaderCard>
      <CardBody>
        <CardTitle>
          {surname ? surname : grouped ? "Agrupada" : statusText[status]}
          {grouped && ` (${group})`}
        </CardTitle>
        <CardSubText>Sem vinculo</CardSubText>
      </CardBody>
      <CardFooter>
        <UsageTime>
          <ClockIconStyled status={status} grouped={grouped} />
          <UsageTimeText status={status} grouped={grouped}>
            {usageTime ? simplifyMinutes(usageTime) : "0 min"}
          </UsageTimeText>
        </UsageTime>
        {moduleIDUpdatingFavoriteState === id ? (
          <CircularLoader status={status} grouped={grouped} />
        ) : (
          <FavoritePressableArea
            onPress={() => toggleFavoriteState(number, isFavorite)}
          >
            <StarIconStyled
              isFavorite={isFavorite}
              grouped={grouped}
              status={status}
              onLayout={(e) => e}
            />
          </FavoritePressableArea>
        )}
      </CardFooter>
    </CardModuleContainer>
  );
};
