import {
  ProductsPageContext,
  ProductsPageContextProvider,
  OrderContextProvider,
} from "@contexts/index";
import type { IModule } from "@contexts/@types/useModulesPage.types";

import { Order } from "../Order";

import { Groups } from "./components/Groups";
import { Header } from "./components/Header";
import { PizzaBottomSheet } from "./components/PizzaBottomSheet";
import { ProductsList } from "./components/ProductsList";

import { CircularLoader, Container } from "./styles";
import { CancelOrderMessage } from "./components/CancelOrderMessage";

interface ProductsPageProps {
  route: {
    params: {
      module: IModule;
    };
  };
}

export const Products = ({
  route: {
    params: { module },
  },
}: ProductsPageProps) => {
  return (
    <OrderContextProvider module={module}>
      <ProductsPageContextProvider>
        <Container>
          <Header />
          <ProductsPageContext.Consumer>
            {({ isProductsAndGroupsLoading }) =>
              isProductsAndGroupsLoading ? (
                <CircularLoader />
              ) : (
                <>
                  <Groups />
                  <ProductsList />
                </>
              )
            }
          </ProductsPageContext.Consumer>
          <Order />
          <PizzaBottomSheet />
          <CancelOrderMessage />
        </Container>
      </ProductsPageContextProvider>
    </OrderContextProvider>
  );
};
