import { ProductTile } from "@components/ProductTile";
import { useProductPage } from "@contexts/useProductPage";
import { useTheme } from "styled-components/native";

import { ChooseText, Container, ChooseButton } from "./styles";

interface ComboLevelItem {
  levelID: string;
}

export const ComboLevelItem = ({ levelID }: ComboLevelItem) => {
  const { colors, commonShadowConfig } = useTheme();
  const {
    comboServices: {
      selectedProducts,
      handlePickProductForLevel,
      handleRemoveProductForLevel,
      handleRequestEditProduct,
    },
  } = useProductPage();

  const levelItem = selectedProducts.find((p) => p.levelID === levelID);

  if (!levelItem) {
    return (
      <ChooseButton onPress={() => handlePickProductForLevel(levelID)}>
        <ChooseText>Escolher</ChooseText>
      </ChooseButton>
    );
  }

  return (
    <Container
      style={{
        shadowColor: colors.shadows,
        ...commonShadowConfig,
      }}
    >
      <ProductTile
        product={levelItem!.product}
        showExtraDetails
        showDetailsLine={false}
        onEdit={() => handleRequestEditProduct(levelID, levelItem!.product)}
        onRemove={() => handleRemoveProductForLevel(levelID)}
      />
    </Container>
  );
};
