import { useNavigation } from "@react-navigation/native";
import { useModuleBottomSheet, useModulesPage } from "@contexts/index";

import {
  Container,
  TotalOrder,
  TotalOrderPrice,
  TotalOrderTitle,
  DashedLineStyled,
  Actions,
  CloseModuleButton,
  AddOrderButton,
} from "./styles";

export const Footer = () => {
  const { navigate } = useNavigation();
  const { selectedModule } = useModulesPage();
  const {
    moduleOrders,
    totalPriceOrder,
    handleCloseModule,
    onLayoutComponent,
  } = useModuleBottomSheet();

  const moduleAlreadyClose = selectedModule?.status === "F";
  const ordersIsEmpty = moduleOrders.length === 0;

  return (
    <Container onLayout={(e) => onLayoutComponent(e, "footer")}>
      <TotalOrder>
        <TotalOrderTitle>Total</TotalOrderTitle>
        <TotalOrderPrice price={totalPriceOrder} />
      </TotalOrder>
      <DashedLineStyled />
      <Actions>
        <CloseModuleButton
          disabled={moduleAlreadyClose || ordersIsEmpty}
          text='Fechar Conta'
          onPress={handleCloseModule}
        />
        <AddOrderButton
          disabled={moduleAlreadyClose}
          text='Novo Pedido'
          onPress={() => navigate("Products", { module: selectedModule! })}
        />
      </Actions>
    </Container>
  );
};
