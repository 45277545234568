import { MotiView } from "moti";
import styled from "styled-components/native";

export const Track = styled(MotiView)`
  width: 44px;

  padding: 2px;

  border-width: 1px;
  border-radius: 40px;
`;

export const Thumb = styled(MotiView)`
  width: 20px;
  height: 20px;
  border-radius: 10px;
`;
