import Svg, { SvgProps, Path } from "react-native-svg";

export default (props: SvgProps) => (
  <Svg
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <Path d='M17.143 10H2.857' stroke='currentColor' strokeLinecap='round' />
  </Svg>
);
