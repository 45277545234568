import * as yup from "yup";

export const formConnectionNetworkSchema = yup.object().shape({
  ip: yup.string().required("O IP do Servidor é obrigatório!"),
});

export const formSignInSchema = yup.object().shape({
  user: yup.string().required("Usuário é obrigatório!"),
  password: yup.string().required("Senha é obrigatório!"),
});
