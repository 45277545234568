import { RFValue } from "react-native-responsive-fontsize";
import styled from "styled-components/native";

import { StyledInput } from "@components/Forms/TextInput/styles";

import { CategoryTitle } from "../CategoryHeader/styles";

export const Container = styled.View`
  margin: 0px 0 32px;
  padding: 0 20px;
`;

export const Title = styled(CategoryTitle)`
  width: 100%;

  padding: 16px 0;
`;

export const TextAreaBox = styled.View`
  padding: 14px 20px;

  background-color: ${({ theme }) => theme.colors.background[200]};

  border-radius: ${RFValue(12)}px;
`;

export const TextAreaInput = styled(StyledInput).attrs({
  multiline: true,
})`
  font-size: ${RFValue(14)}px;

  min-height: 100px;
`;
