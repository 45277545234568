import { useCallback } from "react";
import { Animated, Keyboard } from "react-native";
import { FlashList, ListRenderItem } from "@shopify/flash-list";

import {
  ModuleBottomSheetContextProvider,
  ModulesPageContextProvider,
  ModulesPageContext,
  useUser,
} from "@contexts/index";

import { SpacerBox, EmptyListComponent } from "@components/index";

import { CardModule } from "./components/CardModule";
import { Favorites } from "./components/Favorites";
import { FloatingHeader } from "./components/FloatingHeader";
import { Header } from "./components/Header";
import { MenuDropdown } from "./components/MenuDropdown";
import { ModuleBottomSheet } from "./components/ModuleBottomSheet";
import { SearchArea } from "./components/SearchArea";

import {
  CategoryPageTitle,
  Container,
  CategoryPageTitleHighlight,
  CircularLoader,
} from "./styles";

import type { IModule } from "@contexts/@types/useModulesPage.types";
import { TabBar } from "./components/TabBar";

const columns = 2;

export const ModulesPage = () => {
  const { user } = useUser();

  const useAllModules =
    user!.usedModules.includes("M") && user!.usedModules.includes("C");

  const renderItem: ListRenderItem<IModule> = useCallback(({ item, index }) => {
    return <CardModule index={index} numOfColumns={columns} {...item} />;
  }, []);

  const ListHeaderComponent = (
    <>
      <Header />
      <SearchArea />
      <Favorites />
      <CategoryPageTitle>
        Todas
        <CategoryPageTitleHighlight>
          {" Disponíveis"}
        </CategoryPageTitleHighlight>
      </CategoryPageTitle>
    </>
  );

  const ListFooterComponent = <SpacerBox height={96} />;

  return (
    <ModulesPageContextProvider>
      <Container>
        <FloatingHeader />
        <MenuDropdown />
        <ModulesPageContext.Consumer>
          {({
            filteredModuleList,
            flashListRef,
            scrollY,
            currentModuleType,
            modulesLoading,
          }) => (
            <FlashList
              ref={flashListRef}
              data={filteredModuleList}
              keyExtractor={(item) => item.id}
              renderItem={renderItem}
              numColumns={columns}
              onScroll={Animated.event(
                [{ nativeEvent: { contentOffset: { y: scrollY } } }],
                { useNativeDriver: false }
              )}
              onScrollBeginDrag={Keyboard.dismiss}
              ItemSeparatorComponent={() => <SpacerBox height={16} />}
              ListHeaderComponent={ListHeaderComponent}
              ListFooterComponent={ListFooterComponent}
              estimatedItemSize={164}
              showsVerticalScrollIndicator={false}
              ListEmptyComponent={
                modulesLoading ? (
                  <CircularLoader />
                ) : (
                  <EmptyListComponent
                    highlightLabel='Nenhuma'
                    normalLabel={`${
                      currentModuleType === "C" ? "comanda" : "mesa"
                    } foi encontrada.`}
                    type='card'
                  />
                )
              }
            />
          )}
        </ModulesPageContext.Consumer>
        {useAllModules && <TabBar />}
      </Container>
      <ModuleBottomSheetContextProvider>
        <ModuleBottomSheet />
      </ModuleBottomSheetContextProvider>
    </ModulesPageContextProvider>
  );
};
