import * as React from "react";
import Svg, { SvgProps, G, Path } from "react-native-svg";

export default (props: SvgProps) => (
  <Svg
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <G stroke='currentColor'>
      <Path d='M15 6.667a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Zm0 11.666a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5ZM5 12.5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z' />
      <Path
        d='m12.84 14.573-5.678-3.312m5.678-5.834L7.162 8.739l5.678-3.312Z'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </G>
  </Svg>
);
