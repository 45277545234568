import { useModulesPage } from "@contexts/useModulesPage";
import {
  Container,
  NotesIconStyled,
  TabItem,
  TableIconStyled,
  TabTitle,
} from "./styles";

export const TabBar = () => {
  const { currentModuleType, handleToggleModuleType } = useModulesPage();

  const currentModuleIsCommand = currentModuleType === "C";

  return (
    <Container>
      <TabItem onPress={() => handleToggleModuleType("C")}>
        <NotesIconStyled isActive={currentModuleIsCommand} />
        <TabTitle isActive={currentModuleIsCommand}>Comandas</TabTitle>
      </TabItem>
      <TabItem onPress={() => handleToggleModuleType("M")}>
        <TableIconStyled isActive={!currentModuleIsCommand} />
        <TabTitle isActive={!currentModuleIsCommand}>Mesas</TabTitle>
      </TabItem>
    </Container>
  );
};
