import { CenterPoint, Container } from "./styles";

interface RadioButtonProps {
  size?: number;
  isSelected?: boolean;
  onPress?: () => void;
}

export const RadioButton = ({
  size = 20,
  isSelected = false,
  onPress,
}: RadioButtonProps) => {
  const borderRadius = size / 2;
  return (
    <Container
      onPress={onPress}
      isSelected={isSelected}
      style={{ width: size, height: size, borderRadius }}
    >
      {isSelected && <CenterPoint style={{ borderRadius }} />}
    </Container>
  );
};
