import { useNavigation, StackActions } from "@react-navigation/native";
import { useTheme } from "styled-components/native";

import { PriceHighlight } from "@components/PriceHighlight";

import type { IProduct } from "@contexts/@types/useProductsPage.types";
import type { ICategoryItem } from "@hooks/@types/useProductCategory.types";

import {
  Background,
  Container,
  Content,
  Line,
  OldPrice,
  ProductImage,
  ProductPrice,
  ProductTitle,
  PromotionContainer,
  PromotionText,
} from "./styles";

interface CardProductProps {
  product: IProduct;
  hidePrice?: boolean;
  fixedAdditional?: ICategoryItem[];
  fixedObservations?: ICategoryItem[];
}

export const CardProduct = ({
  product,
  hidePrice,
  fixedAdditional,
  fixedObservations,
}: CardProductProps) => {
  const { dispatch } = useNavigation();
  const { colors, commonShadowConfig } = useTheme();

  const hasPromotionalPrice = product.promotionalPrice !== undefined;

  return (
    <Container
      onPress={() =>
        dispatch(
          StackActions.push("Product", {
            product,
            fixedAdditional,
            fixedObservations,
          })
        )
      }
    >
      <Background
        style={{
          shadowColor: colors.shadows,
          ...commonShadowConfig,
        }}
      />

      <ProductImage
        source={{
          uri: product.image,
        }}
      />

      <Content>
        <ProductTitle hasPromotion={!!product.promotionalPrice}>
          {product.name}
        </ProductTitle>
        {!hidePrice && hasPromotionalPrice && (
          <>
            {product.typePizza && <PromotionText>A partir de</PromotionText>}
            <PromotionContainer>
              <ProductPrice price={product.promotionalPrice!} />
              <OldPrice>
                <Line />
                <PriceHighlight
                  price={product.price}
                  isOldPrice
                  highlightSize={14}
                  normalSize={12}
                />
              </OldPrice>
            </PromotionContainer>
          </>
        )}
        {!hidePrice && !hasPromotionalPrice && (
          <ProductPrice price={product.price} />
        )}
      </Content>
    </Container>
  );
};
