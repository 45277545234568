import { ReactNode } from "react";
import { BoxLabel, Container } from "./styles";

interface LabelProps {
  children: ReactNode;
  text: string;
}

export const Label = ({ children, text }: LabelProps) => {
  return (
    <Container>
      <BoxLabel>{text}</BoxLabel>
      {children}
    </Container>
  );
};
