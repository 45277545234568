import Svg, { SvgProps, Path } from "react-native-svg";

export default (props: SvgProps) => (
  <Svg
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <Path
      d='M10.273 18.072a7.5 7.5 0 1 0 0-15 7.5 7.5 0 0 0 0 15Z'
      stroke='currentColor'
      strokeMiterlimit={10}
    />
    <Path
      d='M10.273 6.197v4.375h4.375'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </Svg>
);
