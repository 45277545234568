import { RFValue } from "react-native-responsive-fontsize";
import styled, { DefaultTheme } from "styled-components/native";

import { CloseIcon, EditIcon, VerifyIcon } from "@assets/icons";

import { Button } from "@components/Button";

export const Container = styled.View`
  padding: 0 20px;

  margin-bottom: 32px;
`;

export const SurnameControls = styled.View`
  flex-direction: row;
  align-items: center;

  margin: 0 20px 0 8px;
`;

export const SurnameActionButton = styled(Button).attrs({
  variant: "transparent",
})``;

export const ActionsDivider = styled.View`
  width: 1.8px;
  height: 20px;

  background-color: ${({ theme }) => theme.colors.background[300]};

  margin: 0 8px;
`;

const controlStyle = (theme: DefaultTheme) => ({
  color: theme.colors.text[400],
  strokeWidth: 1.4,
  width: RFValue(18),
  height: RFValue(18),
});

export const EditIconStyled = styled(EditIcon).attrs(({ theme }) => ({
  ...controlStyle(theme),
}))``;
export const VerifyIconStyled = styled(VerifyIcon).attrs(({ theme }) => ({
  ...controlStyle(theme),
}))``;

export const ClearIconStyled = styled(CloseIcon).attrs(({ theme }) => ({
  ...controlStyle(theme),
}))``;

export const CircularLoader = styled.ActivityIndicator.attrs(({ theme }) => ({
  color: theme.colors.primary,
  size: RFValue(18),
}))`
  margin-right: 20px;
`;
