import styled from "styled-components/native";
import { RFValue } from "react-native-responsive-fontsize";

import { STATUS_BAR_HEIGHT } from "@utils/nativeLayoutHelper";

import { LongArrow } from "@assets/icons";
import { Button } from "@components/Button";

const buttonReturnWidth = RFValue(20) + 4;

export const Container = styled.View`
  flex: 1;
  padding: 20px;
`;

export const Header = styled.View`
  padding: ${STATUS_BAR_HEIGHT + 20}px ${buttonReturnWidth + 20}px 12px;

  align-items: center;
`;

export const ButtonReturn = styled(Button).attrs({
  variant: "transparent",
})`
  padding: 4px;

  position: absolute;
  left: 20px;
  top: ${STATUS_BAR_HEIGHT + 20}px;
`;

export const LevelTitle = styled.Text`
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: ${RFValue(14)}px;
  color: ${({ theme }) => theme.colors.text[100]};

  padding: 4px 0;
`;

export const LongArrowStyled = styled(LongArrow).attrs(({ theme }) => ({
  width: RFValue(20),
  height: RFValue(20),
  color: theme.colors.text[100],
  strokeWidth: 1.6,
}))`
  transform: rotate(180deg);
`;
