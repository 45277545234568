import AsyncStorage from "@react-native-async-storage/async-storage";
import AxiosInstance, { AxiosError } from "axios";

export const api = AxiosInstance.create({});

let isRefreshing = false;
//@ts-ignore
let failedRequestsQueue = [];

export const setupApiClient = async (signOut: () => void) => {
  const storageIP = await AsyncStorage.getItem("@ip");
  const token = await AsyncStorage.getItem("@TOKEN");

  if (storageIP) {
    api.defaults.baseURL = `http://${JSON.parse(storageIP)}:300/v1/`;
  }

  if (token) {
    api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }

  api.interceptors.response.use(
    (response) => response,
    async (error: AxiosError<any>) => {
      if (
        error.response?.status === 401 &&
        error.response.data?.data === "401 - unauthorized"
      ) {
        const refreshToken = await AsyncStorage.getItem("@REFRESH_TOKEN");

        const originalConfig = error.config;

        if (!isRefreshing) {
          isRefreshing = true;

          api
            .post("usuario/refreshToken", null, {
              headers: {
                Authorization: `Bearer ${refreshToken!}`,
              },
            })
            .then(async (response) => {
              const { data } = response.data;

              const newAccessToken = data.access_token;

              await AsyncStorage.multiSet([
                ["@TOKEN", newAccessToken],
                ["@REFRESH_TOKEN", data.refresh_token],
              ]);

              //@ts-ignore
              api.defaults.headers[
                "Authorization"
              ] = `Bearer ${newAccessToken}`;
              //@ts-ignore
              failedRequestsQueue.forEach((request) =>
                request.onSuccess(newAccessToken)
              );
              failedRequestsQueue = [];
            })
            .catch(async (err) => {
              //@ts-ignore
              failedRequestsQueue.forEach((request) => request.onFailure(err));
              failedRequestsQueue = [];

              signOut();
            })
            .finally(() => {
              isRefreshing = false;
            });
        }

        return new Promise((resolve, reject) => {
          failedRequestsQueue.push({
            onSuccess: (token: string) => {
              //@ts-ignore
              originalConfig.headers["Authorization"] = `Bearer ${token}`;

              resolve(api(originalConfig));
            },
            onFailure: (err: AxiosError<any>) => {
              reject(err);
            },
          });
        });
      } else if (error.response?.data?.data === "TOKEN_REVOKED") {
        signOut();
      }

      return Promise.reject(error);
    }
  );
};
