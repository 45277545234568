import { KeyboardAvoidingView } from "react-native";
import { Control, Controller } from "react-hook-form";
import { FormConnectionNetworkData, FormSignInData } from "..";

import { TextInput } from "@components/Forms/TextInput";
import {
  NetworkIconStyled,
  ArtBox,
  ConnectionArt,
  TextInstructions,
  FormContainer,
  FreeTestButton,
} from "../styles";
import { useUser } from "@contexts/useUser";

interface FormConnectionNetworkProps {
  control: Control<FormConnectionNetworkData | FormSignInData>;
  onSubmit: () => void;
}

export const FormConnectionNetwork = ({
  control,
  onSubmit,
}: FormConnectionNetworkProps) => {
  const { handleActiveFreeTest } = useUser();

  return (
    <>
      <ArtBox>
        <ConnectionArt />
      </ArtBox>

      <FormContainer>
        <TextInstructions>
          Vamos conectar ao seu local de trabalho
        </TextInstructions>
        <Controller
          name='ip'
          control={control}
          render={({ field: { onChange, onBlur, value }, fieldState }) => (
            <TextInput
              placeholder='IP do servidor'
              elementLeft={{ type: "icon", element: <NetworkIconStyled /> }}
              elementRight={{
                element: (
                  <FreeTestButton
                    text='Testar Grátis'
                    onPress={handleActiveFreeTest}
                  />
                ),
              }}
              error={fieldState.error?.message}
              onBlur={onBlur}
              onChangeText={(text) =>
                onChange(
                  text.replace(/,/g, ".").replace(/(\d{3})(\d.*)/g, "$1.$2")
                )
              }
              value={value}
              keyboardType='decimal-pad'
              onSubmitEditing={onSubmit}
            />
          )}
        />
      </FormContainer>
    </>
  );
};
