import { useAnimationState } from "moti";
import { useEffect } from "react";
import { Pressable } from "react-native";

import { convertHexToRgba } from "@utils/string.utils";

import light from "../../../styles/themes/light";
import { Thumb, Track } from "./styles";

const useTrackAnimation = () =>
  useAnimationState(
    {
      on: {
        borderColor: light.colors.primary,
        backgroundColor: convertHexToRgba(light.colors.primary, 0.12),
      },
      off: {
        borderColor: light.colors.background[400],
        backgroundColor: light.colors.background[100],
      },
    },
    {
      from: "off",
      to: "on",
    }
  );

const useThumbAnimation = () =>
  useAnimationState(
    {
      on: {
        right: -18,
        backgroundColor: light.colors.primary,
      },
      off: {
        right: 0,
        backgroundColor: light.colors.background[500],
      },
    },
    {
      from: "off",
      to: "on",
    }
  );

const transitionSpring = {
  stiffness: 800,
  damping: 300,
  mass: 1.5,
};

interface SwitchProps {
  isActive: boolean;
  onChange: (value: boolean) => void;
}

export const Switch = ({ isActive, onChange }: SwitchProps) => {
  const trackAnimation = useTrackAnimation();
  const thumbAnimation = useThumbAnimation();

  useEffect(() => {
    if (isActive) {
      trackAnimation.transitionTo("on");
      thumbAnimation.transitionTo("on");
    } else {
      trackAnimation.transitionTo("off");
      thumbAnimation.transitionTo("off");
    }
  }, [isActive]);

  return (
    <Pressable onPress={() => onChange(!isActive)}>
      <Track state={trackAnimation} transition={transitionSpring}>
        <Thumb state={thumbAnimation} transition={transitionSpring} />
      </Track>
    </Pressable>
  );
};
