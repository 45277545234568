import { MotiView } from "moti";
import { FlatList, FlatListProps, Platform } from "react-native";
import styled from "styled-components/native";

import { IModule } from "@contexts/@types/useModulesPage.types";

export const FavoritesContainer = styled(MotiView).attrs({
  from: {
    maxHeight: 0,
    opacity: 0,
  },
  animate: {
    maxHeight: 400,
    opacity: 1,
  },
  exit: {
    maxHeight: 0,
    opacity: 0,
  },
  transition: {
    stiffness: 800,
    damping: 60,
    mass: 1,
  },
})`
  width: 100%;

  align-items: flex-start;
`;

export const FavoritesList = styled(
  FlatList as new (props: FlatListProps<IModule>) => FlatList<IModule>
).attrs({
  showsHorizontalScrollIndicator: false,
  contentContainerStyle: { paddingHorizontal: 12, paddingBottom: 34 },
})`
  ${Platform.OS === "web" && "width: 100%"}
`;
