import AsyncStorage from "@react-native-async-storage/async-storage";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import { api } from "@services/api";
import { productGroupQueries, productQueries } from "@services/queries";
import { capitalize, normalize } from "@utils/string.utils";

import type { GenericResponse } from "../@types/generic-response";
import type {
  GroupFromBD,
  IGroup,
  IProductDetailed,
  ProductFromDB,
  ProductsPageContextData,
  ProductsPageProviderProps,
} from "./@types/useProductsPage.types";
import { defaultCatch } from "@utils/defaultCatch";
import { useFocusEffect, useNavigation } from "@react-navigation/native";
import { useOrder } from "./useOrder";
import { BackHandler } from "react-native";
import { FlashList } from "@shopify/flash-list";

export const ProductsPageContext = createContext({} as ProductsPageContextData);

export const ProductsPageContextProvider = ({
  children,
}: ProductsPageProviderProps) => {
  const { goBack } = useNavigation();
  const { orderProducts } = useOrder();

  const [showPizzaBottomSheet, setShowPizzaBottomSheet] = useState(false);
  const [showCancelOrderMessage, setShowCancelOrderMessage] = useState(false);
  const [isProductsAndGroupsLoading, setIsProductsAndGroupsLoading] =
    useState(true);
  const [showGroupsSection, setShowGroupsSection] = useState(true);
  const [allProducts, setAllProducts] = useState<IProductDetailed[]>([]);
  const [groups, setGroups] = useState<IGroup[]>([]);
  const [searchedProduct, setSearchedProduct] = useState("");
  const [selectedGroupsID, setSelectedGroupsID] = useState("allProducts");
  const productListRef = useRef<FlashList<any>>(null);

  const filteredProductList = allProducts.filter((product) => {
    const groupSelectedIsEqualsGroupID =
      selectedGroupsID === product.groupID ||
      selectedGroupsID === "allProducts";

    if (searchedProduct.length > 0) {
      const nameFormatted = normalize(product.name.toLowerCase().trim());
      const searchValueFormatted = normalize(
        searchedProduct.toLowerCase().trim()
      );
      1;
      return (
        nameFormatted.includes(searchValueFormatted) &&
        groupSelectedIsEqualsGroupID
      );
    }
    return groupSelectedIsEqualsGroupID;
  });

  useEffect(() => {
    const getAllProductsAndGroups = async () => {
      try {
        setIsProductsAndGroupsLoading(true);

        const storageIP: string = JSON.parse(
          (await AsyncStorage.getItem("@ip")) ?? ""
        );

        const { data: groups } = (
          await api.post<GenericResponse<GroupFromBD[]>>("venda/query", {
            SQL: productGroupQueries.getAll(),
          })
        ).data;

        const { data: products } = (
          await api.post<GenericResponse<ProductFromDB[]>>("venda/query", {
            SQL: productQueries.getAll(),
          })
        ).data;

        const groupsFormatted: IGroup[] = groups.map((group) => ({
          id: group.ID.toString(),
          name: capitalize(group.DESCRICAO),
        }));

        const allProductsFormatted: IProductDetailed[] = products
          .sort((pA, pB) => pA.ORDEM_TOUCH - pB.ORDEM_TOUCH)
          .map((p) => {
            const promotionalPriceFormatted = Number(p.VALOR_PROMOCIONAL);
            return {
              id: p.ID.toString(),
              groupID: p.ID_GRUPO.toString(),
              name: capitalize(p.DESCRICAO_TOUCH ?? "Sem Nome"),
              price: Number(p.VALOR_VENDA),
              promotionalPrice:
                promotionalPriceFormatted === 0
                  ? undefined
                  : promotionalPriceFormatted,
              unit: p.UNIDADE,
              image:
                storageIP === "0.0.0.0"
                  ? p.IMAGE_URL!
                  : `http://${storageIP}:53649/produto_galeria/${p.ID_IMAGEM_PADRAO}?v=${p.VERSAO_IMAGEM}`,
              productType: p.TIPO,
              canFractionate: p.PODE_FRACIONAR === "S",
              typePizza: p.PRODUTO_PIZZA === 1,
              typeCategory: p.TIPO_CATEGORIA === 1,
            };
          });

        setGroups([
          {
            id: "allProducts",
            name: "Todos",
          },
          ...groupsFormatted,
        ]);
        setAllProducts(allProductsFormatted);
      } catch (error: any) {
        defaultCatch(
          error,
          "getAllProductsAndGroups",
          "Erro ao carregar os produtos!"
        );
      } finally {
        setIsProductsAndGroupsLoading(false);
      }
    };
    getAllProductsAndGroups();
  }, []);

  useFocusEffect(
    useCallback(() => {
      const onBackPress = () => {
        if (orderProducts.length !== 0) {
          setShowCancelOrderMessage(true);

          return true;
        } else {
          return false;
        }
      };

      const subscription = BackHandler.addEventListener(
        "hardwareBackPress",
        onBackPress
      );

      return () => subscription.remove();
    }, [orderProducts])
  );

  const handleClosePizzaBottomSheet = () => {
    setShowPizzaBottomSheet(false);
  };

  const handleOpenPizzaBottomSheet = (productID: string) => {
    setShowPizzaBottomSheet(true);
  };

  const handleSearchProduct = (value: string) => {
    setSearchedProduct(value);
  };

  const handleSelectGroup = (groupID: string) => {
    productListRef.current?.scrollToIndex({ animated: true, index: 0 });
    setSelectedGroupsID(groupID);
  };

  const handleRequestReturn = (clearAndReturn?: boolean) => {
    if (!clearAndReturn && orderProducts.length !== 0) {
      setShowCancelOrderMessage(true);
    } else {
      goBack();
    }
  };

  const handleCloseOrderMessage = () => {
    setShowCancelOrderMessage(false);
  };

  const handleSetShowSection = (value: boolean) => {
    setShowGroupsSection(value);
  };

  return (
    <ProductsPageContext.Provider
      value={{
        showPizzaBottomSheet,
        isProductsAndGroupsLoading,
        filteredProductList,
        groups,
        selectedGroupsID,
        showCancelOrderMessage,
        productListRef,
        showGroupsSection,
        handleOpenPizzaBottomSheet,
        handleClosePizzaBottomSheet,
        handleSearchProduct,
        handleSelectGroup,
        handleRequestReturn,
        handleCloseOrderMessage,
        handleSetShowSection,
      }}
    >
      {children}
    </ProductsPageContext.Provider>
  );
};

export const useProductsPage = () => useContext(ProductsPageContext);
