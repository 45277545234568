import { Container, LevelTitle, RequiredLevelStatus } from "./styles";

interface ComboLevelHeader {
  title: string;
  valid: boolean;
}

export const ComboLevelHeader = ({ title, valid }: ComboLevelHeader) => {
  return (
    <Container>
      <LevelTitle>{title}</LevelTitle>
      {valid ? (
        <RequiredLevelStatus isValid>OK!</RequiredLevelStatus>
      ) : (
        <RequiredLevelStatus>Obrigatório</RequiredLevelStatus>
      )}
    </Container>
  );
};
