import { Animated, SectionListProps } from "react-native";
import { RFValue } from "react-native-responsive-fontsize";
import styled from "styled-components/native";

import type { Category } from "../../hooks/@types/useProductCategory.types";
import type { ItemOfSection } from "../../@types/genericSectionIte";
import type { ComboLevel } from "../../hooks/@types/useProductCombo.types";

export interface SectionCategory {
  title: string;
  category?: Category;
  comboLevel?: ComboLevel;
  data: {
    id: string;
    body: JSX.Element;
  }[];
}

export const Container = styled.View`
  flex: 1;

  background-color: ${({ theme }) => theme.colors.background[100]};

  overflow: hidden;
`;

export const Content = styled(
  Animated.SectionList as new (
    props: SectionListProps<ItemOfSection, SectionCategory>
  ) => Animated.SectionList<ItemOfSection>
)`
  flex: 1;
`;

export const CircularLoader = styled.ActivityIndicator.attrs(({ theme }) => ({
  color: theme.colors.primary,
  size: RFValue(32),
}))`
  margin-top: 52px;
`;
