import Svg, { SvgProps, Path } from "react-native-svg";

export default (props: SvgProps) => (
  <Svg
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <Path
      d='M10 17.143V10m0 0V2.857M10 10h7.143M10 10H2.857'
      stroke='currentColor'
      strokeLinecap='round'
    />
  </Svg>
);
