import { useRef, useState } from "react";
import { Control, Controller } from "react-hook-form";
import { TextInput as TextInputReact } from "react-native";

import type { FormConnectionNetworkData, FormSignInData } from "..";

import { TextInput, SpacerBox } from "@components/index";
import {
  PersonIconStyled,
  LockOpenIconStyled,
  EyeCloseIconStyled,
  ArtBox,
  LoginArt,
  TextInstructions,
  FormContainer,
  SwitchVisibilityPasswordButton,
  EyeOpenIconStyled,
} from "../styles";

interface FormSignInProps {
  control: Control<FormConnectionNetworkData | FormSignInData>;
  onSubmit: () => void;
}

export const FormSignIn = ({ control, onSubmit }: FormSignInProps) => {
  const passwordInput = useRef<TextInputReact>(null);

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  return (
    <>
      <ArtBox>
        <LoginArt />
      </ArtBox>
      <TextInstructions>
        Agora basta inserir as credenciais do PDV
      </TextInstructions>
      <FormContainer>
        <Controller
          name='user'
          control={control}
          render={({ field: { onChange, onBlur, value }, fieldState }) => (
            <TextInput
              placeholder='Usuário'
              elementLeft={{ type: "icon", element: <PersonIconStyled /> }}
              error={fieldState.error?.message}
              onBlur={onBlur}
              onChangeText={onChange}
              value={value}
              onSubmitEditing={() => {
                passwordInput.current?.focus();
              }}
            />
          )}
        />
        <SpacerBox height={28} />

        <Controller
          name='password'
          control={control}
          render={({ field: { onChange, onBlur, value }, fieldState }) => (
            <TextInput
              ref={passwordInput}
              placeholder='Senha'
              elementLeft={{ type: "icon", element: <LockOpenIconStyled /> }}
              elementRight={{
                element: (
                  <SwitchVisibilityPasswordButton
                    onPress={() => setIsPasswordVisible(!isPasswordVisible)}
                  >
                    {isPasswordVisible ? (
                      <EyeOpenIconStyled />
                    ) : (
                      <EyeCloseIconStyled />
                    )}
                  </SwitchVisibilityPasswordButton>
                ),
              }}
              error={fieldState.error?.message}
              onBlur={onBlur}
              onChangeText={onChange}
              value={value}
              secureTextEntry={!isPasswordVisible}
              onSubmitEditing={onSubmit}
              keyboardType='default'
            />
          )}
        />
      </FormContainer>
    </>
  );
};
