import * as React from "react";
import Svg, { SvgProps, Path } from "react-native-svg";

export default (props: SvgProps) => (
  <Svg
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <Path
      d='M10 15c4.418 0 8-2.239 8-5s-3.582-5-8-5-8 2.239-8 5 3.582 5 8 5Z'
      stroke='currentColor'
    />
    <Path d='M10 13a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z' stroke='currentColor' />
  </Svg>
);
