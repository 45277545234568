import { useNavigation } from "@react-navigation/native";
import { Animated, SectionList, Platform } from "react-native";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import hash from "object-hash";

import { api } from "@services/api";
import { productQueries } from "@services/queries";
import { capitalize } from "@utils/string.utils";
import EventEmitter from "@services/EventEmitter";

import type {
  ProductPageContextData,
  ProductPageProviderProps,
  AdditionalFromBD,
  PrefixObservationFromBD,
} from "./@types/useProductPage.types";
import type { GenericResponse } from "../@types/generic-response";
import type { IProductOrder } from "./@types/useOrder.types";
import type {
  Category,
  ICategoryItem,
} from "@hooks/@types/useProductCategory.types";

import { toastNotification } from "@hooks/useToast";
import { defaultCatch } from "@utils/defaultCatch";
import { useProductCategory } from "@hooks/useProductCategory";
import { useProductCombo } from "@hooks/useProductCombo";

export const ProductPageContext = createContext({} as ProductPageContextData);

export const ProductPageContextProvider = ({
  product,
  index,
  selectedLevelID,
  fixedAdditional,
  fixedObservations,
  children,
}: ProductPageProviderProps) => {
  const [
    loadingAdditionalAndObservations,
    setLoadingAdditionalAndObservations,
  ] = useState(true);

  const isEditing = index !== undefined || selectedLevelID !== undefined;
  const { goBack } = useNavigation();
  const categoryServices = useProductCategory({
    product,
    isEditingMode: isEditing,
  });
  const comboServices = useProductCombo({
    product,
    isEditingMode: isEditing,
  });

  const [productAmount, setProductAmount] = useState(1);
  const [observation, setObservation] = useState("");
  const [footerHeight, setFooterHeight] = useState(0);
  const [headerHeight, setHeaderHeight] = useState(0);

  const contentRef = useRef<SectionList>(null);

  const scrollYPage = useRef(new Animated.Value(0)).current;

  const getExtraPrice = () => {
    if (
      product.productType === "N" ||
      product.productType === "C" ||
      product.typeCategory
    ) {
      return categoryServices.allSelectedItemsTotal;
    } else {
      return 0;
    }
  };

  const totalPrice =
    ((product.promotionalPrice ?? product.price) + getExtraPrice()) *
    productAmount;

  const isProductInComboLevel =
    fixedAdditional !== undefined || fixedObservations !== undefined;

  const getAdditionalAndObservations = async () => {
    let prefixObservationsItems: ICategoryItem[] = fixedObservations ?? [];
    let additionalItems: ICategoryItem[] = fixedAdditional ?? [];

    try {
      if (!isProductInComboLevel) {
        const { data: additional } = (
          await api.post<GenericResponse<AdditionalFromBD[]>>("venda/query", {
            SQL: productQueries.getAdditional(Number(product.id)),
          })
        ).data;

        const { data: observations } = (
          await api.post<GenericResponse<PrefixObservationFromBD[]>>(
            "venda/query",
            {
              SQL: productQueries.getPrefixObservations(Number(product.id)),
            }
          )
        ).data;

        prefixObservationsItems = observations.map(({ ID, OBSERVACAO }) => ({
          id: ID.toString(),
          categoryID: "Observações (Prefixadas)",
          description: OBSERVACAO,
          amount: 0,
          maxAmount: 0,
          type: "OBSERVACAO",
        }));

        additionalItems = additional.map(
          ({ DESCRICAO_TOUCH, ID, VALOR_VENDA }) => {
            return {
              id: ID.toString(),
              categoryID: "Adicionais",
              description: capitalize(DESCRICAO_TOUCH ?? "sem nome"),
              price: Number(VALOR_VENDA),
              amount: 0,
              maxAmount: 0,
              type: "ADICIONAL",
            };
          }
        );
      }

      const newCategories: Category[] = [
        {
          title: "Adicionais",
          id: "Adicionais",
          inputType: "counter",
          position: 1,
          itemsAmount: 0,
          maxAmount: 0,
          minAmount: 0,
          required: false,
          items: additionalItems,
        },
        {
          title: "Observações (Prefixadas)",
          id: "Observações",
          inputType: "check",
          position: 2,
          itemsAmount: 0,
          maxAmount: 0,
          minAmount: 0,
          required: false,
          items: prefixObservationsItems,
        },
      ];

      categoryServices.handleSetCategories(
        newCategories.filter((c) => c.items.length !== 0)
      );
    } catch (error: any) {
      defaultCatch(
        error,
        "getAdditionalAndObservations",
        "Erro ao carregar os adicionais e observações!"
      );
    }
  };

  useEffect(() => {
    (async () => {
      if (
        (product.productType === "N" || product.productType === "C") &&
        !product.typeCategory
      ) {
        await getAdditionalAndObservations();
      } else if (product.typePizza) {
        toastNotification({
          message: "A página de pizzas ainda está em desenvolvimento!",
          type: "error",
        });
      }
      setLoadingAdditionalAndObservations(false);
    })();
  }, [product]);

  useEffect(() => {
    if (isEditing && !loadingAdditionalAndObservations) {
      const productOrder = product as IProductOrder;
      productOrder.observation && setObservation(productOrder.observation);
      setProductAmount(productOrder.amount);
    }
  }, [loadingAdditionalAndObservations, isEditing]);

  const handleChangeProductAmount = (amount: number) => {
    setProductAmount(amount);
  };

  const navigateToInvalidSection = () => {
    const invalidSectionIndex = product.typeCategory
      ? categoryServices.getInvalidCategory()
      : comboServices.getInvalidLevel();
    toastNotification({
      message: "Preencha as categorias obrigatórias!",
      type: "error",
    });
    contentRef.current?.scrollToLocation({
      sectionIndex: invalidSectionIndex,
      itemIndex: 0,
      animated: true,
      viewOffset: Platform.OS === "ios" ? undefined : headerHeight,
    });
  };

  const validateProduct = () => {
    const invalidCategoryIndex = categoryServices.getInvalidCategory();
    const invalidLevelIndex = comboServices.getInvalidLevel();

    if (product.typeCategory) {
      return !invalidCategoryIndex;
    }

    if (product.productType === "G") {
      return !invalidLevelIndex;
    }

    return true;
  };

  const handleLaunchProduct = () => {
    if (!validateProduct()) {
      navigateToInvalidSection();
      return;
    }

    const productOrderFormatted = {
      ...product,
      totalPrice,
      amount: productAmount,
      observation: observation.length > 0 ? observation : undefined,
      additional: categoryServices.selectedProducts,
      prefixObservations: categoryServices.selectedObservations,
      comboProducts: comboServices.selectedProducts,
    };

    if (isProductInComboLevel) {
      EventEmitter.notify("addOrEditProductComboLevel", {
        selectedLevelID,
        selectedProduct: productOrderFormatted,
      });
    } else {
      EventEmitter.notify("addOrEditProduct", {
        newProduct: productOrderFormatted,
        index,
      });
    }

    goBack();
  };

  const handleObservationChange = (newObservationValue: string) => {
    setObservation(newObservationValue);
  };

  const verifyChanges = () => {
    const { selectedProducts, selectedObservations } = categoryServices;

    if (isEditing) {
      const {
        amount,
        additional,
        observation: prevObservation,
        prefixObservations,
        comboProducts,
      } = product as IProductOrder;

      return (
        hash({
          amount: productAmount,
          observation,
          additional: selectedProducts,
          prefixObservations: selectedObservations,
          comboProducts: comboServices.selectedProducts,
        }) !==
        hash({
          amount,
          observation: prevObservation ?? "",
          additional: additional ?? [],
          prefixObservations: prefixObservations ?? [],
          comboProducts: comboProducts ?? [],
        })
      );
    }

    return false;
  };

  const handleChangeDimensions = (
    typeDimension: "header" | "footer",
    dimension: number
  ) => {
    if (typeDimension === "header") {
      setHeaderHeight(dimension);
    } else {
      setFooterHeight(dimension);
    }
  };

  const getLoadingState = () => {
    if (product.typeCategory) {
      return categoryServices.loadingCategories;
    } else if (product.productType === "G") {
      return comboServices.loadingComboLevels;
    } else {
      return loadingAdditionalAndObservations;
    }
  };

  return (
    <ProductPageContext.Provider
      value={{
        product,
        totalPrice,
        productAmount,
        scrollYPage,
        observation,
        isEditing,
        isLoading: getLoadingState(),
        categoryServices,
        comboServices,
        hasChanged: verifyChanges(),
        validProduct: validateProduct(),
        contentRef,
        footerHeight,
        headerHeight,
        isProductInComboLevel,
        handleChangeProductAmount,
        handleLaunchProduct,
        handleObservationChange,
        handleChangeDimensions,
      }}
    >
      {children}
    </ProductPageContext.Provider>
  );
};

export const useProductPage = () => useContext(ProductPageContext);
