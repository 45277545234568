import { Dimensions, StatusBar, Platform } from "react-native";
import { getStatusBarHeight } from "react-native-safearea-height";

const iosNavBarHeight = 8;

export const SCREEN_HEIGHT = Dimensions.get("screen").height;
export const SCREEN_WIDTH = Dimensions.get("screen").width;
export const SAFE_SCREEN_HEIGHT =
  Dimensions.get("window").height - getStatusBarHeight(true) - iosNavBarHeight;
export const STATUS_BAR_HEIGHT =
  StatusBar.currentHeight ?? getStatusBarHeight();
export const NAV_BAR_HEIGHT =
  Platform.OS === "ios"
    ? iosNavBarHeight
    : SCREEN_HEIGHT - SAFE_SCREEN_HEIGHT - STATUS_BAR_HEIGHT;
