import { MotiTransitionProp, useAnimationState } from "moti";
import { RFValue } from "react-native-responsive-fontsize";

export type TransitionProps = Omit<MotiTransitionProp, "type">;

//repetindo em muitas telas
export const transitionSpring = {
  stiffness: 1000,
  damping: 100,
  mass: 4,
};

export const useDropItemAnimation = () =>
  useAnimationState(
    {
      show: {
        maxHeight: RFValue(24),
        marginTop: 8,
      },
      hidden: {
        maxHeight: 0,
        marginTop: 0,
      },
    },
    {
      from: "hidden",
      to: "show",
    }
  );

export const useDropdownAnimation = () =>
  useAnimationState(
    {
      show: {
        opacity: 1,
        paddingTop: 4,
      },
      hidden: {
        opacity: 0,
        paddingTop: 0,
      },
    },
    {
      from: "hidden",
      to: "show",
    }
  );

export const useFooterAnimation = (hasActions: boolean) =>
  useAnimationState(
    {
      show: {
        maxHeight: RFValue(32),
        marginTop: hasActions ? 12 : 0,
      },
      hidden: {
        maxHeight: 0,
        marginTop: 0,
      },
    },
    {
      from: "hidden",
      to: "show",
    }
  );

export const useTagHasExtraAnimation = () =>
  useAnimationState(
    {
      show: {
        opacity: 1,
      },
      hidden: {
        opacity: 0,
      },
    },
    {
      from: "hidden",
      to: "show",
    }
  );

export const useArrowAnimation = () =>
  useAnimationState(
    {
      down: {
        rotateZ: "0deg",
      },
      up: {
        rotateZ: "180deg",
      },
    },
    {
      from: "down",
      to: "up",
    }
  );
