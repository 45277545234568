import { commonColors } from "./commonColors";
import { fonts } from "./fonts";

const dark = {
  title: "dark",
  colors: {
    ...commonColors,

    background: {
      100: "#232528",
      200: "#2A2C2F",
      300: "#313336",
      400: "#414348",
      500: "#7D7F84",
    },

    text: {
      100: "#FBFBFB",
      200: "#E5E5E5",
      400: "#898c91",
      500: "#585A5F",
    },

    shadows: "#00000008",
  },
  commonShadowConfig: {
    shadowOffset: {
      width: 0,
      height: 6,
    },
    shadowOpacity: 1,
    shadowRadius: 12,
    elevation: 12,
  },
  fonts,
};

export default dark;
