import { AnimatePresence } from "moti";
import { useEffect, useState } from "react";
import { Gesture, GestureDetector } from "react-native-gesture-handler";
import {
  runOnJS,
  useAnimatedStyle,
  useSharedValue,
  withSpring,
} from "react-native-reanimated";

import { SAFE_SCREEN_HEIGHT, SCREEN_HEIGHT } from "@utils/nativeLayoutHelper";

import {
  Container,
  GestureRootView,
  HandleArea,
  HandleLine,
  Overlay,
  VisibleArea,
} from "./styles";

const springAnimation = {
  in: {
    stiffness: 800,
    damping: 100,
    mass: 2,
  },
  out: {
    stiffness: 800,
    damping: 100,
    mass: 1.5,
  },
};

export type BottomSheetAnimationType = "in" | "out";

export interface BottomSheetModalProps {
  isOpen: boolean;
  percentagePosition?: number;
  children?: any;
  onDismiss: () => void;
  onAnimationEnd?: (type: BottomSheetAnimationType) => void;
}

export const BottomSheetModal = ({
  isOpen = false,
  percentagePosition = 30,
  children,
  onDismiss,
  onAnimationEnd,
}: BottomSheetModalProps) => {
  const [handleHeight, setHandleHeight] = useState(0);

  const translationY = useSharedValue(0);

  const context = useSharedValue({ y: 0 });

  const initialPosition = -SCREEN_HEIGHT * (percentagePosition / 100);

  const scrollTo = (
    destination: number,
    type: BottomSheetAnimationType = "in"
  ) => {
    "worklet";
    translationY.value = withSpring(
      destination,
      springAnimation[type],
      (finished) => {
        onAnimationEnd && finished && runOnJS(onAnimationEnd)(type);
      }
    );
  };

  useEffect(() => {
    if (isOpen) {
      scrollTo(initialPosition, "in");
    } else {
      scrollTo(0, "out");
    }
  }, [isOpen]);

  const gesture = Gesture.Pan()
    .onBegin(() => {
      context.value = { y: translationY.value };
    })
    .onUpdate((event) => {
      translationY.value = event.translationY + context.value.y;
      translationY.value = Math.max(translationY.value, initialPosition);
    })
    .onEnd(() => {
      if (translationY.value > initialPosition) {
        scrollTo(0, "out");
        runOnJS(onDismiss)();
      }
    });

  const animatedSheetStyle = useAnimatedStyle(() => {
    return {
      transform: [
        {
          translateY: translationY.value,
        },
      ],
    };
  });

  const isFullscreen = percentagePosition === 100;

  return (
    <AnimatePresence>
      {isOpen && (
        <GestureRootView>
          <Overlay onTouchEnd={onDismiss} />
          <Container style={animatedSheetStyle} isFullscreen={isFullscreen}>
            {!isFullscreen && (
              <GestureDetector gesture={gesture}>
                <HandleArea
                  onLayout={({
                    nativeEvent: {
                      layout: { height },
                    },
                  }) => setHandleHeight(height)}
                >
                  <HandleLine />
                </HandleArea>
              </GestureDetector>
            )}
            <VisibleArea
              visibleAreaHeight={initialPosition * -1}
              isFullscreen={isFullscreen}
              handlerHeight={handleHeight}
            >
              {children}
            </VisibleArea>
          </Container>
        </GestureRootView>
      )}
    </AnimatePresence>
  );
};
