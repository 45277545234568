import { ReactNode } from "react";
import { StyleProp, TextStyle, TouchableOpacityProps } from "react-native";

import { ButtonContainer, ButtonText, IconBox } from "./styles";

interface ButtonProps extends TouchableOpacityProps {
  iconLeft?: ReactNode;
  iconRight?: ReactNode;
  variant: "translucent" | "solid" | "transparent";
  backgroundHashColor?: string;
  textHashColor?: string;
  text?: string;
  textButtonStyle?: StyleProp<TextStyle>;
}

export const Button = ({
  iconLeft,
  iconRight,
  variant,
  backgroundHashColor,
  textHashColor,
  children,
  text,
  textButtonStyle,
  ...rest
}: ButtonProps) => {
  return (
    <ButtonContainer
      variant={variant}
      backgroundHashColor={backgroundHashColor}
      textHashColor={textHashColor}
      {...rest}
    >
      {iconLeft && <IconBox iconPosition='left'>{iconLeft}</IconBox>}
      {!children ? (
        <ButtonText
          variant={variant}
          backgroundHashColor={backgroundHashColor}
          textHashColor={textHashColor}
          style={textButtonStyle}
        >
          {text}
        </ButtonText>
      ) : (
        children
      )}
      {iconRight && <IconBox iconPosition='right'>{iconRight}</IconBox>}
    </ButtonContainer>
  );
};
