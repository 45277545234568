import { Animated } from "react-native";
import styled from "styled-components/native";
import { RFValue } from "react-native-responsive-fontsize";

import { Button } from "@components/Button";
import { STATUS_BAR_HEIGHT } from "@utils/nativeLayoutHelper";

export const Container = styled.View`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  z-index: 1;
`;

export const HeaderProduct = styled(Animated.View)`
  padding: ${STATUS_BAR_HEIGHT + 20}px 20px 12px;

  background-color: ${({ theme }) => theme.colors.background[100]};
`;

export const TitleHeader = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: center;

  padding: 4px 0;
`;

export const ButtonReturn = styled(Button)<{ isTheFirst?: boolean }>`
  background-color: ${({ theme }) => theme.colors.background[100]};
  border-radius: 8px;

  padding: 4px;
  margin-right: 16px;
  position: absolute;
  left: 20px;
  top: ${STATUS_BAR_HEIGHT + 20}px;
`;

export const ProductTitle = styled.Text`
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: ${RFValue(14)}px;
  color: ${({ theme }) => theme.colors.text[100]};
`;
