import * as React from "react";
import Svg, { SvgProps, Path } from "react-native-svg";

export default (props: SvgProps) => (
  <Svg
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <Path
      d='M5.833 4.9h8.334M5.833 8.3h8.334m-8.334 3.4h8.334m-8.334 3.4h3.334m-5-13.6c-.834 0-1.667.68-1.667 1.7v13.6c0 .85 1 1.7 1.667 1.7h11.666c1.25 0 1.667-1.02 1.667-1.7V3.2c0-.85-.667-1.7-1.667-1.7H4.167Z'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </Svg>
);
