import styled from "styled-components/native";
import { LinearGradient } from "expo-linear-gradient";

import { GradientDivider } from ".";

export const Container = styled(LinearGradient).attrs(({ theme }) => ({
  colors: [
    theme.colors.background[200],
    theme.colors.background[300],
    theme.colors.background[200],
  ],
  start: [0, 1],
  end: [1, 0],
}))<GradientDivider>`
  margin: ${({ verticalMargin }) => verticalMargin ?? 0}px 0;
  height: 1.2px;
`;
