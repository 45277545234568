import { RFValue } from "react-native-responsive-fontsize";
import styled, { css } from "styled-components/native";

import { PlusIcon } from "@assets/icons";

import { Button } from "@components/Button";

interface ContainerProps {
  orderIsEmpty: boolean;
}

export const Container = styled.View<ContainerProps>`
  flex: 1;
  padding: 28px 0px 0px;
`;

export const ExpandedContainer = styled.View`
  flex: 1;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export const OrderListHeader = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: 0 20px;
  margin-bottom: 20px;
`;

export const OrderListTitle = styled.Text`
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: ${RFValue(16)}px;
  color: ${({ theme }) => theme.colors.text[100]};
`;

export const ButtonAddMore = styled(Button).attrs(({ theme }) => ({
  variant: "translucent",
  textButtonStyle: {
    fontSize: RFValue(12),
    fontFamily: theme.fonts.semiBold,
    color: theme.colors.primary,
  },
}))`
  padding: 8px 16px;
`;

export const PlusIconStyled = styled(PlusIcon).attrs(({ theme }) => ({
  width: RFValue(12),
  height: RFValue(12),
  color: theme.colors.primary,
  strokeWidth: 1.8,
}))``;
