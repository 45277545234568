import { FlattenInterpolation } from "styled-components";
import styled, {
  css,
  DefaultTheme,
  ThemeProps,
} from "styled-components/native";
import { TouchableOpacity } from "react-native";
import { RFValue } from "react-native-responsive-fontsize";

import dark from "../../styles/themes/dark";

import { convertHexToRgba } from "@utils/string.utils";

interface ButtonProps {
  variant: "translucent" | "solid" | "transparent";
  textHashColor?: string;
  backgroundHashColor?: string;
}

interface IconButtonProps {
  iconPosition: "left" | "right";
}

interface StyleByVariant {
  [key: string]: {
    text: FlattenInterpolation<ThemeProps<DefaultTheme>>;
    primaryBackground: FlattenInterpolation<ThemeProps<DefaultTheme>>;
  };
}

export const ButtonContainer = styled(TouchableOpacity)<ButtonProps>`
  flex-direction: row;
  border-radius: 8px;
  justify-content: center;

  ${(props) => stylesButton(props).primaryBackground}
`;

export const IconBox = styled.View<IconButtonProps>`
  align-items: center;
  justify-content: center;

  ${({ iconPosition }) =>
    iconPosition === "left"
      ? css`
          margin-right: 8px;
        `
      : css`
          margin-left: 8px;
        `};
`;

export const ButtonText = styled.Text<ButtonProps>`
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: ${RFValue(16)}px;

  ${(props) => stylesButton(props).text}
`;

const stylesButton = ({
  variant,
  textHashColor,
  backgroundHashColor,
}: ButtonProps) => {
  const styles: StyleByVariant = {
    translucent: {
      text: css`
        color: ${({ theme }) => backgroundHashColor ?? theme.colors.primary};
      `,
      primaryBackground: css`
        background: ${({ theme }) =>
          backgroundHashColor
            ? convertHexToRgba(backgroundHashColor, 0.12)
            : convertHexToRgba(theme.colors.primary, 0.12)};
      `,
    },
    solid: {
      text: css`
        color: ${textHashColor ?? dark.colors.text[100]};
      `,
      primaryBackground: css`
        background: ${({ theme }) =>
          backgroundHashColor ? backgroundHashColor : theme.colors.primary};
      `,
    },
    transparent: {
      text: css`
        color: ${({ theme }) => theme.colors.text[500]};
      `,
      primaryBackground: css`
        background: transparent;
      `,
    },
  };
  return styles[variant];
};
