import { AnimatePresence, MotiView } from "moti";
import { useEffect, useRef, useState } from "react";
import { TextInput } from "react-native";
import { useDebouncedCallback } from "use-debounce";

import { useProductsPage } from "@contexts/useProductsPage";
import { fadePresence } from "@utils/commonAnimations";

import {
  LongArrowStyled,
  HeaderButton,
  HeaderContainer,
  HeaderTitle,
  SearchIconStyled,
  SearchProductInput,
  SearchInputContainer,
  CloseIconStyled,
} from "../styles";
import { useOrder } from "@contexts/useOrder";

export const Header = () => {
  const {
    handleSearchProduct,
    handleRequestReturn,
    handleSetShowSection,
    handleSelectGroup,
  } = useProductsPage();

  const [searchInputFocuses, setSearchInputFocuses] = useState(false);
  const inputRef = useRef<TextInput>(null);
  const [searchValue, setSearchValue] = useState("");

  const searchIsEmpty = searchValue === "";

  const debounced = useDebouncedCallback((value) => {
    handleSearchProduct(value);
  }, 500);

  const handleSearchPress = () => {
    if (!searchInputFocuses) {
      handleSelectGroup("allProducts");
      setSearchInputFocuses(true);
      inputRef.current?.focus();
    }
  };

  const clearSearch = () => {
    debounced("");
    setSearchValue("");
    setSearchInputFocuses(false);
  };

  useEffect(() => {
    handleSetShowSection(!searchInputFocuses && searchValue === "");
  }, [searchInputFocuses, searchValue]);

  return (
    <HeaderContainer>
      <HeaderButton
        onPress={() => {
          if (searchInputFocuses || !searchIsEmpty) {
            clearSearch();
          } else {
            handleRequestReturn();
          }
        }}
      >
        <LongArrowStyled />
      </HeaderButton>
      <AnimatePresence exitBeforeEnter>
        {searchInputFocuses || searchValue !== "" ? (
          <SearchInputContainer key='searchInput'>
            <SearchProductInput
              ref={inputRef}
              placeholder='Pesquisar...'
              blurOnSubmit
              onChangeText={(value) => {
                debounced(value);
                setSearchValue(value);
              }}
              value={searchValue}
              autoFocus
              onFocus={() => setSearchInputFocuses(true)}
              onBlur={() => setSearchInputFocuses(false)}
            />
          </SearchInputContainer>
        ) : (
          <HeaderTitle key='pageTitle'>Lançar Produto</HeaderTitle>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {!searchInputFocuses && searchIsEmpty ? (
          <MotiView {...fadePresence}>
            <HeaderButton onPress={handleSearchPress}>
              <SearchIconStyled />
            </HeaderButton>
          </MotiView>
        ) : (
          !searchIsEmpty && (
            <MotiView {...fadePresence}>
              <HeaderButton onPress={clearSearch}>
                <CloseIconStyled />
              </HeaderButton>
            </MotiView>
          )
        )}
      </AnimatePresence>
    </HeaderContainer>
  );
};
