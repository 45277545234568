import { RFValue } from "react-native-responsive-fontsize";
import styled from "styled-components/native";

import { Button } from "@components/Button";

import { STATUS_BAR_HEIGHT } from "@utils/nativeLayoutHelper";
import { ArrowIcon, TrashIcon } from "@assets/icons";

export const Container = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: ${STATUS_BAR_HEIGHT + 24}px 20px 0;
`;

export const HeaderButton = styled(Button).attrs({
  variant: "transparent",
})`
  padding: 4px;
`;

export const TitleModule = styled.Text`
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: ${RFValue(18)}px;
  color: ${({ theme }) => theme.colors.text[100]};
`;

export const NumberModule = styled.Text`
  color: ${({ theme }) => theme.colors.text[500]};
`;

const commonIconStyle = {
  width: RFValue(20),
  height: RFValue(20),
  strokeWidth: 1.8,
};

export const ArrowDownIcon = styled(ArrowIcon).attrs(({ theme }) => ({
  color: theme.colors.text[100],
  ...commonIconStyle,
}))``;

export const TrashIconStyled = styled(TrashIcon).attrs(({ theme }) => ({
  color: theme.colors.text[500],
  ...commonIconStyle,
}))``;
