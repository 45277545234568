import { RFValue } from "react-native-responsive-fontsize";
import styled from "styled-components/native";

export const Container = styled.View``;

export const BoxLabel = styled.Text`
  font-family: ${({ theme }) => theme.fonts.light};
  font-size: ${RFValue(14)}px;
  color: ${({ theme }) => theme.colors.text[200]};

  margin: 0 0 4px 8px;
`;
