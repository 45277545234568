import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { Platform } from "react-native";
import { ThemeProvider, DefaultTheme } from "styled-components/native";
import * as NavigationBar from "expo-navigation-bar";
import * as StatusBar from "expo-status-bar";

import lightTheme from "../styles/themes/light";
import darkTheme from "../styles/themes/dark";
import AsyncStorage from "@react-native-async-storage/async-storage";

interface CustomThemeProviderProps {
  children: ReactNode;
}

interface CustomThemeContext {
  theme: DefaultTheme;
  toggleTheme: () => void;
}

const CustomThemeContext = createContext({} as CustomThemeContext);

export const CustomThemeContextProvider = ({
  children,
}: CustomThemeProviderProps) => {
  const [theme, setTheme] = useState<DefaultTheme>(darkTheme);

  const toggleTheme = async () => {
    setTheme(theme.title === "light" ? darkTheme : lightTheme);

    await AsyncStorage.setItem("@theme", theme.title);
  };

  useEffect(() => {
    const getThemeFromStorage = async () => {
      const storageTheme = await AsyncStorage.getItem("@theme");
      if (storageTheme) {
        setTheme(storageTheme === "light" ? darkTheme : lightTheme);
      }
    };
    getThemeFromStorage();
  }, []);

  useEffect(() => {
    if (theme.title === "light") {
      StatusBar.setStatusBarStyle("dark");
      NavigationBar.setButtonStyleAsync("dark");
    } else {
      StatusBar.setStatusBarStyle("light");
      NavigationBar.setButtonStyleAsync("light");
    }
    Platform.OS !== "ios" &&
      NavigationBar.setBackgroundColorAsync(theme.colors.background[100]);
  }, [theme]);

  return (
    <ThemeProvider theme={theme}>
      <CustomThemeContext.Provider value={{ toggleTheme, theme: theme }}>
        {children}
      </CustomThemeContext.Provider>
    </ThemeProvider>
  );
};

export const useTheme = () => useContext(CustomThemeContext);
