import { BottomMessageModal } from "@components/BottomMessageModal";
import { useUser } from "@contexts/useUser";

export const DisconnectAnotherDevicesMessage = () => {
  const {
    showDisconnectDevicesMessage,
    handleSignIn,
    handleCloseDisconnectDevicesMessage,
  } = useUser();
  return (
    <BottomMessageModal
      show={showDisconnectDevicesMessage}
      title='Usuário conectado'
      message='Este usuário está sendo utilizado, deseja desconectar o outro dispositivo?'
      onConfirm={() => handleSignIn(undefined, true)}
      onCancel={handleCloseDisconnectDevicesMessage}
    />
  );
};
