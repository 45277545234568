import { RFValue } from "react-native-responsive-fontsize";
import styled from "styled-components/native";

import { Button } from "@components/Button";

import { convertHexToRgba } from "@utils/string.utils";

interface OptionState {
  isSelected: boolean;
}

export const ModalContainer = styled.View`
  flex: 1;

  height: 100%;
  padding: 0 20px;
`;

export const TitlePizza = styled.Text`
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: ${RFValue(18)}px;
  color: ${({ theme }) => theme.colors.text[100]};

  align-self: center;

  margin-bottom: 28px;
`;

export const TypeContainer = styled.View`
  width: 100%;

  margin-bottom: 32px;
`;

export const TypeTitle = styled.Text`
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: ${RFValue(16)}px;
  color: ${({ theme }) => theme.colors.text[100]};

  margin-bottom: 20px;
`;

export const TypeOptions = styled.View`
  width: 100%;

  flex-direction: row;
  align-items: center;
`;

export const Option = styled.View<OptionState>`
  padding: 8px 16px;

  background-color: ${({ isSelected, theme }) =>
    isSelected ? convertHexToRgba(theme.colors.primary, 0.12) : "transparent"};
  border: 1px solid
    ${({ theme, isSelected }) =>
      isSelected ? "transparent" : theme.colors.background[200]};
  border-radius: 8px;

  margin-right: 12px;
`;

export const OptionText = styled.Text<OptionState>`
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: ${RFValue(14)}px;
  color: ${({ isSelected, theme }) =>
    isSelected ? theme.colors.primary : theme.colors.text[400]};
`;

export const FooterButtons = styled.View`
  margin: auto 0 20px;

  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

export const ReturnButton = styled(Button).attrs({
  variant: "transparent",
  textButtonStyle: {
    fontSize: RFValue(14),
  },
})`
  padding: 8px 20px;

  margin-right: 12px;
`;

export const ContinueButton = styled(Button).attrs({
  variant: "solid",
  textButtonStyle: {
    fontSize: RFValue(14),
  },
})`
  padding: 8px 20px;
`;
