import { Keyboard } from "react-native";
import { FlashList, ListRenderItem } from "@shopify/flash-list";
import { useCallback } from "react";

import { BottomSheetModal } from "@components/BottomSheetModal";
import { useProductPage } from "@contexts/useProductPage";
import { CardProduct, SpacerBox } from "@components/index";

import {
  ButtonReturn,
  Header,
  LongArrowStyled,
  LevelTitle,
  Container,
} from "./styles";
import type { IProduct } from "@contexts/@types/useProductsPage.types";

export const ComboProductsBottomSheet = () => {
  const {
    comboServices: { selectedComboLevel, handleCancelPickProduct },
  } = useProductPage();

  const renderItem: ListRenderItem<IProduct> = useCallback(
    ({ item }) => (
      <CardProduct
        product={item}
        hidePrice
        fixedAdditional={selectedComboLevel?.additional}
        fixedObservations={selectedComboLevel?.observations}
      />
    ),
    [selectedComboLevel]
  );

  return (
    <BottomSheetModal
      percentagePosition={100}
      isOpen={!!selectedComboLevel}
      onDismiss={() => {}}
    >
      {selectedComboLevel && (
        <>
          <Header>
            <ButtonReturn onPress={handleCancelPickProduct}>
              <LongArrowStyled />
            </ButtonReturn>
            <LevelTitle>{selectedComboLevel.title}</LevelTitle>
          </Header>
          <Container>
            <FlashList
              numColumns={2}
              onScrollBeginDrag={() => Keyboard.dismiss()}
              showsVerticalScrollIndicator={false}
              ItemSeparatorComponent={() => <SpacerBox height={16} />}
              data={selectedComboLevel.products}
              keyExtractor={(item) => item.id}
              renderItem={renderItem}
              estimatedItemSize={172}
            />
          </Container>
        </>
      )}
    </BottomSheetModal>
  );
};
