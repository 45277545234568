import { memo } from "react";
import { NumericFormat } from "react-number-format";

import type { CategoryInputType } from ".";
import type { ICategoryItem } from "@hooks/@types/useProductCategory.types";

import { DisplayCounter, RadioButton } from "@components/index";

import {
  Checkbox,
  ItemContainer,
  ItemContent,
  ItemName,
  ItemPrice,
} from "./styles";

interface CategoryItem {
  item: ICategoryItem;
  inputType: CategoryInputType;
  disabled: boolean;
  onChangeItem: (itemID: string, newValue: any) => void;
}

const CategoryItemBase = ({
  item,
  inputType,
  disabled,
  onChangeItem,
}: CategoryItem) => {
  const inputByType = ({ id: itemID, amount, isSelected }: ICategoryItem) => {
    const input: Record<CategoryInputType, JSX.Element> = {
      check: (
        <Checkbox
          checked={!!isSelected}
          disabled={!isSelected && disabled}
          onChange={(selectedValue: boolean) => {
            onChangeItem(itemID, selectedValue);
          }}
        />
      ),
      counter: (
        <DisplayCounter
          showOnlyPlusButton={amount! === 0}
          onlyButtons
          value={amount!}
          onChange={(value) => onChangeItem(itemID, value)}
          disabledIncrement={disabled}
        />
      ),
      radio: (
        <RadioButton
          isSelected={isSelected}
          onPress={() => onChangeItem(itemID, true)}
        />
      ),
    };

    return input[inputType];
  };

  const handlePressItem = ({
    id: itemID,
    amount,
    isSelected,
  }: ICategoryItem) => {
    const notDisabled = !disabled;
    const executeByInputType: Record<CategoryInputType, () => void> = {
      check: () =>
        (notDisabled || isSelected) && onChangeItem(itemID, !isSelected),
      counter: () => notDisabled && onChangeItem(itemID, ++amount),
      radio: () => !isSelected && onChangeItem(itemID, true),
    };
    executeByInputType[inputType]();
  };

  const priceFormatted = item.price?.toFixed(2).replace(".", ",");

  return (
    <ItemContainer
      onPress={() => inputType !== "counter" && handlePressItem(item)}
      key={item.id}
    >
      <ItemContent
        disabled={inputType !== "counter"}
        onPress={() => handlePressItem(item)}
      >
        <ItemName>{item.description}</ItemName>
        {item.price !== undefined && item.price !== 0 && (
          <ItemPrice>+ R$ {priceFormatted}</ItemPrice>
        )}
      </ItemContent>
      {inputByType(item)}
    </ItemContainer>
  );
};

export const CategoryItem = memo(CategoryItemBase, (prev, next) => {
  return (
    prev.item.amount === next.item.amount && prev.disabled === next.disabled
  );
});
