import { BottomMessageModal } from "@components/BottomMessageModal";
import { useProductsPage } from "@contexts/useProductsPage";

export const CancelOrderMessage = () => {
  const {
    showCancelOrderMessage,
    handleRequestReturn,
    handleCloseOrderMessage,
  } = useProductsPage();

  return (
    <BottomMessageModal
      show={showCancelOrderMessage}
      title='Atenção'
      message='Retornar a página anterior irá remover todos os itens já lançados, deseja continuar?'
      onConfirm={() => handleRequestReturn(true)}
      onCancel={handleCloseOrderMessage}
    />
  );
};
