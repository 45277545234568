import { useAnimationState } from "moti";
import { RFValue } from "react-native-responsive-fontsize";
import { DefaultTheme } from "styled-components/native";

export const useMenuItemAnimation = () =>
  useAnimationState(
    {
      open: {
        opacity: 1,
        maxHeight: RFValue(24),
        width: RFValue(200),
        marginTop: 16,
      },
      closed: {
        opacity: 0,
        maxHeight: 0,
        width: 0,
        marginTop: 0,
      },
    },
    {
      from: "closed",
      to: "open",
    }
  );

export const useHeaderMenuTitleAnimation = () =>
  useAnimationState(
    {
      open: {
        marginLeft: 0,
      },
      closed: {
        marginLeft: 12,
      },
    },
    {
      from: "closed",
      to: "open",
    }
  );

export const useMenuAnimation = (
  theme: DefaultTheme,
  inFloatingHeader?: boolean
) =>
  useAnimationState(
    {
      open: {
        borderRadius: 12,
        padding: 12,
        backgroundColor: theme.colors.background[200],
      },
      closed: {
        borderRadius: 8,
        padding: inFloatingHeader ? 8 : 4,
        backgroundColor: theme.colors.background[100],
      },
    },
    {
      from: "closed",
      to: "open",
    }
  );

export const transitionSpring = {
  stiffness: 800,
  damping: 300,
  mass: 1.5,
};
