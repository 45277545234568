import * as React from "react";
import Svg, { SvgProps, Path } from "react-native-svg";

export default (props: SvgProps) => (
  <Svg
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <Path
      d='M13.333 7.833C13.333 5.75 13.586 2 10 2c-1.667 0-2.917.833-3.333 2.5m6.666 3.333H5.417s-.488.072-.834.417c-.345.345-.416.833-.416.833v7.5s.071.489.416.834c.346.345.834.416.834.416h9.166s.489-.071.834-.416c.345-.346.416-.834.416-.834v-7.5s-.071-.488-.416-.833-.834-.417-.834-.417h-1.25Z'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <Path d='M10 14a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z' fill='currentColor' />
  </Svg>
);
