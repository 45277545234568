import { SearchBar } from "@components/SearchBar";
import { useModulesPage } from "@contexts/useModulesPage";

import {
  RefreshButton,
  RefreshButtonIcon,
  SearchAreaContainer,
} from "../styles";

export const SearchArea = () => {
  const { handleSearchModule, handleRefresh, searchInputRef } =
    useModulesPage();

  return (
    <SearchAreaContainer>
      <SearchBar
        ref={searchInputRef}
        placeholder={"Nome ou número..."}
        onChange={handleSearchModule}
      />
      <RefreshButton onPress={() => handleRefresh()}>
        <RefreshButtonIcon />
      </RefreshButton>
    </SearchAreaContainer>
  );
};
