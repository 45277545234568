import { AnimatePresence } from "moti";
import { ReactNode, useEffect, useState } from "react";
import { Extrapolate } from "react-native-reanimated";
import Constants from "expo-constants";

import { Switch, RadioButton } from "@components/index";
import { useUser, useTheme, useModulesPage } from "@contexts/index";

import {
  transitionSpring,
  useHeaderMenuTitleAnimation,
  useMenuAnimation,
  useMenuItemAnimation,
} from "./animations";

import { api } from "@services/api";
import { STATUS_BAR_HEIGHT } from "@utils/nativeLayoutHelper";

import {
  AnimatedDropContainer,
  ArrowIconStyled,
  Container,
  HeaderButton,
  HighlightIcon,
  LongArrowStyled,
  MenuItem,
  MenuItemSubText,
  MenuItemText,
  Overlay,
  PointsMenuIconStyled,
  PositionedContainer,
  PressableMenuItem,
  SignOutText,
  Title,
} from "./styles";

export interface MenuDropdownProps {
  inFloatingHeader?: boolean;
}

export const MenuDropdown = ({ inFloatingHeader }: MenuDropdownProps) => {
  const { scrollY } = useModulesPage();
  const { handleSignOut, user, handleChangeUserEnvironment } = useUser();
  const [showMenu, setShowMenu] = useState(false);
  const [currentMenu, setCurrentMenu] = useState<
    "Opções" | "Ambiente" | "Perfil"
  >("Opções");

  const { toggleTheme, theme } = useTheme();

  const menuAnimation = useMenuAnimation(theme, inFloatingHeader);
  const menuItemAnimation = useMenuItemAnimation();
  const headerMenuTitleAnimation = useHeaderMenuTitleAnimation();

  useEffect(() => {
    if (showMenu) {
      menuAnimation.transitionTo("open");
      headerMenuTitleAnimation.transitionTo("open");
      menuItemAnimation.transitionTo("open");
    } else {
      menuItemAnimation.transitionTo("closed");
      headerMenuTitleAnimation.transitionTo("closed");
      menuAnimation.transitionTo("closed");
    }
  }, [showMenu, theme]);

  const ItemWithAnimation = (children: ReactNode) => {
    return (
      <MenuItem
        state={menuItemAnimation}
        transition={{
          ...transitionSpring,
          width: { ...delayOnClose },
          maxHeight: { ...delayOnClose },
        }}
      >
        {children}
      </MenuItem>
    );
  };

  const executeIfShowMenu = (callback: () => void) => {
    if (showMenu) {
      callback();
    }
  };

  const delayOnClose = {
    delay: !showMenu && menuAnimation.current === "open" ? 150 : 0,
  };

  const initialPosition = STATUS_BAR_HEIGHT + 20;

  // 44 = altura aproximado do botão
  const top =
    scrollY &&
    scrollY.interpolate({
      inputRange: [0, initialPosition + 44],
      outputRange: [initialPosition, -44],
      extrapolateRight: Extrapolate.CLAMP,
    });

  return (
    <>
      <AnimatePresence
        onExitComplete={() =>
          currentMenu !== "Opções" && setCurrentMenu("Opções")
        }
      >
        {showMenu && <Overlay onTouchEnd={() => setShowMenu(false)} />}
      </AnimatePresence>
      <PositionedContainer
        inFloatingHeader={inFloatingHeader}
        style={!inFloatingHeader && { top: top }}
      >
        <Container
          state={menuAnimation}
          transition={{ ...transitionSpring, padding: { ...delayOnClose } }}
        >
          <HeaderButton>
            {(!inFloatingHeader || showMenu) && (
              <Title
                state={headerMenuTitleAnimation}
                transition={transitionSpring}
              >
                Opções
              </Title>
            )}

            <HighlightIcon onPress={() => setShowMenu(!showMenu)}>
              <PointsMenuIconStyled />
            </HighlightIcon>
          </HeaderButton>

          <AnimatePresence exitBeforeEnter>
            {currentMenu === "Opções" && (
              <AnimatedDropContainer key='OptionsMenu' isInitialContainer>
                {ItemWithAnimation(
                  <PressableMenuItem onPress={() => setCurrentMenu("Perfil")}>
                    <MenuItemText>Perfil</MenuItemText>
                    <ArrowIconStyled />
                  </PressableMenuItem>
                )}
                {user!.environments.length !== 0 &&
                  ItemWithAnimation(
                    <PressableMenuItem
                      onPress={() => setCurrentMenu("Ambiente")}
                    >
                      <MenuItemText>Ambiente</MenuItemText>
                      <ArrowIconStyled />
                    </PressableMenuItem>
                  )}
                {ItemWithAnimation(
                  <>
                    <MenuItemText>Modo Escuro</MenuItemText>
                    <Switch
                      isActive={theme.title !== "light"}
                      onChange={(_) => executeIfShowMenu(toggleTheme)}
                    />
                  </>
                )}
                {ItemWithAnimation(
                  <PressableMenuItem
                    onPress={() => executeIfShowMenu(handleSignOut)}
                  >
                    <SignOutText>Sair do app</SignOutText>
                  </PressableMenuItem>
                )}
              </AnimatedDropContainer>
            )}
            {currentMenu === "Ambiente" && (
              <AnimatedDropContainer key='EnvironmentsMenu'>
                {ItemWithAnimation(
                  <PressableMenuItem
                    onPress={() => setCurrentMenu("Opções")}
                    isHeader
                  >
                    <LongArrowStyled />
                    <MenuItemText isHeader>Ambiente</MenuItemText>
                  </PressableMenuItem>
                )}
                {user!.environments.map((env) => (
                  <MenuItem
                    key={env.id}
                    state={menuItemAnimation}
                    transition={{
                      ...transitionSpring,
                      width: { ...delayOnClose },
                      maxHeight: { ...delayOnClose },
                    }}
                  >
                    <PressableMenuItem
                      onPress={() => handleChangeUserEnvironment(env.id)}
                    >
                      <MenuItemText>{env.name}</MenuItemText>
                      <RadioButton isSelected={env.id === user!.idCaixa} />
                    </PressableMenuItem>
                  </MenuItem>
                ))}
              </AnimatedDropContainer>
            )}
            {currentMenu === "Perfil" && (
              <AnimatedDropContainer key='ProfileMenu'>
                {ItemWithAnimation(
                  <PressableMenuItem
                    onPress={() => setCurrentMenu("Opções")}
                    isHeader
                  >
                    <LongArrowStyled />
                    <MenuItemText isHeader>Perfil</MenuItemText>
                  </PressableMenuItem>
                )}
                {ItemWithAnimation(
                  <MenuItemText>
                    <MenuItemSubText>Nome: </MenuItemSubText>
                    {user!.name}
                  </MenuItemText>
                )}
                {ItemWithAnimation(
                  <MenuItemText>
                    <MenuItemSubText>Ip Local: </MenuItemSubText>
                    {api.defaults
                      .baseURL!.replace("http://", "")
                      .replace(":300/v1/", "")}
                  </MenuItemText>
                )}
                {ItemWithAnimation(
                  <MenuItemText>
                    <MenuItemSubText>Versão do APP: </MenuItemSubText>
                    {Constants.expoConfig?.version}
                  </MenuItemText>
                )}
                {ItemWithAnimation(
                  <MenuItemText>
                    <MenuItemSubText>Versão da API: </MenuItemSubText>
                    {user!.apiVersion}
                  </MenuItemText>
                )}
              </AnimatedDropContainer>
            )}
          </AnimatePresence>
        </Container>
      </PositionedContainer>
    </>
  );
};
