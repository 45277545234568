import { AnimatePresence } from "moti";

import { useToast } from "../../hooks/useToast";

import { Content, Message, ToastContainer } from "./styles";

export const Toast = () => {
  const { currentNotification, removeNotification } = useToast();

  return (
    <AnimatePresence onExitComplete={removeNotification}>
      {currentNotification && (
        <ToastContainer>
          <Content type={currentNotification.type}>
            <Message>{currentNotification.message}</Message>
          </Content>
        </ToastContainer>
      )}
    </AnimatePresence>
  );
};
