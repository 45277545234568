import { GestureHandlerRootView } from "react-native-gesture-handler";
import Animated from "react-native-reanimated";
import { MotiView } from "moti";
import styled from "styled-components/native";

import { fadePresence } from "@utils/commonAnimations";
import {
  SAFE_SCREEN_HEIGHT,
  STATUS_BAR_HEIGHT,
  SCREEN_HEIGHT,
  NAV_BAR_HEIGHT,
} from "@utils/nativeLayoutHelper";

interface VisibleAreaProps extends BottomSheetProps {
  visibleAreaHeight: number;
  handlerHeight: number;
}

interface BottomSheetProps {
  isFullscreen: boolean;
}

export const GestureRootView = styled(GestureHandlerRootView)`
  height: ${SCREEN_HEIGHT}px;
  width: 100%;
  flex: 1;

  position: absolute;

  overflow: hidden;

  z-index: 10;
`;

export const Overlay = styled(MotiView).attrs({
  ...fadePresence,
})`
  height: ${SCREEN_HEIGHT}px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.36);

  position: absolute;
`;

export const HandleArea = styled.View`
  width: 100%;
`;

export const HandleLine = styled.View`
  width: 44px;
  height: 4px;

  border-radius: 2px;

  background-color: ${({ theme }) => theme.colors.background[500]};

  align-self: center;

  margin: 20px 0;
`;

export const VisibleArea = styled.View<VisibleAreaProps>`
  width: 100%;
  height: ${({ visibleAreaHeight, isFullscreen, handlerHeight }) =>
    visibleAreaHeight - (isFullscreen ? 0 : handlerHeight)}px;
`;

export const Container =
  Animated.createAnimatedComponent(styled.View<BottomSheetProps>`
    height: ${SCREEN_HEIGHT}px;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.background[100]};

    position: absolute;
    top: ${SCREEN_HEIGHT}px;

    border-radius: ${({ isFullscreen }) => (isFullscreen ? 0 : 24)}px;
  `);
