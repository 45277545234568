import { FlatList } from "react-native";

import { useModulesPage, useModuleBottomSheet } from "@contexts/index";

import {
  BottomSheetModal,
  SpacerBox,
  OrderProductList,
} from "@components/index";

import { EmptyOrderList } from "./components/EmptyOrderList";
import { Header } from "./components/Header";
import { Form } from "./components/Form";
import { Footer } from "./components/Footer";

import {
  BottomSheetContent,
  OrderList,
  OrderTitle,
  OrderNumber,
  OrderContainer,
  CircularLoader,
} from "./styles";

export const ModuleBottomSheet = () => {
  const { isModulesBottomSheetOpen, handleCloseModulesBottomSheet } =
    useModulesPage();
  const { placeholderHeights, moduleOrders, loadingModule } =
    useModuleBottomSheet();

  return (
    <BottomSheetModal
      percentagePosition={100}
      isOpen={isModulesBottomSheetOpen}
      onDismiss={handleCloseModulesBottomSheet}
    >
      <Header />

      <BottomSheetContent>
        <FlatList
          data={[1]}
          showsVerticalScrollIndicator={false}
          keyExtractor={(item) => item.toString()}
          renderItem={(_) => (
            <>
              <SpacerBox height={placeholderHeights.header} />

              <Form />
              {loadingModule ? (
                <CircularLoader />
              ) : moduleOrders.length > 0 ? (
                <OrderList
                  data={moduleOrders}
                  keyExtractor={(item) => item.orderNumber.toString()}
                  renderItem={({ item: { orderNumber, order } }) => (
                    <OrderContainer>
                      <OrderTitle>
                        Pedido <OrderNumber>Nº {orderNumber}</OrderNumber>
                      </OrderTitle>
                      <OrderProductList data={order} />
                    </OrderContainer>
                  )}
                />
              ) : (
                <EmptyOrderList />
              )}
            </>
          )}
          ListFooterComponent={<SpacerBox height={placeholderHeights.footer} />}
        />
      </BottomSheetContent>
      <Footer />
    </BottomSheetModal>
  );
};
