import { useOrder } from "@contexts/useOrder";
import {
  ArrowDownIcon,
  HeaderButton,
  Container,
  NumberModule,
  TitleModule,
  TrashIconStyled,
} from "./styles";

export const Header = () => {
  const { module, toggleShowOrderPage, handleRequestClearOrder } = useOrder();

  return (
    <Container>
      <HeaderButton onPress={toggleShowOrderPage}>
        <ArrowDownIcon />
      </HeaderButton>
      <TitleModule>
        {module.type === "C" ? "Comanda " : "Mesa "}
        <NumberModule>Nº</NumberModule> {module.number}
      </TitleModule>
      <HeaderButton onPress={handleRequestClearOrder}>
        <TrashIconStyled />
      </HeaderButton>
    </Container>
  );
};
