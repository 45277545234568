import Svg, { SvgProps, Path } from "react-native-svg";

export default ({ fill, ...rest }: SvgProps) => (
  <Svg
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...rest}
  >
    <Path
      d='m10.82 15.507 3.937 2.5c.508.32 1.133-.156.984-.742l-1.14-4.485a.679.679 0 0 1 .226-.687l3.532-2.945c.46-.383.226-1.157-.375-1.196l-4.61-.297a.648.648 0 0 1-.57-.421l-1.719-4.329a.648.648 0 0 0-1.219 0L8.148 7.234a.648.648 0 0 1-.57.421l-4.61.297c-.602.04-.836.813-.375 1.196l3.531 2.945a.68.68 0 0 1 .227.687l-1.055 4.157c-.18.703.57 1.273 1.172.89l3.664-2.32a.64.64 0 0 1 .688 0v0Z'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      fill={fill}
    />
  </Svg>
);
