import { Platform } from "react-native";
import { RFValue } from "react-native-responsive-fontsize";
import styled, { DefaultTheme } from "styled-components/native";

import { NotesIcon, TableIcon } from "@assets/icons";
import { convertHexToRgba } from "@utils/string.utils";
import { NAV_BAR_HEIGHT, SCREEN_WIDTH } from "@utils/nativeLayoutHelper";

interface TabState {
  isActive: boolean;
}

export const Container = styled.View`
  width: ${SCREEN_WIDTH}px;

  position: absolute;
  bottom: 0;

  flex-direction: row;

  padding: 24px 20px ${24 + (Platform.OS === "ios" ? NAV_BAR_HEIGHT : 0)}px;

  background-color: ${({ theme }) =>
    convertHexToRgba(theme.colors.background[100], 0.96)};

  border-top-width: 1px;
  border-top-color: ${({ theme }) => theme.colors.background[300]};
`;
export const TabItem = styled.TouchableOpacity`
  flex: 1;

  justify-content: center;
  flex-direction: row;
`;

export const TabTitle = styled.Text<TabState>`
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: ${RFValue(14)}px;
  color: ${({ theme, isActive }) =>
    isActive ? theme.colors.primary : theme.colors.text[500]};

  margin-left: 12px;
`;

const iconStyle = (theme: DefaultTheme, isActive: boolean) => ({
  width: RFValue(20),
  height: RFValue(20),
  strokeWidth: 1.4,
  color: isActive ? theme.colors.primary : theme.colors.text[500],
});

export const NotesIconStyled = styled(NotesIcon).attrs<TabState>(
  ({ theme, isActive }) => ({
    ...iconStyle(theme, isActive),
  })
)<TabState>``;

export const TableIconStyled = styled(TableIcon).attrs<TabState>(
  ({ theme, isActive }) => ({
    ...iconStyle(theme, isActive),
  })
)<TabState>``;
