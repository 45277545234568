import styled, { css } from "styled-components/native";

interface IProps {
  widthCustom?: number;
  heightCustom?: number;
}

export const Container = styled.View<IProps>`
  ${({ widthCustom }) =>
    widthCustom &&
    css`
      width: ${widthCustom}px;
    `}

  ${({ heightCustom }) =>
    heightCustom &&
    css`
      height: ${heightCustom}px;
    `}
`;
