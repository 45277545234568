import Svg, { SvgProps, Path } from "react-native-svg";

export default (props: SvgProps) => (
  <Svg
    fill='none'
    viewBox='0 0 20 20'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <Path
      d='M14.44 2.105h1.455a2 2 0 0 1 2 2v11.79a2 2 0 0 1-2 2H14.44M5.559 2.105H4.105a2 2 0 0 0-2 2v11.79a2 2 0 0 0 2 2H5.56m0-12.336h2.96v2.96H5.56V5.56Zm5.921 0h2.96v2.96h-2.96V5.56Zm0 5.921h2.96v2.961h-2.96v-2.96Zm-5.92 0h2.96v2.961H5.56v-2.96Z'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </Svg>
);
