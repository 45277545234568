import styled from "styled-components/native";
import { RFPercentage, RFValue } from "react-native-responsive-fontsize";
import { MotiView } from "moti";

import {
  ConnectionArt as ConnectionArtBase,
  LoginArt as LoginArtBase,
} from "@assets/svgs";
import {
  LongArrow,
  EyeCloseIcon,
  LockOpenIcon,
  NetworkIcon,
  PersonIcon,
  EyeOpenIcon,
} from "@assets/icons";

import { Button } from "@components/Button";

import {
  NAV_BAR_HEIGHT,
  SCREEN_HEIGHT,
  STATUS_BAR_HEIGHT,
} from "@utils/nativeLayoutHelper";
import dark from "../../styles/themes/dark";

interface AnimatedFormComponent {
  isInitialForm?: boolean;
}

const springTransition = {
  stiffness: 800,
  damping: 200,
  mass: 1,
};

export const Container = styled.View`
  background-color: ${({ theme }) => theme.colors.background[100]};

  height: ${SCREEN_HEIGHT}px;
`;

export const GreetingsContainer = styled.View`
  margin-top: ${STATUS_BAR_HEIGHT + RFValue(40)}px;
  padding: 0 20px;
`;

export const AppName = styled.Text`
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: ${RFValue(20)}px;
  color: ${({ theme }) => theme.colors.text[100]};
`;

export const HighlightText = styled.Text`
  color: ${({ theme }) => theme.colors.primary};
`;

export const GreetingText = styled.Text`
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: ${RFValue(24)}px;
  color: ${({ theme }) => theme.colors.text[100]};
`;

export const ArtBox = styled.View`
  min-height: ${RFPercentage(40)}px;

  flex: 1;
  padding: ${RFValue(32)}px 20px;

  align-items: center;
  justify-content: center;
`;

export const ConnectionArt = styled(ConnectionArtBase).attrs({})``;

export const LoginArt = styled(LoginArtBase).attrs({})``;

export const TextInstructions = styled.Text`
  align-self: center;

  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: ${RFValue(14)}px;
  color: ${({ theme }) => theme.colors.text[200]};

  margin-bottom: 24px;
`;

export const FormContainer = styled.View`
  padding: 0 20px;
`;

export const FormContainerAnimated = styled(
  MotiView
).attrs<AnimatedFormComponent>(({ isInitialForm }) => ({
  from: {
    opacity: 0,
    right: isInitialForm ? "50%" : "-50%",
  },
  animate: {
    opacity: 1,
    right: 0,
  },
  exit: {
    opacity: 0,
    right: isInitialForm ? "60%" : "-50%",
  },
  transition: springTransition,
}))<AnimatedFormComponent>`
  position: relative;

  flex: 1;
`;

export const ButtonSubmit = styled(Button).attrs({
  variant: "solid",
})`
  flex: 1;

  padding: 12px 0;
`;

export const ButtonPrev = styled(MotiView).attrs({
  from: {
    opacity: 0,
    marginRight: 0,
    padding: 0,
    maxWidth: 0,
  },
  animate: {
    opacity: 1,
    marginRight: 12,
    padding: 12,
    maxWidth: RFValue(50),
  },
  exit: {
    opacity: 0,
    marginRight: 0,
    padding: 0,
    maxWidth: 0,
  },
  transition: springTransition,
})`
  background-color: ${({ theme }) => theme.colors.background[200]};
  border-radius: 8px;
`;

export const AnimatedTextView = styled(MotiView).attrs<AnimatedFormComponent>(
  ({ isInitialForm }) => ({
    from: {
      opacity: 0,
      right: isInitialForm ? 50 : 0,
    },
    animate: {
      opacity: 1,
      right: 0,
    },
    exit: {
      opacity: 0,
      right: isInitialForm ? -50 : 50,
    },
    transition: springTransition,
  })
)<AnimatedFormComponent>`
  position: relative;

  flex-direction: row;
  align-items: center;
`;

export const ButtonText = styled.Text`
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: ${RFValue(16)}px;
  line-height: ${RFValue(20)}px;
  color: ${dark.colors.text[100]};
`;

export const FooterButtons = styled.View`
  width: 100%;

  flex-direction: row;
  align-items: center;

  margin: ${RFValue(40)}px 0 ${NAV_BAR_HEIGHT + RFValue(40)}px;

  padding: 0 20px;
`;

export const SwitchVisibilityPasswordButton = styled.TouchableWithoutFeedback``;

export const FreeTestButton = styled(Button).attrs({
  variant: "translucent",
  textButtonStyle: {
    fontSize: RFValue(12),
  },
})`
  padding: 8px;
  margin-right: 8px;

  border-radius: 4px;
`;

const attrsIcon = {
  strokeWidth: 1.6,
  width: RFValue(20),
  height: RFValue(20),
};

export const NetworkIconStyled = styled(NetworkIcon).attrs(({ theme }) => ({
  color: theme.colors.primary,
  ...attrsIcon,
}))``;

export const PersonIconStyled = styled(PersonIcon).attrs(({ theme }) => ({
  color: theme.colors.primary,
  ...attrsIcon,
}))``;

export const LockOpenIconStyled = styled(LockOpenIcon).attrs(({ theme }) => ({
  color: theme.colors.primary,
  ...attrsIcon,
}))``;

export const EyeCloseIconStyled = styled(EyeCloseIcon).attrs(({ theme }) => ({
  color: theme.colors.text[500],
  ...attrsIcon,
}))`
  margin-right: 20px;
`;

export const EyeOpenIconStyled = styled(EyeOpenIcon).attrs(({ theme }) => ({
  color: theme.colors.text[500],
  ...attrsIcon,
}))`
  margin-right: 20px;
`;

export const LongArrowRightIcon = styled(LongArrow).attrs({
  color: dark.colors.text[100],
  strokeWidth: 1.8,
  width: RFValue(20),
  height: RFValue(20),
})`
  margin-left: 8px;
`;

export const LongArrowLeftIcon = styled(LongArrow).attrs(({ theme }) => ({
  color: theme.colors.text[500],
  strokeWidth: 1.4,
  width: RFValue(20),
  height: RFValue(20),
}))`
  transform: rotate(180deg);
`;
