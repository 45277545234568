import { DisplayCounter, SpacerBox } from "@components/index";

import { useProductPage } from "@contexts/useProductPage";

import {
  Container,
  LaunchProductButton,
  LaunchText,
  TotalPriceText,
} from "./styles";

export const StickyFooter = () => {
  const {
    product,
    productAmount,
    totalPrice,
    isEditing,
    isLoading,
    hasChanged,
    validProduct,
    isProductInComboLevel,
    handleChangeProductAmount,
    handleLaunchProduct,
    handleChangeDimensions,
  } = useProductPage();

  const disabled = (!hasChanged && isEditing) || isLoading;

  return (
    <Container
      onLayout={({
        nativeEvent: {
          layout: { height },
        },
      }) => handleChangeDimensions("footer", height)}
    >
      {!isProductInComboLevel && product.productType !== "G" && (
        <>
          <DisplayCounter
            value={productAmount}
            onChange={handleChangeProductAmount}
            min={1}
          />
          <SpacerBox width={16} />
        </>
      )}
      <LaunchProductButton
        validProduct={validProduct}
        onPress={handleLaunchProduct}
        disabled={disabled}
        alignCenter={isProductInComboLevel}
      >
        <LaunchText disabled={disabled} validProduct={validProduct}>
          {isEditing
            ? "Editar"
            : isProductInComboLevel
            ? "Confirmar"
            : "Lançar"}
        </LaunchText>
        {!isProductInComboLevel && (
          <TotalPriceText
            disabled={disabled}
            price={totalPrice}
            validProduct={validProduct}
          />
        )}
      </LaunchProductButton>
    </Container>
  );
};
