import { MotiText, MotiView } from "moti";
import Animated from "react-native-reanimated";
import { RFValue } from "react-native-responsive-fontsize";
import styled from "styled-components/native";

import { CloseIcon, LongArrow, SearchIcon } from "@assets/icons";

import { Button } from "@components/Button";
import { StyledInput } from "@components/Forms/TextInput/styles";

import { fadePresence } from "@utils/commonAnimations";
import { STATUS_BAR_HEIGHT } from "@utils/nativeLayoutHelper";

interface GroupProps {
  selected: boolean;
}

export const Container = styled.View`
  flex: 1;

  background-color: ${({ theme }) => theme.colors.background[100]};

  overflow: hidden;
`;

export const HeaderContainer = styled.View`
  width: 100%;

  padding: ${STATUS_BAR_HEIGHT + 20}px 20px 0;

  flex-direction: row;
  align-items: center;
`;

export const HeaderButton = styled(Button).attrs({
  variant: "transparent",
})`
  padding: 4px;
`;

export const HeaderTitle = styled(MotiText).attrs({
  ...fadePresence,
})`
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: ${RFValue(18)}px;
  color: ${({ theme }) => theme.colors.text[100]};

  margin: 0 auto;
`;

export const SearchInputContainer = styled(MotiView).attrs({
  ...fadePresence,
})`
  width: 100%;
  flex: 1;

  margin: 0 12px;
`;

export const SearchProductInput = styled(StyledInput)`
  font-size: ${RFValue(18)}px;
  flex: 1;
`;

export const GroupList = styled.ScrollView.attrs({
  showsHorizontalScrollIndicator: false,
  contentContainerStyle: { paddingLeft: 20, paddingRight: 4 },
})`
  margin: 20px 0 20px;

  width: 100%;
  flex-grow: 0;
`;

export const AnimatedBar = styled(Animated.View)`
  position: absolute;
  left: 0;
  bottom: 0;

  height: 3px;

  background-color: ${({ theme }) => theme.colors.primary};

  border-radius: 1.5px;
`;

export const GroupItemContainer = styled.Pressable<GroupProps>`
  padding: 8px 16px;
  margin-right: 16px;
`;

export const GroupItemText = styled.Text<GroupProps>`
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: ${RFValue(14)}px;
  color: ${({ theme, selected }) =>
    selected ? theme.colors.text[100] : theme.colors.text[500]};

  margin-bottom: 2px;
`;

export const LongArrowStyled = styled(LongArrow).attrs(({ theme }) => ({
  width: RFValue(20),
  height: RFValue(20),
  color: theme.colors.text[100],
  strokeWidth: 1.6,
}))`
  transform: rotate(180deg);
`;

export const CloseIconStyled = styled(CloseIcon).attrs(({ theme }) => ({
  width: RFValue(20),
  height: RFValue(20),
  color: theme.colors.text[500],
  strokeWidth: 1.6,
}))``;

export const SearchIconStyled = styled(SearchIcon).attrs(({ theme }) => ({
  width: RFValue(20),
  height: RFValue(20),
  color: theme.colors.primary,
  strokeWidth: 1.6,
}))``;

export const CircularLoader = styled.ActivityIndicator.attrs(({ theme }) => ({
  color: theme.colors.primary,
  size: RFValue(32),
}))`
  flex: 1;

  justify-self: center;
`;
