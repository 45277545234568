import { MotiText, MotiView } from "moti";
import { RFValue } from "react-native-responsive-fontsize";
import styled from "styled-components/native";

import { Button } from "../Button";
import { PriceHighlight } from "../PriceHighlight";

import { EditIcon, TrashIcon, ArrowIcon } from "@assets/icons";
import DashedLine from "react-native-dashed-line";

export const Container = styled.View``;

export const TileContainer = styled.Pressable`
  flex-direction: row;
  align-items: center;
`;

export const ProductImage = styled.ImageBackground.attrs({
  imageStyle: {
    borderRadius: 8,
  },
})`
  width: ${RFValue(48)}px;
  height: ${RFValue(48)}px;
`;

export const ProductContent = styled.View`
  flex: 1;
  margin-left: 12px;
`;

export const ProductTitle = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const ProductTitleText = styled.Text.attrs({
  numberOfLines: 1,
  ellipsizeMode: "tail",
})`
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: ${RFValue(14)}px;
  color: ${({ theme }) => theme.colors.text[100]};
`;

export const ProductDetails = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  margin-top: 8px;
`;

export const Amount = styled.Text`
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: ${RFValue(14)}px;
  color: ${({ theme }) => theme.colors.text[200]};
`;

export const AmountUnit = styled.Text`
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: ${RFValue(10)}px;
  color: ${({ theme }) => theme.colors.text[500]};
`;

export const TagHasExtra = styled(MotiView)`
  padding: 4px 8px;

  border-radius: 8px;

  border: 1px solid ${({ theme }) => theme.colors.background[300]};
`;

export const TagHasExtraText = styled.Text`
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: ${RFValue(10)}px;
  color: ${({ theme }) => theme.colors.text[400]};
`;

export const TagHasExtraPlus = styled.Text`
  font-size: ${RFValue(10)}px;
  color: ${({ theme }) => theme.colors.primary};
`;

export const ProductDropdownContainer = styled(MotiView)`
  width: 100%;
`;

export const RemovedIngredient = styled(MotiText)`
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: ${RFValue(14)}px;
  color: ${({ theme }) => theme.colors.red};
`;

export const ComboProductArea = styled.View``;

export const ComboProduct = styled.View`
  margin-left: ${RFValue(32)}px;
`;

export const ComboProductTitle = styled(MotiText)`
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: ${RFValue(14)}px;
  color: ${({ theme }) => theme.colors.text[200]};

  margin-left: -${RFValue(8)}px;
`;

export const FooterActions = styled(MotiView)`
  flex-direction: row;
  align-items: center;
`;

export const ActionsDivider = styled.View`
  width: 1.4px;
  height: 20px;

  background-color: ${({ theme }) => theme.colors.background[300]};

  margin: 0 8px;
`;

export const ActionButton = styled(Button).attrs(({ theme }) => ({
  variant: "transparent",
  textButtonStyle: {
    fontFamily: theme.fonts.medium,
    fontSize: RFValue(12),
    color: theme.colors.text[200],
  },
}))`
  flex: 1;
  width: 100%;

  padding: 8px;
`;

export const ProductPrice = styled(PriceHighlight).attrs(({ theme }) => ({
  unitColor: theme.colors.primary,
  highlightSize: 14,
}))``;

export const EditIconStyled = styled(EditIcon).attrs({
  width: RFValue(16),
  height: RFValue(16),
  strokeWidth: 1.6,
})`
  color: ${({ theme }) => theme.colors.primary};
`;

export const ArrowIconStyled = styled(ArrowIcon).attrs({
  width: RFValue(14),
  height: RFValue(14),
  strokeWidth: 1.6,
})`
  color: ${({ theme }) => theme.colors.text[500]};
`;

export const TrashIconStyled = styled(TrashIcon).attrs({
  width: RFValue(16),
  height: RFValue(16),
  strokeWidth: 1.6,
})`
  color: ${({ theme }) => theme.colors.red};
`;

export const DashedLineStyled = styled(DashedLine).attrs(({ theme }) => ({
  dashColor: theme.colors.background[400],
  dashThickness: 1,
  dashLength: 8,
  dashGap: 8,
  axis: "vertical",
}))`
  position: absolute;
  top: 8px;
  bottom: 0px;
  left: 12px;
`;
