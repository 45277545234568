import { memo } from "react";
import {
  CategoryRequiredStatus,
  CategorySubTitle,
  CategoryTitle,
  Container,
  GroupAmountStatus,
  SubTitleText,
} from "./styles";

interface CategoryHeaderProps {
  title: string;
  itemsAmount?: number;
  minAmount?: number;
  maxAmount?: number;
  required?: boolean;
  valid?: boolean;
}

const CategoryHeaderBase = ({
  title,
  itemsAmount,
  minAmount,
  maxAmount,
  required,
  valid,
}: CategoryHeaderProps) => {
  const maxAmountExists = maxAmount !== undefined && maxAmount !== 0;
  const minAmountExists = minAmount !== undefined;

  return (
    <Container>
      <CategoryTitle>{title}</CategoryTitle>
      {minAmountExists && maxAmountExists && (
        <CategorySubTitle>
          <SubTitleText>
            {required
              ? `No mínimo ${minAmount} ${minAmount > 1 ? "opções" : "opção"}`
              : `De ${minAmount} a ${maxAmount} ${
                  maxAmount > 1 ? "opções" : "opção"
                }`}
          </SubTitleText>
          {valid ? (
            <CategoryRequiredStatus isValid>Ok!</CategoryRequiredStatus>
          ) : (
            required && (
              <CategoryRequiredStatus>Obrigatório</CategoryRequiredStatus>
            )
          )}

          <GroupAmountStatus>
            {itemsAmount}/{maxAmount}
          </GroupAmountStatus>
        </CategorySubTitle>
      )}
    </Container>
  );
};

export const CategoryHeader = memo(CategoryHeaderBase, (prev, next) => {
  return prev.itemsAmount === next.itemsAmount && prev.valid === next.valid;
});
