import { BottomMessageModal } from "@components/BottomMessageModal";

import { useProductPage } from "@contexts/useProductPage";
import { useNavigation } from "@react-navigation/native";

export const UnavailableMessage = () => {
  const { goBack } = useNavigation();
  const {
    categoryServices: { categories },
    product,
    isLoading,
  } = useProductPage();

  const isCategoryAndCategoriesIsEmpty =
    !!product.typeCategory && categories.length === 0;

  return (
    <BottomMessageModal
      show={!isLoading && isCategoryAndCategoriesIsEmpty}
      title='Indisponível'
      message='Produto indisponível neste horário, para torna-lo disponível basta alterar a disponibilidade no Portal Gplus!'
      confirmButtonText='Voltar'
      onConfirm={goBack}
    />
  );
};
