import { RFValue } from "react-native-responsive-fontsize";
import styled from "styled-components/native";

export const Container = styled.View`
  margin: 12px 20px 24px;

  border-radius: 12px;

  padding: 12px;
  background-color: ${({ theme }) => theme.colors.background[200]};
`;

export const ChooseText = styled.Text`
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: ${RFValue(14)}px;
  color: ${({ theme }) => theme.colors.primary};
`;

export const ChooseButton = styled.TouchableOpacity`
  margin: 12px 20px 24px;
  padding: 16px;
  align-items: center;

  border-radius: 12px;

  border: 1px solid ${({ theme }) => theme.colors.background[300]};
`;
