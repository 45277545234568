import { RFValue } from "react-native-responsive-fontsize";
import styled, { css } from "styled-components/native";
import { Text } from "moti";

import { LockOpenIcon, LongArrow } from "@assets/icons";
import { STATUS_BAR_HEIGHT } from "@utils/nativeLayoutHelper";

import { Button } from "@components/Button";

export const Container = styled.View`
  flex: 1;
  width: 100%;

  background-color: ${({ theme }) => theme.colors.background[100]};

  position: absolute;
  top: 0;
  left: 0;

  flex-direction: row;
  align-items: center;
  justify-content: center;

  padding: ${STATUS_BAR_HEIGHT + 20}px 20px 24px;

  z-index: 1;
`;

export const TitleModule = styled.Text`
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: ${RFValue(18)}px;
  color: ${({ theme }) => theme.colors.text[100]};
`;

export const NumberModule = styled.Text`
  color: ${({ theme }) => theme.colors.text[500]};
`;

export const HeaderButton = styled(Button).attrs({
  variant: "transparent",
})<{ isRightButton?: boolean }>`
  padding: 4px;

  position: absolute;
  top: ${STATUS_BAR_HEIGHT + 20}px;

  ${({ isRightButton }) =>
    isRightButton
      ? css`
          right: 20px;
        `
      : css`
          left: 20px;
        `}
`;

export const ModulePasswordText = styled(Text)`
  font-family: ${({ theme }) => theme.fonts.medium};
  font-size: ${RFValue(14)}px;
  color: ${({ theme }) => theme.colors.primary};

  margin-left: 8px;
`;

export const LongArrowStyled = styled(LongArrow).attrs(({ theme }) => ({
  width: RFValue(20),
  height: RFValue(20),
  color: theme.colors.text[100],
  strokeWidth: 1.6,
}))`
  transform: rotate(180deg);
`;

export const LockOpenIconStyled = styled(LockOpenIcon).attrs(({ theme }) => ({
  color: theme.colors.primary,
  strokeWidth: 1.4,
  width: RFValue(20),
  height: RFValue(20),
}))``;

export const CircularLoader = styled.ActivityIndicator.attrs(({ theme }) => ({
  color: theme.colors.primary,
  size: RFValue(20),
}))``;
