import styled, { css } from "styled-components/native";

export const Container = styled.Pressable<{
  iconSize: number;
  checked: boolean;
  disabled: boolean;
}>`
  border-radius: 6px;
  border-width: 1.4px;

  border-color: ${({ theme, disabled }) =>
    disabled ? theme.colors.background[300] : theme.colors.background[500]};

  ${({ theme, checked }) =>
    checked &&
    css`
      border-color: ${theme.colors.primary};
    `}

  width: ${({ iconSize }) => iconSize + 1}px;
  height: ${({ iconSize }) => iconSize + 1}px;

  background-color: ${({ theme, checked }) =>
    checked ? theme.colors.primary : "transparent"};
`;
