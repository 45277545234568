import * as React from "react";
import Svg, { SvgProps, Path } from "react-native-svg";

export default (props: SvgProps) => (
  <Svg
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <Path
      d='M17 3 3 17M10.187 7.16a2.873 2.873 0 0 0-3.002 3.286m5.635-1.101a2.875 2.875 0 0 1-3.522 3.463'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <Path
      d='M7.065 14.653C7.974 14.877 8.964 15 10 15c4.418 0 8-2.239 8-5 0-1.43-.96-2.72-2.5-3.63m-11 7.26C2.96 12.72 2 11.43 2 10c0-2.761 3.582-5 8-5 1.012 0 1.98.117 2.872.332'
      stroke='currentColor'
      strokeLinecap='round'
    />
  </Svg>
);
