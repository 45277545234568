import { useState } from "react";

import { TextInput, Label, BottomMessageModal } from "@components/index";
import { useOrder } from "@contexts/useOrder";

export const AskTableMessage = () => {
  const { showAskTable, handleConfirmOrder, closeMessage } = useOrder();
  const [tableNumber, setTableNumber] = useState("");

  return (
    <BottomMessageModal
      show={showAskTable}
      title='Entregar em qual mesa?'
      customMessageContent={
        <Label text='Nº Mesa'>
          <TextInput
            keyboardType='numeric'
            onChangeText={(value) => setTableNumber(value)}
          />
        </Label>
      }
      cancelButtonText='Voltar'
      confirmButtonText='Ok'
      onCancel={closeMessage}
      onConfirm={() => handleConfirmOrder(tableNumber)}
    />
  );
};
