import { Server, Model, ActiveModelSerializer } from "miragejs";

import type {
  SignInProps,
  SignInResponse,
} from "@contexts/@types/useUser.types";
import type { ModulesFromBD } from "@contexts/@types/useModulesPage.types";
import type {
  GroupFromBD,
  ProductFromDB,
} from "@contexts/@types/useProductsPage.types";
import type {
  CategoryFromBD,
  CategoryItemFromBD,
} from "@hooks/@types/useProductCategory.types";
import type { ModuleSaleFromBD } from "@contexts/@types/useModuleBottomSheet.types";
import type { OrderBDFormat } from "@contexts/@types/useOrder.types";

import factories from "./factories";
import { substringSQL } from "@utils/string.utils";

let ApplicationSerializer = ActiveModelSerializer.extend({
  keyForAttribute(attr) {
    return String(attr).toUpperCase();
  },
});

const makeServer = () => {
  return new Server({
    serializers: {
      application: ApplicationSerializer,
    },

    models: {
      module: Model.extend<Partial<ModulesFromBD>>({}),
      group: Model.extend<Partial<GroupFromBD>>({}),
      product: Model.extend<Partial<ProductFromDB>>({}),
      additional: Model.extend<Partial<CategoryItemFromBD>>({}),
      observation: Model.extend<Partial<CategoryItemFromBD>>({}),
      moduleSale: Model.extend<Partial<ModuleSaleFromBD>>({}),
    },

    factories,

    seeds(server) {
      server.createList("module", 100);
      server.createList("group", 2);
      server.createList("product", 2);
      server.createList("additional", 3);
      server.createList("observation", 3);
    },

    routes() {
      this.urlPrefix = "http://0.0.0.0:300/v1";

      this.post("usuario/login", (_, request) => {
        const { requestBody } = request;
        const { user, password } = JSON.parse(requestBody) as SignInProps;

        if (
          (user !== "Apple" && user !== "Teste Grátis") ||
          password !== "teste"
        ) {
          return { data: "Usuário o senha inválida", status: "error" };
        }

        const signInResponse: SignInResponse = {
          data: {
            ID: 8,
            ID_CAIXA: 8,
            NOME: user,
            MODULOS_UTILIZADOS: "C",
            PERGUNTAR_MESA: false,
            AMBIENTES: [],
            TOGO: null,
            REFRESH_TOKEN: "naljdlajdajlkd",
            ACCESS_TOKEN: "dahjwdhwuidhqiudhwq",
            API_VERSION: "1.0.0",
          },
          status: "success",
        };

        return signInResponse;
      });

      this.post("venda/query", function (schema, request) {
        const { requestBody } = request;
        const { SQL } = JSON.parse(requestBody) as { SQL: string };

        if (SQL.includes("ORDER BY VDC.NUMERO_COMANDA")) {
          const { modules } = this.serialize(schema.all("module"));

          return { data: modules, status: "success" };
        }

        if (SQL.includes("CLASSIFICACAO_GRUPO")) {
          const { groups } = this.serialize(schema.all("group"));

          return { data: groups, status: "success" };
        }

        if (SQL.includes("P.POSSUI_CATEGORIA AS TIPO_CATEGORIA,")) {
          const { products } = this.serialize(schema.all("product"));

          return { data: products, status: "success" };
        }

        return { data: [], status: "success" };
      });

      this.post("venda/execsql", function (schema, request) {
        const { requestBody } = request;
        const { SQL } = JSON.parse(requestBody) as { SQL: string };

        if (SQL.includes("UPDATE ecf_venda_comanda SET")) {
          const surname = substringSQL(SQL, "apelido = ", " WHERE");
          const moduleNumber = Number(
            substringSQL(SQL, "numero_comanda = ", " AND")
          );

          schema.db.modules.update(
            { NUMERO_COMANDA: moduleNumber },
            { APELIDO: surname === "null" ? null : surname }
          );
        }

        if (SQL.includes("MESAS_SALVAS")) {
          const moduleNumber = Number(
            substringSQL(SQL, "VALUES ", " MATCH").split(", ")[1]
          );

          if (SQL.includes("UPDATE OR INSERT")) {
            schema.db.modules.update(
              { NUMERO_COMANDA: moduleNumber },
              { MESA_FAVORITADA: "S" }
            );
          } else {
            const moduleNumber = Number(
              SQL.match(/NUMERO_MESA\s*=\s*([0-9]+)/)![1]
            );

            schema.db.modules.update(
              { NUMERO_COMANDA: moduleNumber },
              { MESA_FAVORITADA: "N" }
            );
          }
        }

        return { data: [], status: "success" };
      });

      this.post("venda/consultarVenda", function (schema, request) {
        const { requestBody } = request;
        const { ID_COMANDA } = JSON.parse(requestBody) as {
          ID_COMANDA: number;
        };

        const moduleSale = schema.find("moduleSale", ID_COMANDA.toString());

        return {
          data: moduleSale ?? "Nenhuma venda encontrada",
          status: "success",
        };
      });

      this.post("venda/carregarCategorias", function (schema) {
        const { additionals } = this.serialize(schema.all("additional"));
        const { observations } = this.serialize(schema.all("observation"));

        const categories: CategoryFromBD[] = [
          {
            ID: 1,
            ITEMS: additionals,
            OBRIGATORIO: 1,
            POSICAO: 1,
            QUANTIDADE_MAXIMA: 5,
            QUANTIDADE_MINIMA: 1,
            TIPO_ITENS: "ADICIONAL",
            TITULO: "Algum adicional?",
            REGRA_QUANTIDADE: "",
          },
          {
            ID: 2,
            ITEMS: observations,
            OBRIGATORIO: 0,
            POSICAO: 2,
            QUANTIDADE_MAXIMA: 0,
            QUANTIDADE_MINIMA: 0,
            TIPO_ITENS: "OBSERVACAO",
            TITULO: "Alguma observação?",
            REGRA_QUANTIDADE: "",
          },
        ];

        return { data: categories, status: "success" };
      });

      this.post("venda/novaVenda", function (schema, request) {
        const { ID_COMANDA, ITENS, TOTAL } = JSON.parse(
          request.requestBody
        ) as OrderBDFormat;
        const module = schema.find("module", ID_COMANDA.toString());
        const moduleSale = schema.find("moduleSale", ID_COMANDA.toString());

        let newOrder = {
          NUMERO_LANCAMENTO: 1,
          ITENS: ITENS.map((item) => {
            const product = schema.db.products.find(item.ID_ECF_PRODUTO);

            return {
              ID: item.ID_ECF_PRODUTO,
              DESCRICAO: product.DESCRICAO_TOUCH,
              VALOR_UNITARIO: item.VALOR_UNITARIO.toString(),
              QUANTIDADE: item.QUANTIDADE.toString(),
              TOTAL_ITEM:
                item.QUANTIDADE *
                (item.VALOR_UNITARIO +
                  (item.ADICIONAIS?.reduce(
                    (acc, add) => add.QUANTIDADE * add.VALOR_UNITARIO + acc,
                    0
                  ) ?? 0)),
              UN_VENDA: "UN",
              ID_IMAGEM_PADRAO: 0,
              VERSAO_IMAGEM: 0,
              IMAGE_URL: product.IMAGE_URL,
              ADICIONAL:
                item.ADICIONAIS?.map(
                  ({ ID_PRODUTO, QUANTIDADE, VALOR_UNITARIO }) => ({
                    ID: ID_PRODUTO,
                    DESCRICAO: schema.db.additionals.find(ID_PRODUTO).DESCRICAO,
                    QUANTIDADE: QUANTIDADE.toString(),
                    TOTAL: (VALOR_UNITARIO * QUANTIDADE).toString(),
                  })
                ) ?? [],
              OBSERVACAO:
                item.OBSERVACOES?.map(({ OBSERVACAO, QUANTIDADE }) => ({
                  ID: Math.random() * 999,
                  QUANTIDADE: QUANTIDADE,
                  OBSERVACAO,
                })) ?? [],
            };
          }),
        };

        if (moduleSale) {
          // @ts-ignore
          const lastLaunchArray = moduleSale.attrs.LANCAMENTO;
          // @ts-ignore
          const lastLaunchTotal = moduleSale.attrs.VALOR_FINAL;

          newOrder.NUMERO_LANCAMENTO = lastLaunchArray.length + 1;

          moduleSale.update({
            LANCAMENTO: [...lastLaunchArray, newOrder],
            VALOR_FINAL: Number(lastLaunchTotal) + Number(TOTAL),
          });
        } else {
          const newModuleSale: ModuleSaleFromBD = {
            ID: ID_COMANDA.toString(),
            LANCAMENTO: [newOrder],
            VALOR_FINAL: TOTAL,
          };

          schema.db.moduleSales.insert(newModuleSale);
        }

        module?.update({
          STATUS: "O",
          TEMPO_ULT_PED: "0 min",
        });

        return { data: "Add new order", status: "success" };
      });
      this.post("comanda/fecharComanda", (schema, request) => {
        const { ID_COMANDA } = JSON.parse(request.requestBody) as {
          ID_COMANDA: number;
        };

        const module = schema.find("module", ID_COMANDA.toString());
        const moduleSale = schema.find("moduleSale", ID_COMANDA.toString());

        moduleSale?.destroy();

        module?.update({
          STATUS: "L",
        });

        return { data: null, status: "success" };
      });
    },
  });
};

export const initServer = () => {
  // @ts-ignore
  if (window.server) {
    // @ts-ignore
    window.server.shutdown();
  }
  // @ts-ignore
  window.server = makeServer();
};

export const shutdownServer = () => {
  // @ts-ignore
  if (window.server) {
    // @ts-ignore
    window.server.shutdown();
  }
};
