import { Platform } from "react-native";
import { useModuleBottomSheet, useModulesPage } from "@contexts/index";

import { Label, SpacerBox, TagInput, TextInput } from "@components/index";
import {
  Container,
  SurnameControls,
  SurnameActionButton,
  EditIconStyled,
  ClearIconStyled,
  ActionsDivider,
  CircularLoader,
} from "./styles";
import { removeEmojis } from "@utils/string.utils";

export const Form = () => {
  const { currentModuleType } = useModulesPage();
  const {
    surname,
    inputRef,
    surnameIsFocused,
    moduleBond,
    updatingSurname,
    handleRequestUpdateSurname,
    handleSurnameChange,
    handleSurnameFocusChange,
    handleModuleBondChange,
  } = useModuleBottomSheet();

  return (
    <Container>
      <Label text='Apelido'>
        <TextInput
          value={surname}
          ref={inputRef}
          placeholder='Ex. Marcos'
          editable={!updatingSurname}
          elementRight={{
            element: updatingSurname ? (
              <CircularLoader />
            ) : (
              <SurnameControls>
                {!surnameIsFocused && (
                  <>
                    <SurnameActionButton
                      onPress={() => inputRef.current?.focus()}
                    >
                      <EditIconStyled />
                    </SurnameActionButton>
                    {surname.trim() !== "" && <ActionsDivider />}
                  </>
                )}
                {surname.trim() !== "" && (
                  <SurnameActionButton
                    onPress={() => handleRequestUpdateSurname(true)}
                  >
                    <ClearIconStyled />
                  </SurnameActionButton>
                )}
              </SurnameControls>
            ),
          }}
          onChangeText={(value) => handleSurnameChange(removeEmojis(value))}
          onFocus={() => handleSurnameFocusChange(true)}
          onBlur={() => {
            handleSurnameFocusChange(false);
            surname.trim() !== "" && handleRequestUpdateSurname();
          }}
        />
      </Label>
      <SpacerBox height={24} />
      <Label text='Vínculo'>
        <TagInput
          // placeholder={`Nº ${currentModuleType === "C" ? "Mesa" : "Comanda"}`}
          placeholder='Em desenvolvimento'
          inputEditable={false}
          data={moduleBond}
          onDataChanged={handleModuleBondChange}
          maxTags={currentModuleType === "C" ? 1 : 0}
          prefixTagText={currentModuleType === "C" ? "Mesa " : "Comanda "}
          KeyboardType='number-pad'
        />
      </Label>
    </Container>
  );
};
