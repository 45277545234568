import { useCallback } from "react";
import {
  ButtonStyled,
  Container,
  CounterInput,
  MinusIconStyled,
  PlusIconStyled,
} from "./styles";

export interface DisplayCounterProps {
  value: number;
  onChange: (value: number) => void;
  min?: number;
  max?: number;
  showOnlyPlusButton?: boolean;
  onlyButtons?: boolean;
  disabledIncrement?: boolean;
}

export const DisplayCounter = ({
  value,
  onChange,
  min = 0,
  max,
  showOnlyPlusButton = false,
  onlyButtons = false,
  disabledIncrement = false,
}: DisplayCounterProps) => {
  const handleIncrement = useCallback(() => {
    onChange(value + 1);
  }, [onChange]);

  const handleDecrement = useCallback(() => {
    onChange(value - 1);
  }, [onChange]);

  const disableDecrementButton = value === min;
  const disableIncrementButton = value === max || disabledIncrement;

  return (
    <Container onlyButtons={onlyButtons}>
      {!showOnlyPlusButton ? (
        <>
          <ButtonStyled
            showOnlyPlusButton={showOnlyPlusButton}
            disabled={disableDecrementButton}
            onPress={handleDecrement}
          >
            <MinusIconStyled buttonDisabled={disableDecrementButton} />
          </ButtonStyled>
          <CounterInput editable={false} value={value.toString()} />
        </>
      ) : (
        <></>
      )}
      <ButtonStyled
        showOnlyPlusButton={showOnlyPlusButton}
        onPress={handleIncrement}
        disabled={disableIncrementButton}
      >
        <PlusIconStyled buttonDisabled={disableIncrementButton} />
      </ButtonStyled>
    </Container>
  );
};
