import * as React from "react";
import Svg, { SvgProps, Path, Rect } from "react-native-svg";

export default (props: SvgProps) => (
  <Svg
    viewBox='0 0 357 276'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <Path
      d='M230.172 0H112.69c-13.22 0-12.852 19.77 0 19.77h14.688c9.18 0 8.262 15.287 0 15.287H55.073c-37.573 0-38.073 62.667 0 62.667H62.2c11.934 0 11.934 23.77 0 23.77H33.741c-44.065-1.08-45.901 84.276 0 84.276 16.527 0 51.225 1.081 57.835 1.081 10.098 0 10.098 20.528 0 20.528H55.073c-25.704 0-24.786 48.621 0 48.621h263.252c19.279 0 19.279-28.092 0-28.092h-29.376c-16.157 0-17.442-27.011 0-27.011h58.753c12.852 0 11.934-22.69 0-22.69h-24.787c-13.953 0-13.77-25.931 0-25.931h12.531c19.278 0 17.442-46.46 0-46.46h-29.539c-13.77 0-13.77-23.77 0-23.77h5.992c34.061 0 34.885-66.989 0-66.989H226.5c-10 0-13-15.287 0-15.287h3.672c17.442 0 17.442-19.77 0-19.77Z'
      fill='#4F5154'
      fillOpacity={0.12}
    />
    <Path
      d='M270.397 43c18.492 0 18.492 13.56 18.492 19.725v113.418H254.37V43h16.027Z'
      fill='#1C2023'
      stroke='#181A1D'
      strokeWidth={3}
    />
    <Path
      d='M272.863 62.725v113.418H49.725V62.725C49.725 46.945 62.053 43 68.217 43h184.921c16.026 0 19.725 11.095 19.725 19.725Z'
      fill='#212529'
      stroke='#181A1D'
      strokeWidth={3}
    />
    <Path
      d='M137.75 120.083c13.65-15.166 31.85-15.166 45.5 0'
      stroke='#EA7722'
      strokeWidth={8}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <Path
      d='M161 139a5 5 0 1 0-.001-10.001A5 5 0 0 0 161 139Z'
      fill='#EA7722'
    />
    <Path
      d='M123 103.959c22.75-25.279 53.083-25.279 75.833 0'
      stroke='#EA7722'
      strokeWidth={8}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <Path
      d='M196.429 209.429h-9.863v40.682h39.45v-7.397c0-3.945-3.288-4.931-4.931-4.931H201.36l-4.931-28.354Z'
      fill='#949AAA'
      stroke='#181A1D'
      strokeWidth={3}
    />
    <Path
      d='M108.899 250.111v-7.397c0-1.643 1.233-4.931 6.164-4.931h16.027l6.164-28.354H68.217c-6.164-.411-18.492-4.685-18.492-18.492v-14.794l183.804 1.233-.116 32.053h-46.847l4.931 28.354h14.794c1.644 0 4.931.986 4.931 4.931v7.397H108.899Z'
      fill='#E5E5E5'
    />
    <Path
      d='M57.122 250.111h51.777m124.514 0h-22.191m-102.323 0v-7.397c0-1.643 1.233-4.931 6.164-4.931h16.027m-22.191 12.328h102.323m0 0v-7.397c0-3.945-3.287-4.931-4.931-4.931h-14.794m-54.243-28.354-6.164 28.354m6.164-28.354h49.312m-49.312 0H68.217c-6.164-.411-18.492-4.685-18.492-18.492v-14.794l183.804 1.233-.116 32.053h-46.847m-55.476 28.354h60.407m-4.931-28.354 4.931 28.354M42.328 250.111h7.397m117.116-56.709a7.397 7.397 0 1 1-14.793 0 7.396 7.396 0 1 1 14.793 0Z'
      stroke='#181A1D'
      strokeWidth={3}
      strokeLinecap='round'
    />
    <Path
      d='M301.217 168.746h-12.328V276h14.794c3.945 0 4.931-4.11 4.931-6.164v-91.228c0-3.945-1.233-9.862-7.397-9.862Z'
      fill='#949AAA'
      stroke='#181A1D'
      strokeWidth={3}
    />
    <Path
      d='M285.19 168.746c10.479 0 12.329 6.904 12.329 9.862v88.762c0 2.877-1.973 8.63-9.863 8.63h-44.381c-3.287 0-9.862-1.726-9.862-8.63v-88.762a7.6 7.6 0 0 1 .116-1.232c.522-3.09 2.349-8.63 9.746-8.63h41.915Z'
      fill='#E5E5E5'
    />
    <Path
      d='M243.275 276h44.381c7.89 0 9.863-5.753 9.863-8.63v-6.164M243.275 276c-3.287 0-9.862-1.726-9.862-8.63v-6.164M243.275 276h-18.492 92.46m16.027 0h-8.63m-64.106-99.857h7.397m29.588 7.397v-4.932c0-2.958-1.85-9.862-12.329-9.862h-41.915c-7.397 0-9.224 5.54-9.746 8.63a7.6 7.6 0 0 0-.116 1.232v4.932m64.106 0h-64.106m64.106 0v77.666m-64.106-77.666v77.666m64.106 0h-64.106m34.518 7.397a2.466 2.466 0 1 1-4.931 0 2.466 2.466 0 0 1 4.931 0Z'
      stroke='#181A1D'
      strokeWidth={3}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <Path d='M234.646 259.974v-75.201h61.64v75.201h-61.64Z' fill='#212529' />
    <Rect x={238} y={231} width={56} height={9} rx={2} fill='#282D32' />
    <Rect x={238} y={244} width={56} height={9} rx={2} fill='#EA7722' />
    <Path
      d='m279.824 214.534-6.942 8.966-6.247-8.966H263s-1.966.137-3-1.034c-.914-1.034-1-2.5-1-2.5v-15s-.105-1.895 1-3 3-1 3-1h64s2.332.187 3.5 1.5c1.038 1.168 1 2.5 1 2.5v15s-.019 1.529-1 2.5c-.971.962-2.5 1.034-2.5 1.034h-48.176Z'
      fill='#212529'
      stroke='#181A1D'
      strokeWidth={3}
      strokeLinejoin='round'
    />
    <Path
      d='M264.75 206v-5.711h.938V206h-.938Zm4.105-5.711c.737 0 1.275.151 1.614.453.338.302.508.725.508 1.27 0 .247-.039.483-.118.707-.075.221-.2.418-.375.59a1.84 1.84 0 0 1-.691.402c-.284.099-.634.148-1.051.148h-.609V206h-.938v-5.711h1.66Zm-.062.777h-.66v2.012h.512c.294 0 .544-.034.75-.101a.917.917 0 0 0 .468-.332c.107-.152.16-.351.16-.598 0-.331-.1-.577-.3-.738-.198-.162-.508-.243-.93-.243Zm20.402 4.45c0-.214.055-.364.164-.45a.64.64 0 0 1 .407-.132c.158 0 .294.044.406.132.114.086.172.236.172.45 0 .208-.058.359-.172.453a.624.624 0 0 1-.406.136.625.625 0 0 1-.407-.136c-.109-.094-.164-.245-.164-.453Zm15.344 0c0-.214.055-.364.164-.45a.638.638 0 0 1 .406-.132.64.64 0 0 1 .407.132c.114.086.172.236.172.45 0 .208-.058.359-.172.453a.625.625 0 0 1-.407.136.624.624 0 0 1-.406-.136c-.109-.094-.164-.245-.164-.453Zm15.344 0c0-.214.055-.364.164-.45a.636.636 0 0 1 .406-.132c.159 0 .294.044.406.132.115.086.172.236.172.45 0 .208-.057.359-.172.453a.622.622 0 0 1-.406.136.622.622 0 0 1-.406-.136c-.109-.094-.164-.245-.164-.453Z'
      fill='#E5E5E5'
    />
    <Path
      d='m278.141 199.922-.157 1.492 1.5-.426.118.832-1.403.125.91 1.215-.757.41-.661-1.308-.589 1.304-.79-.406.903-1.215-1.395-.129.133-.828 1.473.426-.156-1.492h.871Zm4.382 0-.156 1.492 1.5-.426.117.832-1.402.125.91 1.215-.758.41-.66-1.308-.59 1.304-.789-.406.903-1.215-1.395-.129.133-.828 1.473.426-.157-1.492h.871Zm4.383 0-.156 1.492 1.5-.426.117.832-1.402.125.91 1.215-.758.41-.66-1.308-.59 1.304-.789-.406.902-1.215-1.394-.129.133-.828 1.472.426-.156-1.492h.871Zm6.578 0-.156 1.492 1.5-.426.117.832-1.402.125.91 1.215-.758.41-.66-1.308-.59 1.304-.789-.406.903-1.215-1.395-.129.133-.828 1.473.426-.157-1.492h.871Zm4.383 0-.156 1.492 1.5-.426.117.832-1.402.125.91 1.215-.758.41-.66-1.308-.59 1.304-.789-.406.902-1.215-1.394-.129.133-.828 1.472.426-.156-1.492h.871Zm4.383 0-.156 1.492 1.5-.426.117.832-1.402.125.91 1.215-.758.41-.66-1.308-.59 1.304-.789-.406.902-1.215-1.394-.129.132-.828 1.473.426-.156-1.492h.871Zm6.578 0-.156 1.492 1.5-.426.117.832-1.402.125.91 1.215-.758.41-.66-1.308-.59 1.304-.789-.406.902-1.215-1.394-.129.133-.828 1.472.426-.156-1.492h.871Zm4.383 0-.156 1.492 1.5-.426.117.832-1.402.125.91 1.215-.758.41-.66-1.308-.59 1.304-.789-.406.902-1.215-1.394-.129.132-.828 1.473.426-.156-1.492h.871Zm4.383 0-.156 1.492 1.5-.426.117.832-1.403.125.91 1.215-.757.41-.66-1.308-.59 1.304-.789-.406.902-1.215-1.395-.129.133-.828 1.473.426-.156-1.492h.871Zm6.578 0-.156 1.492 1.5-.426.117.832-1.403.125.911 1.215-.758.41-.66-1.308-.59 1.304-.789-.406.902-1.215-1.394-.129.132-.828 1.473.426-.156-1.492h.871Z'
      fill='#EA7722'
    />
  </Svg>
);
