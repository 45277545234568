import { MotiView } from "moti";
import { RFValue } from "react-native-responsive-fontsize";
import styled from "styled-components/native";

import { TrashIcon } from "@assets/icons";

export const Container = styled(MotiView)`
  flex-direction: row;
  align-items: center;

  border: 1px solid ${({ theme }) => theme.colors.background[500]};
  border-radius: ${RFValue(6)}px;

  padding-top: ${RFValue(4)}px;
  padding-bottom: ${RFValue(4)}px;
`;

export const TagText = styled.Text`
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: ${RFValue(14)}px;
  line-height: ${RFValue(18)}px;
  color: ${({ theme }) => theme.colors.text};
`;

export const TrashButton = styled(MotiView).attrs({
  from: {
    opacity: 0,
    width: 0,
    marginLeft: 0,
  },
  animate: {
    opacity: 1,
    width: RFValue(16),
    marginLeft: RFValue(8),
  },
  exit: {
    opacity: 0,
    width: 0,
    marginLeft: 0,
  },
})``;

export const TrashIconStyled = styled(TrashIcon).attrs({
  width: RFValue(16),
  height: RFValue(16),
  strokeWidth: 1,
})`
  color: ${({ theme }) => theme.colors.text[500]};
`;
