import { FlatList, View } from "react-native";

import { useOrder } from "@contexts/useOrder";

import { OrderProductList } from "@components/OrderProductList";

import {
  ButtonAddMore,
  Container,
  OrderListHeader,
  OrderListTitle,
  PlusIconStyled,
} from "./styles";
import { SpacerBox } from "@components/SpacerBox";

export const ProductList = () => {
  const {
    orderProducts,
    footerHeight,
    toggleShowOrderPage,
    handleRequestRemoveItem,
    handleEditProduct,
  } = useOrder();

  const orderIsEmpty = orderProducts.length === 0;

  return (
    <Container orderIsEmpty={orderIsEmpty}>
      <OrderListHeader>
        <OrderListTitle>Pedido</OrderListTitle>

        <ButtonAddMore
          text='Item'
          iconLeft={<PlusIconStyled />}
          onPress={toggleShowOrderPage}
        />
      </OrderListHeader>
      <FlatList
        data={[1]}
        showsVerticalScrollIndicator={false}
        renderItem={(_) => (
          <OrderProductList
            data={orderProducts}
            onRemove={handleRequestRemoveItem}
            onEdit={handleEditProduct}
          />
        )}
        ListFooterComponent={<SpacerBox height={footerHeight + 20} />}
      />
    </Container>
  );
};
