import { UseAnimationState } from "moti";

import type { TransitionProps } from "../../animations";
import type { ICategoryItem } from "@hooks/@types/useProductCategory.types";

import { ObsText } from "./styles";

interface ObservationsListProps {
  prefixObservations?: ICategoryItem[];
  observation?: string;
  dropItemAnimation: UseAnimationState<any>;
  transitionWithDelay: TransitionProps;
}

export const ObservationsList = ({
  dropItemAnimation,
  transitionWithDelay,
  observation,
  prefixObservations,
}: ObservationsListProps) => {
  return (
    <>
      {prefixObservations &&
        prefixObservations.map(({ id, description }) => (
          <ObsText
            key={id}
            state={dropItemAnimation}
            transition={transitionWithDelay}
          >
            • {description}
          </ObsText>
        ))}

      {observation && (
        <ObsText state={dropItemAnimation} transition={transitionWithDelay}>
          • {observation}
        </ObsText>
      )}
    </>
  );
};
