import { MotiText, MotiView } from "moti";
import { Dimensions, Platform } from "react-native";
import { Animated } from "react-native";
import { RFValue } from "react-native-responsive-fontsize";
import styled from "styled-components/native";

import { MenuDropdownProps } from ".";
import {
  ArrowIcon,
  LongArrow,
  PointsMenuIcon,
} from "../../../../../assets/icons";
import dark from "../../../../styles/themes/dark";
import { fadePresence } from "../../../../utils/commonAnimations";
import { STATUS_BAR_HEIGHT } from "../../../../utils/nativeLayoutHelper";
import { convertHexToRgba } from "../../../../utils/string.utils";
import { transitionSpring } from "./animations";

interface AnimatedDropContainerProps {
  isInitialContainer?: boolean;
}

interface DropItemType {
  isHeader?: boolean;
}

const { height: SCREEN_HEIGHT, width: SCREEN_WIDTH } = Dimensions.get(
  Platform.OS === "web" ? "window" : "screen"
);

export const Container = styled(MotiView)`
  border: 1px solid ${({ theme }) => theme.colors.background[300]};

  overflow: hidden;
`;

export const PositionedContainer = styled(Animated.View)<MenuDropdownProps>`
  position: absolute;
  right: 20px;
  top: ${({ inFloatingHeader }) =>
    inFloatingHeader ? STATUS_BAR_HEIGHT + 20 : 0}px;

  z-index: 2;
`;

export const HeaderButton = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const HighlightIcon = styled.Pressable`
  padding: 4px;
  border-radius: 4px;

  background-color: ${({ theme }) => theme.colors.primary};

  transform: rotate(90deg);

  margin-left: auto;
`;

export const Title = styled(MotiText)`
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: ${RFValue(14)}px;
  color: ${({ theme }) => theme.colors.text[400]};

  margin-right: 16px;

  overflow: hidden;
`;

export const MenuItem = styled(MotiView)`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const PressableMenuItem = styled.Pressable<DropItemType>`
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: ${({ isHeader }) =>
    isHeader ? "flex-start" : "space-between"};
`;

export const MenuItemText = styled.Text<DropItemType>`
  font-family: ${({ theme, isHeader }) =>
    isHeader ? theme.fonts.semiBold : theme.fonts.regular};
  font-size: ${RFValue(14)}px;
  color: ${({ theme }) => theme.colors.text[200]};
`;
export const MenuItemSubText = styled.Text`
  color: ${({ theme }) => theme.colors.text[500]};
`;

export const SignOutText = styled(MenuItemText)`
  color: ${({ theme }) => theme.colors.text[500]};
`;

export const Overlay = styled(MotiView).attrs({
  ...fadePresence,
})`
  position: absolute;
  top: 0;
  left: 0;

  width: ${SCREEN_WIDTH}px;
  height: ${SCREEN_HEIGHT}px;

  background-color: ${convertHexToRgba("#000", 0.35)};

  z-index: 1;
`;

export const AnimatedDropContainer = styled(
  MotiView
).attrs<AnimatedDropContainerProps>(({ isInitialContainer }) => ({
  from: {
    opacity: 0,
    right: isInitialContainer ? "50%" : "-50%",
  },
  animate: {
    opacity: 1,
    right: 0,
  },
  exit: {
    opacity: 0,
    right: isInitialContainer ? "60%" : "-50%",
  },
  transition: transitionSpring,
}))<AnimatedDropContainerProps>`
  position: relative;

  overflow: hidden;
`;

export const PointsMenuIconStyled = styled(PointsMenuIcon).attrs({
  width: RFValue(20),
  height: RFValue(20),
  color: dark.colors.text[100],
})``;

export const ArrowIconStyled = styled(ArrowIcon).attrs({
  width: RFValue(14),
  height: RFValue(14),
  strokeWidth: 1.6,
})`
  color: ${({ theme }) => theme.colors.text[400]};
  transform: rotate(-90deg);
`;

export const LongArrowStyled = styled(LongArrow).attrs(({ theme }) => ({
  width: RFValue(14),
  height: RFValue(14),
  color: theme.colors.text[100],
  strokeWidth: 2,
}))`
  transform: rotate(180deg);

  margin-right: 8px;
`;
