import { MotiView } from "moti";
import { Platform } from "react-native";
import { RFValue } from "react-native-responsive-fontsize";
import styled, { DefaultTheme } from "styled-components/native";
import * as Device from "expo-device";

import { TypeNotification } from "@hooks/useToast";
import { STATUS_BAR_HEIGHT } from "@utils/nativeLayoutHelper";

import dark from "../../styles/themes/dark";

const headerSpace =
  STATUS_BAR_HEIGHT +
  24 +
  (Platform.OS === "web" && Device.manufacturer === "Apple" ? 40 : 0);

export const ToastContainer = styled(MotiView).attrs({
  from: {
    opacity: 0,
    top: -headerSpace,
  },
  animate: {
    opacity: 1,
    top: headerSpace,
  },
  exit: {
    opacity: 0,
    top: -headerSpace,
  },
  transition: {
    damping: 16,
  },
})`
  position: absolute;
  left: 0;
  right: 0;

  padding: 0 20px;
`;

const colorByType = (theme: DefaultTheme, type: TypeNotification) => {
  const colors = {
    info: theme.colors.blue,
    success: theme.colors.green,
    error: theme.colors.red,
    warning: "#DDB62B",
  };

  return colors[type];
};

export const Content = styled.View<{ type: TypeNotification }>`
  border-radius: 12px;

  flex-direction: row;

  align-items: center;
  justify-content: center;

  padding: 16px 8px;

  background-color: ${({ theme, type }) => colorByType(theme, type)};
`;

export const Message = styled.Text`
  font-family: ${({ theme }) => theme.fonts.medium};
  font-size: ${RFValue(14)}px;
  color: ${dark.colors.text[100]};
  text-align: center;
`;
