import { MotiText } from "moti";
import { RFValue } from "react-native-responsive-fontsize";
import styled from "styled-components/native";

export const ObsText = styled(MotiText)`
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: ${RFValue(14)}px;
  color: ${({ theme }) =>
    theme.title === "light" ? theme.colors.text[500] : theme.colors.text[400]};
`;
