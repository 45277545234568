import { useModulesPage } from "@contexts/useModulesPage";
import { HeaderUserContainer, Title } from "./styles";

export const Header = () => {
  const { currentModuleType } = useModulesPage();
  return (
    <HeaderUserContainer>
      <Title>{currentModuleType === "C" ? "Comandas" : "Mesas"}</Title>
    </HeaderUserContainer>
  );
};
