import { fonts } from "./fonts";
import { Platform } from "react-native";
import { commonColors } from "./commonColors";

const light = {
  title: "light",
  colors: {
    ...commonColors,

    background: {
      100: "#f0f0f0",
      200: "#F8F8F8",
      300: "#dedede",
      400: "#aeaeae",
      500: "#818181",
    },

    text: {
      100: "#2c2c2c",
      200: "#484849",
      400: "#757575",
      500: "#AAAAAA",
    },

    shadows:
      Platform.OS === "web"
        ? "#E4E4E4"
        : Platform.OS === "ios"
        ? "#e8e8e8"
        : "#cfcfcf",
  },
  commonShadowConfig: {
    shadowOffset: {
      width: 0,
      height: 6,
    },
    shadowOpacity: 1,
    shadowRadius: 12,
    elevation: 12,
  },
  fonts,
};

export default light;
