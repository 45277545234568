import { useTheme } from "styled-components/native";

import { useProductPage } from "@contexts/useProductPage";

import {
  Background,
  Container,
  Content,
  ImageBlur,
  Line,
  OldPrice,
  OldPriceBox,
  Price,
  ProductImage,
  PromotionContainer,
  PromotionText,
  Title,
} from "./styles";

export const ProductHeaderCard = () => {
  const { commonShadowConfig } = useTheme();
  const { product, isProductInComboLevel } = useProductPage();

  const hasPromotionalPrice = product.promotionalPrice !== undefined;

  return (
    <Container>
      <ImageBlur source={{ uri: product.image }} blurRadius={12} />

      <Background
        style={{
          shadowColor: "#00000016",
          ...commonShadowConfig,
          shadowRadius: 24,
          elevation: 24,
        }}
      />

      <ProductImage
        source={{
          uri: product.image,
        }}
      />

      <Content>
        <Title hasPromotion={hasPromotionalPrice}>{product.name}</Title>

        {hasPromotionalPrice ? (
          <>
            {product.typePizza && <PromotionText>A partir de</PromotionText>}
            <PromotionContainer>
              <Price price={product.promotionalPrice!} />
              <OldPriceBox>
                <Line />
                <OldPrice price={product.price} />
              </OldPriceBox>
            </PromotionContainer>
          </>
        ) : (
          !isProductInComboLevel && <Price price={product.price} />
        )}
      </Content>
    </Container>
  );
};
