import { Animated, Dimensions } from "react-native";

import { RFValue } from "react-native-responsive-fontsize";
import styled from "styled-components/native";
import { STATUS_BAR_HEIGHT } from "../../../../utils/nativeLayoutHelper";
import { convertHexToRgba } from "../../../../utils/string.utils";

const { width: SCREEN_WIDTH } = Dimensions.get("window");

export const Container = styled(Animated.View)`
  width: ${SCREEN_WIDTH}px;

  position: absolute;
  top: 0;

  flex-direction: row;

  padding: ${STATUS_BAR_HEIGHT + 20}px ${20 + RFValue(44) + 12}px 20px 20px;

  background-color: ${({ theme }) =>
    convertHexToRgba(theme.colors.background[100], 0.96)};

  border-bottom-width: 1px;
  border-bottom-color: ${({ theme }) => theme.colors.background[300]};

  z-index: 3;
`;

export const FakeSearchBarContainer = styled.Pressable`
  flex: 1;
  width: 100%;

  background-color: ${({ theme }) => theme.colors.background[200]};

  padding: 10px 20px;

  flex-direction: row;
  align-items: center;

  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.colors.background[300]};
`;
