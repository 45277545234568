import Svg, { SvgProps, Path } from "react-native-svg";

export default (props: SvgProps) => (
  <Svg
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <Path
      clipRule='evenodd'
      d='m14.71 3.96-1.17-1.17a.832.832 0 0 0-1.178 0l-9.029 9.028v2.349h2.349l9.028-9.029a.833.833 0 0 0 0-1.179Z'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <Path
      d='M2.5 17.5h15M13.225 6.625l-2.35-2.35'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </Svg>
);
