import { AnimatePresence, useAnimationState } from "moti";
import { useEffect } from "react";
import { LayoutChangeEvent, Platform } from "react-native";
import { DefaultTheme, useTheme } from "styled-components/native";

import { useOrder } from "@contexts/useOrder";

import { SCREEN_HEIGHT, NAV_BAR_HEIGHT } from "@utils/nativeLayoutHelper";

import { Header } from "./components/Header";
import { ProductList } from "./components/ProductList";
import { RemoveProductMessage } from "./components/RemoveProductMessage";
import { ClearProductListMessage } from "./components/ClearProductListMessage";
import { AskTableMessage } from "./components/AskTableMessage";

import {
  Amount,
  Container,
  FooterOrder,
  FullContentAnimation,
  OpenOrderButton,
  OrderTotalContainer,
  ConfirmOrderButton,
  SummaryAnimation,
  Total,
  TotalText,
  BackgroundAnimated,
  CircularLoader,
} from "./styles";

const transitionSpring = {
  stiffness: 1000,
  damping: 100,
  mass: 1.5,
};

type OrderContainerAnimationProps = {
  theme: DefaultTheme;
  footerHeight: number;
};

const useOrderContainerAnimation = ({
  theme: { colors },
  footerHeight,
}: OrderContainerAnimationProps) =>
  useAnimationState(
    {
      open: {
        minHeight: SCREEN_HEIGHT - NAV_BAR_HEIGHT,
        borderTopWidth: 0,
        BorderTopColor: colors.background[100],
      },
      closed: {
        minHeight: Platform.OS === "web" ? 94 : footerHeight,
        borderTopWidth: 1,
        BorderTopColor: colors.background[300],
      },
    },
    {
      from: "closed",
      to: "open",
    }
  );

const useBackgroundAnimation = () =>
  useAnimationState(
    {
      open: {
        opacity: 1,
      },
      closed: {
        opacity: 0.96,
      },
    },
    {
      from: "closed",
      to: "open",
    }
  );

export const Order = () => {
  const theme = useTheme();

  const {
    isOrderPageOpen,
    total,
    orderProducts,
    footerHeight,
    showOrderBottomBar,
    sendingOrder,
    handleConfirmOrder,
    toggleShowOrderPage,
    handleSetFooterHeight,
  } = useOrder();

  const orderContainerAnimation = useOrderContainerAnimation({
    theme,
    footerHeight,
  });
  const backgroundAnimation = useBackgroundAnimation();

  const onLayoutFooter = (event: LayoutChangeEvent) => {
    const { height } = event.nativeEvent.layout;

    if (height === footerHeight) return;
    handleSetFooterHeight(height);
  };

  useEffect(() => {
    if (isOrderPageOpen) {
      orderContainerAnimation.transitionTo("open");
      backgroundAnimation.transitionTo("open");
    } else {
      orderContainerAnimation.transitionTo("closed");
      backgroundAnimation.transitionTo("closed");
    }
  }, [isOrderPageOpen]);

  return (
    <>
      <AnimatePresence>
        {showOrderBottomBar && (
          <Container
            state={orderContainerAnimation}
            transition={transitionSpring}
          >
            <BackgroundAnimated
              state={backgroundAnimation}
              transition={transitionSpring}
            />
            <AnimatePresence exitBeforeEnter>
              {isOrderPageOpen && (
                <FullContentAnimation
                  key='fullOrder'
                  transition={{
                    ...transitionSpring,
                    height: { mass: 0.01 },
                  }}
                  exitTransition={{
                    ...transitionSpring,
                    height: { mass: 1 },
                  }}
                >
                  <Header />
                  <ProductList />
                  <FooterOrder absolutePosition>
                    <OrderTotalContainer>
                      <TotalText>Total</TotalText>
                      <Total price={total} />
                    </OrderTotalContainer>
                    <ConfirmOrderButton
                      disabled={sendingOrder}
                      text={sendingOrder ? "Enviando" : "Confirmar"}
                      onPress={() => handleConfirmOrder()}
                      iconRight={sendingOrder && <CircularLoader />}
                    />
                  </FooterOrder>
                </FullContentAnimation>
              )}

              {!isOrderPageOpen && (
                <SummaryAnimation
                  key='summaryOrder'
                  transition={transitionSpring}
                >
                  <FooterOrder onLayout={onLayoutFooter}>
                    <OrderTotalContainer>
                      <Total price={total} />
                      <Amount>{orderProducts.length} Itens adicionados</Amount>
                    </OrderTotalContainer>
                    <OpenOrderButton
                      text='Ver Pedido'
                      onPress={toggleShowOrderPage}
                    />
                  </FooterOrder>
                </SummaryAnimation>
              )}
            </AnimatePresence>
          </Container>
        )}
      </AnimatePresence>
      <RemoveProductMessage />
      <ClearProductListMessage />
      <AskTableMessage />
    </>
  );
};
