import * as React from "react";
import Svg, { SvgProps, Path } from "react-native-svg";

export default (props: SvgProps) => (
  <Svg
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 20 20'
    {...props}
  >
    <Path
      d='M3.3 10h13.35m0 0L11.1 4.15M16.65 10l-5.5 5.85'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </Svg>
);
