import Svg, { SvgProps, Path } from "react-native-svg";

interface CustomSVGProps extends Omit<SvgProps, "strokeWidth"> {
  strokeWidth?: number;
}

export default (props: CustomSVGProps) => (
  <Svg
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <Path
      d='M10 11.215V18.5m7.556-11.25v8.339M2.444 7.309v8.28'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <Path
      d='m10 1.5 8.5 5.009-1.342.719L10 11.063 2.842 7.228 1.5 6.508 10 1.5Z'
      stroke='currentColor'
      strokeWidth={props.strokeWidth && props.strokeWidth + 0.4}
    />
  </Svg>
);
