import { RFValue } from "react-native-responsive-fontsize";
import styled, { css } from "styled-components/native";

import { MinusIcon, PlusIcon } from "@assets/icons";
import { convertHexToRgba } from "@utils/string.utils";
import { DisplayCounterProps } from ".";

interface ButtonStyledProps
  extends Omit<DisplayCounterProps, "value" | "onChange"> {}

interface IconButtonState {
  buttonDisabled?: boolean;
}

export const Container = styled.View<ButtonStyledProps>`
  flex-direction: row;

  background-color: ${({ theme, onlyButtons }) =>
    onlyButtons ? "transparent" : theme.colors.background[200]};

  padding: ${({ onlyButtons }) => (onlyButtons ? 0 : 6)}px;
  border-radius: 8px;
`;

export const ButtonStyled = styled.TouchableOpacity<ButtonStyledProps>`
  background-color: ${({ theme, showOnlyPlusButton }) =>
    convertHexToRgba(theme.colors.primary, showOnlyPlusButton ? 0 : 0.2)};
  border-radius: 6px;

  padding: 6px;

  ${({ disabled, showOnlyPlusButton }) =>
    disabled &&
    !showOnlyPlusButton &&
    css`
      background-color: ${({ theme }) => theme.colors.background[300]};
    `}
`;

export const CounterInput = styled.TextInput`
  margin: 0 8px;

  text-align: center;

  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: ${RFValue(14)}px;
  color: ${({ theme }) => theme.colors.text[100]};

  width: 40px;
`;

export const MinusIconStyled = styled(MinusIcon).attrs({
  width: RFValue(14),
  height: RFValue(14),
  strokeWidth: 1.6,
})<IconButtonState>`
  color: ${({ buttonDisabled, theme }) =>
    buttonDisabled ? theme.colors.text[500] : theme.colors.primary};
`;

export const PlusIconStyled = styled(PlusIcon).attrs({
  width: RFValue(14),
  height: RFValue(14),
  strokeWidth: 1.6,
})<IconButtonState>`
  color: ${({ buttonDisabled, theme }) =>
    buttonDisabled ? theme.colors.text[500] : theme.colors.primary};
`;
