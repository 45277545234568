import { AnimatePresence } from "moti";
import { MotiHover } from "moti/interactions";
import { ReactNode, useEffect, useState } from "react";
import { Pressable, StyleProp, ViewStyle } from "react-native";

import { transitionSpring, useTagAnimation } from "./animations";

import { Container, TagText, TrashButton, TrashIconStyled } from "./styles";

interface TagProps<T extends Object | null> {
  index: number;
  item: T;
  readOnly?: boolean;
  children: (item: T, index: number) => ReactNode;
  onRemove?: (index: number) => void;
  style?: StyleProp<ViewStyle>;
}

export function TagRoot<T extends Object | null>({
  children,
  index,
  item,
  readOnly,
  onRemove,
  style,
}: TagProps<T>) {
  const [showTrashButton, setShowTrashButton] = useState(false);

  const tagAnimation = useTagAnimation();

  useEffect(() => {
    if (showTrashButton) {
      tagAnimation.transitionTo("showTrashButton");
    } else {
      tagAnimation.transitionTo("hideTrashButton");
    }
  }, [showTrashButton]);

  if (readOnly) {
    return (
      <Container state={tagAnimation} transition={transitionSpring}>
        {children(item, index)}
      </Container>
    );
  }

  return (
    <MotiHover
      onHoverIn={() => setShowTrashButton(true)}
      onHoverOut={() => setShowTrashButton(false)}
    >
      <Container
        state={tagAnimation}
        transition={transitionSpring}
        onTouchEnd={(_) => setShowTrashButton(!showTrashButton)}
        style={style}
      >
        {children(item, index)}
        <AnimatePresence>
          {showTrashButton && (
            <Pressable
              onPress={() => {
                onRemove && onRemove(index);
              }}
            >
              <TrashButton transition={transitionSpring}>
                <TrashIconStyled />
              </TrashButton>
            </Pressable>
          )}
        </AnimatePresence>
      </Container>
    </MotiHover>
  );
}

export const Tag = {
  Root: TagRoot,
  Text: TagText,
};
