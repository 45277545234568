import { MotiView } from "moti";
import { RFValue } from "react-native-responsive-fontsize";
import styled from "styled-components/native";

import { fadePresence, transitionSpring } from "@utils/commonAnimations";
import { NAV_BAR_HEIGHT, SCREEN_HEIGHT } from "@utils/nativeLayoutHelper";

import { Button } from "../Button";

export const Overlay = styled(MotiView).attrs({
  ...fadePresence,
})`
  height: ${SCREEN_HEIGHT}px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.36);

  position: absolute;
`;

const bottomSpace = NAV_BAR_HEIGHT;

export const Container = styled(MotiView).attrs({
  from: {
    opacity: 0,
    bottom: -bottomSpace,
  },
  animate: {
    opacity: 1,
    bottom: bottomSpace,
  },
  exit: {
    opacity: 0,
    bottom: -bottomSpace,
  },
  transition: transitionSpring,
})`
  padding: 24px 20px;

  position: absolute;
  left: 20px;
  right: 20px;

  background-color: ${({ theme }) => theme.colors.background[100]};

  border-radius: 16px;
`;

export const Title = styled.Text`
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: ${RFValue(18)}px;
  color: ${({ theme }) => theme.colors.text[100]};

  margin-bottom: 20px;
`;

export const TextContent = styled.Text`
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: ${RFValue(14)}px;
  color: ${({ theme }) => theme.colors.text[200]};
`;

export const FooterButtons = styled.View`
  margin: 32px 0 0px;

  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

export const ConfirmButton = styled(Button).attrs({
  textButtonStyle: {
    fontSize: RFValue(16),
  },
})`
  padding: 8px 20px;
`;

export const CancelButton = styled(ConfirmButton)`
  margin-right: 12px;
`;
