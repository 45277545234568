import { PriceHighlight } from "@components/PriceHighlight";
import { MotiView } from "moti";
import { RFValue } from "react-native-responsive-fontsize";
import styled from "styled-components/native";

export const AdditionalContainer = styled(MotiView)`
  width: 100%;

  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
`;

export const AdditionalName = styled.Text`
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: ${RFValue(14)}px;
  color: ${({ theme }) => theme.colors.green};
`;

export const AdditionalPrice = styled(PriceHighlight).attrs(({ theme }) => ({
  color: theme.colors.green,
  highlightSize: 14,
}))``;
