import { EmptyListComponent } from "@components/EmptyListComponent";
import { Container, TitleOrder } from "./styles";

export const EmptyOrderList = () => {
  return (
    <Container>
      <TitleOrder>Pedido</TitleOrder>
      <EmptyListComponent
        highlightLabel='Nenhum'
        normalLabel='pedido foi encontrado.'
        type='tile'
      />
    </Container>
  );
};
