import { Factory } from "miragejs";

export default {
  module: Factory.extend({
    ID(i: number) {
      return i + Math.random() * 999;
    },
    ID_COMANDA_PRINCIPAL() {
      return null;
    },
    ID_VENDA_CABECALHO(i: number) {
      return i + Math.random() * 999;
    },
    AGRUPADA() {
      return "N";
    },
    APELIDO() {
      return null;
    },
    EM_USO() {
      return "N";
    },
    NUMERO_COMANDA(i: number) {
      return ++i;
    },
    STATUS() {
      return "L";
    },
    TEMPO_ULT_PED() {
      return "";
    },
    TIPO() {
      return "C";
    },
    MESA_FAVORITADA() {
      return "N";
    },
  }),
  group: Factory.extend({
    ID(i: number) {
      return ++i;
    },
    DESCRICAO(i: number) {
      const descByIndex: { [key: number]: string } = {
        1: "Lanches",
        2: "Bebidas",
      };
      return descByIndex[++i];
    },
  }),
  product: Factory.extend({
    ID(i: number) {
      return i + Math.random() * 999;
    },
    ID_GRUPO(i: number) {
      return ++i;
    },
    DESCRICAO_TOUCH(i: number) {
      const descByIndex: { [key: number]: string } = {
        0: "Lanche Simples",
        1: "Coca-cola 200ml",
      };
      return descByIndex[i];
    },
    VALOR_VENDA(i: number) {
      const priceByIndex: { [key: number]: string } = {
        0: "25",
        1: "6",
      };
      return priceByIndex[i];
    },
    VALOR_PROMOCIONAL() {
      return "";
    },
    ORDEM_TOUCH(i: number) {
      return i;
    },
    TIPO() {
      return "N";
    },
    IMAGE_URL(i: number) {
      const imageByIndex: { [key: number]: string } = {
        0: "https://images.unsplash.com/photo-1586816001966-79b736744398?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
        1: "https://images.unsplash.com/photo-1622483767028-3f66f32aef97?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
      };
      return imageByIndex[i];
    },
    ID_IMAGEM_PADRAO() {
      return 0;
    },
    VERSAO_IMAGEM() {
      return 0;
    },
    UNIDADE() {
      return "UN";
    },
    PRODUTO_PIZZA(i: number) {
      return i === 2 ? 1 : 0;
    },
    PODE_FRACIONAR() {
      return "N";
    },
    TIPO_CATEGORIA(i: number) {
      return i === 0 ? 1 : 0;
    },
  }),
  additional: Factory.extend({
    ID(i: number) {
      return i + Math.random() * 999;
    },
    ORDEM(i: number) {
      return ++i;
    },
    QTD_MAX() {
      return 0;
    },
    DESCRICAO(i: number) {
      const descByIndex: { [key: number]: string } = {
        0: "Bacon",
        1: "Cheddar",
        2: "Tomate",
      };
      return descByIndex[i];
    },
    PRECO() {
      return "5";
    },
  }),
  observation: Factory.extend({
    ID(i: number) {
      return i + Math.random() * 999;
    },
    ORDEM(i: number) {
      return ++i;
    },
    QTD_MAX() {
      return 1;
    },
    DESCRICAO(i: number) {
      const descByIndex: { [key: number]: string } = {
        0: "Sem molho",
        1: "Trazer talheres",
        2: "Para viagem",
      };
      return descByIndex[i];
    },
    PRECO() {
      return "0";
    },
  }),
};
