import { RFValue } from "react-native-responsive-fontsize";
import styled from "styled-components/native";

import { Checkbox as CheckboxBase } from "@components/Forms/Checkbox";

export const Container = styled.View`
  background-color: ${({ theme }) => theme.colors.background[200]};
  border-radius: 16px;

  padding: 4px 16px;
  margin: 0 20px 24px;
`;

export const ItemContainer = styled.Pressable`
  flex-direction: row;
  align-items: center;

  padding: 12px 0;
`;

export const ItemContent = styled.Pressable`
  flex: 1;
`;

export const ItemName = styled.Text`
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: ${RFValue(14)}px;
  color: ${({ theme }) => theme.colors.text[200]};
`;

export const ItemPrice = styled.Text`
  font-family: ${({ theme }) => theme.fonts.medium};
  font-size: ${RFValue(12)}px;
  color: ${({ theme }) => theme.colors.text[500]};
`;

export const Checkbox = styled(CheckboxBase).attrs((_) => ({ iconSize: 20 }))``;
