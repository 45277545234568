import styled from "styled-components/native";
import {
  CategoryRequiredStatus,
  CategoryTitle,
} from "../CategoryHeader/styles";

export const Container = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  background-color: ${({ theme }) => theme.colors.background[100]};
  width: 100%;

  padding: 8px 20px 0px;
`;

export const LevelTitle = styled(CategoryTitle)``;

export const RequiredLevelStatus = styled(CategoryRequiredStatus)``;
