import { AnimatePresence } from "moti";
import { useCallback } from "react";
import { ListRenderItem } from "react-native";

import { useModulesPage } from "@contexts/useModulesPage";
import type { IModule } from "@contexts/@types/useModulesPage.types";

import { CardModule } from "../CardModule";

import { CategoryPageTitle, CategoryPageTitleHighlight } from "../../styles";
import { FavoritesContainer, FavoritesList } from "./styles";

export const Favorites = () => {
  const { favoriteModules } = useModulesPage();

  const renderItem: ListRenderItem<IModule> = useCallback(
    ({ item, index }) => <CardModule index={index} {...item} isExpanded />,
    []
  );

  return (
    <AnimatePresence>
      {favoriteModules.length > 0 && (
        <FavoritesContainer>
          <CategoryPageTitle>
            Minhas
            <CategoryPageTitleHighlight> Favoritas</CategoryPageTitleHighlight>
          </CategoryPageTitle>
          <FavoritesList
            horizontal
            data={favoriteModules}
            keyExtractor={(item) => item.id}
            renderItem={renderItem}
          />
        </FavoritesContainer>
      )}
    </AnimatePresence>
  );
};
