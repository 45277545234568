import { StyleProp, ViewStyle } from "react-native";
import { AntDesign } from "@expo/vector-icons";

import { Container } from "./styles";

interface CheckboxProps {
  checked: boolean;
  disabled?: boolean;
  onChange?: (newValue: boolean) => void;
  style?: StyleProp<ViewStyle>;
  iconSize?: number;
}

export const Checkbox = ({
  checked,
  disabled,
  onChange,
  style,
  iconSize = 16,
}: CheckboxProps) => {
  return (
    <Container
      checked={checked}
      style={style}
      onPress={() => {
        if (!disabled && onChange) onChange(!checked);
      }}
      iconSize={iconSize}
      disabled={!!disabled}
    >
      {checked && <AntDesign name='check' size={iconSize} color='white' />}
    </Container>
  );
};
